import { PlatformBrandName } from "utils/enums/platformBrandName";

export default class DomainHelper {
    static getJWTKey(platform: PlatformBrandName): string {
        if (platform === PlatformBrandName.QUALIGENCE) return process.env.JWT_KEY_QUALIGENCE!;
        return process.env.JWT_KEY_IDENTITY_MATRIX!
    }
    static getAPIMain() {
        return process.env.REACT_APP_BASE_URL || '';
    }
    static getAPIEnrichment() {
        return process.env.REACT_APP_URL_ENRICHMENT || '';
    }
    static getAPPUrl() {
        return process.env.REACT_APP_CLIENT_URL || '';
    }
}