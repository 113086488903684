import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import DomainHelper from "helpers/DomainHelper";
import { convertQueryString } from "utils/workWithData";
import axios from "../../utils/axios";
import { ClientState, GraphResponse, VisitorsSeenResponse, ClientAnalyticsStatsDTO, ICPResponse, ICPAnalyticsStatsDTO, ClientAnalyticsResponse, ClientAnalyticsPanelResponse, ClientAnalyticsPanelDTO, } from "store/types";
import { toNumber } from "utils/format";

const baseUrl = DomainHelper.getAPIMain()
const initialState: ClientState = {
  data: null,
  dataById: [],
  isLoading: false,
  isSuccess: false,
  errorMessage: null,
  analytics: {
    data: null, isLoading: false, isSuccess: false, isError: false
  },
  panelQualityVolume: {
    data: [], isLoading: false,
  },
  panelQualityVolumePerson: {
    data: [], isLoading: false,
  },
  panelDurationICP: {
    data: [], isLoading: false,
  },
  panelConvertionICP: {
    data: [], isLoading: false,
  },
  panelVolumeVisitors: {
    data: [], isLoading: false,
  },
  panelConvertingVisitors: {
    data: [], isLoading: false,
  },
  graph: {
    data: [], isLoading: false
  },
  icp: {
    data: null, isLoading: false
  },
  analyticsStats: {
    data: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  metrics: {
    data: null, isLoading: false
  },
  stepCount: 1,
  stats: {
    data: {
      planName: null,
      planState: null,
      rateLimitAvailable: 0,
      rateLimitUsed: 0,
      creditsRevealUsed: 0,
      creditsRevealAvailable: 0
    },
    isLoading: false,
    isError: false,
  },
};
export const getClientStats = createAsyncThunk("client/getStats", async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${baseUrl}client/stats`, { withCredentials: true });
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
export const getClientDTO = createAsyncThunk("client/get", async (clientId: string, thunkAPI) => {
  try {
    const response = await axios.get(`${baseUrl}client?client_id=${clientId}`, { withCredentials: true });
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) { return thunkAPI.rejectWithValue(err.response.data); }
});
export const updateWebhookURL = createAsyncThunk("client/updateWebhookURL", async (updateWebhookDto: { clientId: string; clayWebhookUrl: string | null }, thunkAPI) => {
  try {
    const { clientId, clayWebhookUrl } = updateWebhookDto;
    const response = await axios.patch(`${baseUrl}client/${clientId}`, { clayWebhookUrl }, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true
    })
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) { return thunkAPI.rejectWithValue(err.response.data) }
})
export const getClientAnalytics = createAsyncThunk("client/getAnalytics", async (queryParams: any, thunkAPI) => {
  try {
    const query = convertQueryString(queryParams);
    const response = await axios.get(`${baseUrl}client/analytics?${query}`, { withCredentials: true });
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
})
export const getClientAnalyticsByNode = createAsyncThunk("client/analytics/path/analytics?", async (queryParams: any, thunkAPI) => {
  try {
    const query = convertQueryString(queryParams);
    const response = await axios.get(`${baseUrl}client/analytics/path/analytics?${query}`, { withCredentials: true });
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
})
export const getClientGraph = createAsyncThunk("client/analytics/metrics/chart/visitors", async (queryParams: any, thunkAPI) => {
  try {
    const response = await axios.get<AxiosResponse<{ per_day: GraphResponse[], }>>(`${baseUrl}client/analytics/metrics/chart/visitors`, {
      withCredentials: true,
      params: queryParams
    });
    const data = { perDay: response.data.data.per_day }
    return thunkAPI.fulfillWithValue({ data });
  } catch (err: any) {
    console.warn("Error fetching analytics stats. Returning mock data.");

    return thunkAPI.rejectWithValue(err.response.data);
  }
}
);
export const getClientAnalyticsVisitors = createAsyncThunk("client/analytics/metrics/visitors", async (queryParams: any, thunkAPI) => {
  try {
    const response = await axios.get<AxiosResponse<VisitorsSeenResponse>>(`${baseUrl}client/analytics/metrics/visitors`, {
      withCredentials: true,
      params: queryParams
    });
    const data: ClientAnalyticsStatsDTO = {
      visitorsIdentified: response.data.data.visitors_identified ?? 0,
      visitorsSeen: response.data.data.visitors_seen ?? 0,
      visitorsIdentifiedPercentBasedOnSeen: response.data.data.visitors_identified_percent_based_on_seen ?? 0,
      visitorsConversionRateAvg: response.data.data.visitors_conversion_rate_avg ?? 0,
      visitorsIdentifiedScoreAvg: response.data.data.visitors_identified_score_avg ?? 0,
      visitorsSessionDurationAvg: response.data.data.visitors_session_duration_avg ?? 0,
    }
    return thunkAPI.fulfillWithValue({ data });
  } catch (err: any) {
    console.warn("Error fetching analytics stats. Returning mock data.");

    return thunkAPI.rejectWithValue(err.response.data);
  }
}
);
export const getClientAnalyticsICP = createAsyncThunk("client/analytics/metrics/icp", async (queryParams: any, thunkAPI) => {
  try {
    const response = await axios.get<AxiosResponse<ICPResponse>>(`${baseUrl}client/analytics/metrics/icp`, {
      withCredentials: true,
      params: queryParams
    });
    const data: ICPAnalyticsStatsDTO = {
      icpVisitors: response.data.data.icp_visitors || 0,
      icpVisitorsPercentBasedOnVisitorsIdentified: response.data.data.icp_visitors_percent_based_on_visitors_identified || 0,
      icpVisitorsConversionRateAvg: response.data.data.icp_visitors_conversion_rate_avg || 0,
      icpVisitorsScoreAvg: response.data.data.icp_visitors_score_avg || 0,
    }
    return thunkAPI.fulfillWithValue({ data });
  } catch (err: any) {
    console.warn("Error fetching analytics stats. Returning mock data.");
    return thunkAPI.rejectWithValue(err.response.data);
  }
}
);

export const getPanelVolumeVisitors = createAsyncThunk("client/analytics/metrics/panel/volume-visitors", async (queryParams: any, thunkAPI) => {
  try {
    const response = await axios.get<AxiosResponse<ClientAnalyticsPanelResponse[]>>(`${baseUrl}client/analytics/metrics/panel/volume-visitors`, {
      withCredentials: true,
      params: queryParams
    });
    const format: ClientAnalyticsPanelDTO[] = response.data.data.map(item => ({
      ...item,
      valueFormated: toNumber(item.value)
    }))
    return thunkAPI.fulfillWithValue({ data: format });
  } catch (err: any) {
    console.warn("Error fetching analytics stats. Returning mock data.");
    return thunkAPI.rejectWithValue(err.response.data);
  }
}
);
export const getPanelConvertingVisitors = createAsyncThunk("client/analytics/metrics/panel/converting-visitors", async (queryParams: any, thunkAPI) => {
  try {
    const response = await axios.get<AxiosResponse<ClientAnalyticsPanelResponse[]>>(`${baseUrl}client/analytics/metrics/panel/converting-visitors`, {
      withCredentials: true,
      params: queryParams
    });
    const format: ClientAnalyticsPanelDTO[] = response.data.data.map(item => ({
      ...item,
      valueFormated: toNumber(item.value)
    }))
    return thunkAPI.fulfillWithValue({ data: format });
  } catch (err: any) {
    console.warn("Error fetching analytics stats. Returning mock data.");
    return thunkAPI.rejectWithValue(err.response.data);
  }
}
);
export const getPanelDurationICP = createAsyncThunk("client/analytics/metrics/panel/duration-icp", async (queryParams: any, thunkAPI) => {
  try {
    const response = await axios.get<AxiosResponse<ClientAnalyticsPanelResponse[]>>(`${baseUrl}client/analytics/metrics/panel/duration-icp`, {
      withCredentials: true,
      params: queryParams
    });
    const format: ClientAnalyticsPanelDTO[] = response.data.data.map(item => ({
      ...item,
      valueFormated: toNumber(item.value)
    }))
    return thunkAPI.fulfillWithValue({ data: format });
  } catch (err: any) {
    console.warn("Error fetching analytics stats. Returning mock data.");
    return thunkAPI.rejectWithValue(err.response.data);
  }
}
);
export const getPanelConvertionICP = createAsyncThunk("client/analytics/metrics/panel/converting-icp", async (queryParams: any, thunkAPI) => {
  try {
    const response = await axios.get<AxiosResponse<ClientAnalyticsPanelResponse[]>>(`${baseUrl}client/analytics/metrics/panel/converting-icp`, {
      withCredentials: true,
      params: queryParams
    });
    const format: ClientAnalyticsPanelDTO[] = response.data.data.map(item => ({
      ...item,
      valueFormated: toNumber(item.value)
    }))
    return thunkAPI.fulfillWithValue({ data: format });
  } catch (err: any) {
    console.warn("Error fetching analytics stats. Returning mock data.");
    return thunkAPI.rejectWithValue(err.response.data);
  }
}
);
export const getQualityVolume = createAsyncThunk("client/analytics/metrics/panel/quality-volume", async (queryParams: any, thunkAPI) => {
  try {
    const response = await axios.get<AxiosResponse<ClientAnalyticsPanelResponse[]>>(`${baseUrl}client/analytics/metrics/panel/quality-volume`, {
      withCredentials: true,
      params: queryParams
    });
    const format: ClientAnalyticsPanelDTO[] = response.data.data.map(item => ({
      ...item,
      valueFormated: toNumber(item.value)
    }))
    return thunkAPI.fulfillWithValue({ data: format });
  } catch (err: any) {
    console.warn("Error fetching analytics stats. Returning mock data.");
    return thunkAPI.rejectWithValue(err.response.data);
  }
}
);
export const getQualityVolumePerson = createAsyncThunk("client/analytics/metrics/panel/quality-volume-percent", async (queryParams: any, thunkAPI) => {
  try {
    const response = await axios.get<AxiosResponse<ClientAnalyticsPanelResponse[]>>(`${baseUrl}client/analytics/metrics/panel/quality-volume-percent`, {
      withCredentials: true,
      params: queryParams
    });
    const format: ClientAnalyticsPanelDTO[] = response.data.data.map(item => ({
      ...item,
      valueFormated: toNumber(item.value)
    }))
    return thunkAPI.fulfillWithValue({ data: format });
  } catch (err: any) {
    console.warn("Error fetching analytics stats. Returning mock data.");
    return thunkAPI.rejectWithValue(err.response.data);
  }
}
);
export const clientSlice = createSlice({
  name: "crm", initialState, reducers: {
    resetClientAnalyticsData: (state) => { state.analytics.data = null; },
    stepHandler: (state, action) => { state.stepCount = action.payload }
  },
  extraReducers: (builder) => {
    builder.addCase(getClientDTO.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.errorMessage = null;
      state.data = action.payload.data;
    });
    builder.addCase(getClientDTO.rejected, (state, action: any) => {
      state.data = null;
      state.isLoading = false;
      state.isSuccess = true;
      state.errorMessage = action.payload?.message || "Something went wrong";
    });
    builder.addCase(getClientDTO.pending, (state) => { state.isLoading = true; });
    builder.addCase(updateWebhookURL.fulfilled, (state, action) => {
      if (state.data) {
        state.data.clayWebhookUrl = action.payload?.data.clayWebhookUrl
        state.isLoading = false
      }
    })
    builder.addCase(updateWebhookURL.pending, (state) => { state.isLoading = true })
    builder.addCase(updateWebhookURL.rejected, (state) => { state.isLoading = false })
    builder.addCase(getClientAnalytics.fulfilled, (state, action) => {
      if (action.payload.data.page === 1) {
        state.analytics.data = action.payload.data;
      } else {
        if (!state.analytics.data || state.analytics.data?.rows?.length === 0) {
          state.analytics.data = action.payload.data;
        } else {
          const newData = action.payload.data as ClientAnalyticsResponse;
          let newRows = state.analytics.data.rows.map((row) => {
            const existingRows = newData.rows.filter((r) => r.id === row.id);
            if (existingRows.length > 0) {
              for (const existingRowItem of existingRows) {
                const removeExistingNodes = existingRowItem.nodes.filter((node) => !row.nodes.some((n) => n.id === node.id));
                const removeExistingLinks = existingRowItem.links.filter((link) => !row.links.some((l) => l.source === link.source && l.target === link.target));
                row.nodes = row.nodes.concat(removeExistingNodes);
                row.links = row.links.concat(removeExistingLinks);
                // row.nodes = row.nodes.filter((node) => node.step <= state.stepCount)
                // row.links = row.links.filter((node) => node. <= state.stepCount)
              }
            }
            return row
          });
          // ⭐ remove duplicated rows
          newRows = newRows.filter((row, index, self) => self.findIndex((r) => r.id === row.id) === index);
          state.analytics.data = { ...newData, rows: newRows }
        }
      }
      state.analytics.isSuccess = true;
      state.analytics.isLoading = false;
      state.analytics.isError = false;
    });
    builder.addCase(getClientAnalyticsByNode.fulfilled, (state, action) => {
      if (action.payload.data.page === 1) {
        state.analytics.data = action.payload.data;
      } else {
        if (!state.analytics.data || state.analytics.data?.rows?.length === 0) {
          state.analytics.data = action.payload.data;
        } else {
          const newData = action.payload.data as ClientAnalyticsResponse;
          let newRows = state.analytics.data.rows.map((row) => {
            const existingRows = newData.rows.filter((r) => r.id === row.id);
            if (existingRows.length > 0) {
              for (const existingRowItem of existingRows) {
                const removeExistingNodes = existingRowItem.nodes.filter((node) => !row.nodes.some((n) => n.id === node.id));
                const removeExistingLinks = existingRowItem.links.filter((link) => !row.links.some((l) => l.source === link.source && l.target === link.target));
                row.nodes = row.nodes.concat(removeExistingNodes);
                row.links = row.links.concat(removeExistingLinks);
                // row.nodes = row.nodes.filter((node) => node.step <= state.stepCount)
                // row.links = row.links.filter((node) => node. <= state.stepCount)
              }
            }
            return row
          });
          // ⭐ remove duplicated rows
          newRows = newRows.filter((row, index, self) => self.findIndex((r) => r.id === row.id) === index);
          state.analytics.data = { ...newData, rows: newRows }
        }
      }
      state.analytics.isSuccess = true;
      state.analytics.isLoading = false;
      state.analytics.isError = false;
    });
    builder.addCase(getClientAnalytics.rejected, (state, action: any) => {
      state.analytics.data = null;
      state.analytics.isLoading = false;
      state.analytics.isSuccess = false;
      state.analytics.isError = true;
    });
    builder.addCase(getClientAnalytics.pending, (state) => { state.analytics.isLoading = true; });
    builder.addCase(getClientStats.pending, (state) => {
      state.stats.isLoading = true;
      state.stats.isError = false;
    })
    builder.addCase(getClientStats.fulfilled, (state, action) => {
      state.stats = {
        data: {
          ...initialState.stats.data,
          ...action.payload.data
        },
        isLoading: false,
        isError: false
      };
    })
    builder.addCase(getClientAnalyticsICP.rejected, state => {
      state.icp.data = {
        icpVisitors: 0,
        icpVisitorsPercentBasedOnVisitorsIdentified: 0,
        icpVisitorsConversionRateAvg: 0,
        icpVisitorsScoreAvg: 0,
      }
      state.icp.isLoading = false
    })
    builder.addCase(getClientAnalyticsICP.pending, state => { state.icp.isLoading = true })
    builder.addCase(getClientAnalyticsICP.fulfilled, (state, action) => {
      state.icp.data = action.payload.data
      state.icp.isLoading = false
    })
    builder.addCase(getClientGraph.pending, state => { state.graph.isLoading = true })
    builder.addCase(getClientAnalyticsVisitors.pending, state => { state.metrics.isLoading = true })
    builder.addCase(getClientGraph.fulfilled, (state, action) => {
      state.graph.data = action.payload?.data.perDay
      state.graph.isLoading = false
    })
    builder.addCase(getClientAnalyticsVisitors.fulfilled, (state, action) => {
      state.metrics.data = action.payload.data
      state.metrics.isLoading = false
    })
    builder.addCase(getClientAnalyticsVisitors.rejected, state => {
      state.metrics.data = {
        visitorsIdentified: 0,
        visitorsSeen: 0,
        visitorsIdentifiedPercentBasedOnSeen: 0,
        visitorsConversionRateAvg: 0,
        visitorsIdentifiedScoreAvg: 0,
        visitorsSessionDurationAvg: 0,
      }
      state.metrics.isLoading = false
    })
    builder.addCase(getClientStats.rejected, (state) => {
      state.stats.isLoading = false;
      state.stats.isError = true;
    });

    // PANELS
    builder.addCase(getPanelVolumeVisitors.pending, (state) => { state.panelVolumeVisitors.isLoading = true })
    builder.addCase(getPanelVolumeVisitors.fulfilled, (state, action) => {
      state.panelVolumeVisitors.isLoading = false;
      state.panelVolumeVisitors.data = action.payload?.data || [];
    })
    builder.addCase(getPanelVolumeVisitors.rejected, (state) => {
      state.panelVolumeVisitors.isLoading = false;
      state.panelVolumeVisitors.data = [];
    });
    builder.addCase(getPanelConvertingVisitors.pending, (state) => { state.panelConvertingVisitors.isLoading = true })
    builder.addCase(getPanelConvertingVisitors.fulfilled, (state, action) => {
      state.panelConvertingVisitors.isLoading = false;
      state.panelConvertingVisitors.data = action.payload?.data || [];
    })
    builder.addCase(getPanelConvertingVisitors.rejected, (state) => {
      state.panelConvertingVisitors.isLoading = false;
      state.panelConvertingVisitors.data = [];
    });
    builder.addCase(getPanelDurationICP.pending, (state) => { state.panelDurationICP.isLoading = true })
    builder.addCase(getPanelDurationICP.fulfilled, (state, action) => {
      state.panelDurationICP.isLoading = false;
      state.panelDurationICP.data = action.payload?.data || [];
    })
    builder.addCase(getPanelDurationICP.rejected, (state) => {
      state.panelDurationICP.isLoading = false;
      state.panelDurationICP.data = [];
    });
    builder.addCase(getPanelConvertionICP.pending, (state) => { state.panelConvertionICP.isLoading = true })
    builder.addCase(getPanelConvertionICP.fulfilled, (state, action) => {
      state.panelConvertionICP.isLoading = false;
      state.panelConvertionICP.data = action.payload?.data || [];
    })
    builder.addCase(getPanelConvertionICP.rejected, (state) => {
      state.panelConvertionICP.isLoading = false;
      state.panelConvertionICP.data = [];
    });
    builder.addCase(getQualityVolume.pending, (state) => { state.panelQualityVolume.isLoading = true })
    builder.addCase(getQualityVolume.fulfilled, (state, action) => {
      state.panelQualityVolume.isLoading = false;
      state.panelQualityVolume.data = action.payload?.data || [];
    })
    builder.addCase(getQualityVolume.rejected, (state) => {
      state.panelQualityVolume.isLoading = false;
      state.panelQualityVolume.data = [];
    });
    builder.addCase(getQualityVolumePerson.pending, (state) => { state.panelQualityVolumePerson.isLoading = true })
    builder.addCase(getQualityVolumePerson.fulfilled, (state, action) => {
      state.panelQualityVolumePerson.isLoading = false;
      state.panelQualityVolumePerson.data = action.payload?.data || [];
    })
    builder.addCase(getQualityVolumePerson.rejected, (state) => {
      state.panelQualityVolumePerson.isLoading = false;
      state.panelQualityVolumePerson.data = [];
    });
  }
});
export const {
  resetClientAnalyticsData,
  stepHandler
} = clientSlice.actions;