import React, { useEffect } from "react";
import styles from "./style.module.scss";
import Button from "../../components/atoms/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { resetPassword, resetResetPassword } from "store/slices/auth.slice";
import { toast } from "react-toastify";
import theme from "../../assets/themes"
import { Icon, InputField } from "components";
import constants from "utils/constants";
const ResetPasswordPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>();
  const dispatch = useAppDispatch();
  const { isSuccess, isLoading, isError } = useAppSelector((state) => state.auth.resetPassword);
  const save = (data: { email: string }) => {
    dispatch(resetPassword(data.email));
  };
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Reset Password";
    window.analytics.page("Forgot Password")
    const container = document.getElementById("bg-container");
    if (container) {
      container.style.backgroundImage = `url(${theme["bgImage"]})`;
    }
    return () => {
      document.title = theme.serviceName || ""; 
    };
  }, [])
  useEffect(() => {
    if (isSuccess) {
      toast.success("Password reset link has been sent to your email.");
      dispatch(resetResetPassword());
      navigate("/signin");
    }
    if (isError) {
      toast.error("Email not registered. Please verify the address or sign up.");
      dispatch(resetResetPassword());
    }
  }, [isSuccess, isError, dispatch, navigate]);
  return (
    <div className={styles.main} id="bg-container">
      <div className={styles.main_container}>
        <img src={theme["logo"]} alt="logo" className={styles.main_container_logo} onClick={() => navigate("/signin")} />
        <div className={`${styles.container_wrapper} bg-paper-500`}>
          <div className="flex justify-center items-center bg-primary-500 w-28 h-28 rounded-full text-white-500 mb-1"><Icon name="lock" size={70} /></div>
          <h1 className="text-2xl font-bold">Reset your password</h1>
          <p className="text-sm text-gray-300 mb-5">Enter your email to receive a password reset link.</p>
          <form
            onSubmit={handleSubmit(save)}
            className={styles.main_container_form}
          >
            <InputField
              iconName="email" type="text" autoFocus name="email" errors={errors} register={register}
              validationSchema={{
                required: "Email is required",
                pattern: {
                  value: constants.EMAIL_VALIDATION_REGEX,
                  message: "Invalid email address",
              }}}
              placeholder="Enter your email"
            />
           <Button
              action="submit" className="w-full" iconName="email" type="secondary" 
              size="lg"isProcessing={isLoading} isProcessingTitle="Sending reset link">
              Reset password
            </Button>
          </form>
          <Link to="/signin" className="link text-sm mt-5">Go Back</Link>
        </div>
      </div>
    </div>
  );
};
export default ResetPasswordPage;
 