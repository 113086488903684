import React from "react";
import Skeleton from "../Skeleton/Skeleton";
import Container from "../Container/Container";
const LeadViewSkeleton: React.FC = () => {
  return <Container>
    <div className="flex w-full gap-1">
      <Skeleton width={80} height={12} />
      <Skeleton width={80} height={12} />
    </div>
    <div className="flex flex-row gap-1 items-center border-b border-[var(--color-paper-600)]">
      <Skeleton width={200} height={32} />
      <div className="flex flex-row gap-4">
        <Skeleton width={44} height={32} />
        <Skeleton width={44} height={32} />
        <Skeleton width={44} height={32} />
        <Skeleton width={44} height={32} />
      </div>
      <Skeleton width={200} height={32} />
    </div>
    <div className="flex-row flex-1 w-full grid grid-flow-col grid-rows-6 h-full gap-5 min-h-[700px] -mt-1">
      <div className="col-span-12 row-span-3">
        <div className="flex h-full flex-col w-full bg-gray-900 rounded-lg py-4 gap-4 overflow-x-auto justify-between items-left">
          <div className="p-4 py-0"><Skeleton width='140px' height='32px' /></div>
          {[...Array(8)].map((_, index) => (
            <div key={index} className={`flex w-full flex-row gap-10 px-4 ${(index === 2 || index === 4) && `border-b border-[var(--color-paper-600)] pb-4`}`}>
              <Skeleton width='100px' height='12px' />
              <Skeleton width='140px' height='12px' />
            </div>
          ))}
        </div>
      </div>
      <div className="col-span-12 row-span-3">
        <div className="flex h-full flex-col w-full bg-gray-900 rounded-lg py-4 gap-4 overflow-x-auto justify-between items-left">
          <div className="p-4 py-0"><Skeleton width='140px' height='32px' /></div>
          {[...Array(7)].map((_, index) => (
            <div key={index} className={`flex w-full flex-row gap-10  px-4 ${(index === 0 || index === 3) && `border-b border-[var(--color-paper-600)] pb-4`}`}>
              <Skeleton width='100px' height='12px' />
              <Skeleton width='140px' height='12px' />
            </div>
          ))}
        </div>
      </div>
      <div className="col-span-9 row-span-6">
        <div className="flex h-full flex-col w-full bg-gray-900 rounded-lg py-4 gap-4 overflow-x-auto items-left">
          <div className="flex w-full border-b border-[var(--color-paper-600)] pb-4">
            <div className="p-4 py-0"><Skeleton width='140px' height='32px' /></div>
          </div>
          <div className="w-full grid border-b  grid-flow-col border-[var(--color-paper-600)] pb-4">
            <div className="p-4 py-0 col-span-10"><Skeleton width='80px' height='24px' /></div>
            <div className="p-4 py-0 col-span-4"><Skeleton width='80px' height='24px' /></div>
            <div className="p-4 py-0 col-span-4"><Skeleton width='80px' height='24px' /></div>
          </div>
          {[...Array(3)].map((_, index) => (
            <div className="w-full grid border-b  grid-flow-col border-[var(--color-paper-600)] pb-4">
              <div className="p-4 py-0 "><Skeleton width='340px' height='12px' /></div>
              <div className="p-4 py-0"><Skeleton width='140px' height='12px' /></div>
              <div className="p-4 py-0"><Skeleton width='140px' height='12px' /></div>
            </div>
          ))}

        </div>
      </div>
    </div>
  </Container >
}
export default LeadViewSkeleton;