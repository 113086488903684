import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { ITransactionState } from "types/transaction/transaction.type";
import { convertQueryString } from "utils/workWithData";
import DomainHelper from "helpers/DomainHelper";

const initialState: ITransactionState = {
  isLoading: false,
  data: [],
  isSuccess: false,
  errorMessage: "",
  isLastPage:false
};

export const getTransactions = createAsyncThunk("transactions/getTransactions", async (queryParams:any, thunkAPI) => {
    try {
      const query = convertQueryString(queryParams);
      const response = await axios.get(`${DomainHelper.getAPIMain()}payment/credit_transactions?${query}`, { withCredentials: true });
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const transactionSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUpdateUser: (state) => {
      state.errorMessage = "";
      state.data = [];
      state.isLoading = false;
      state.isSuccess = false;
    },
    resetTransactionsSlice: (state) => {
      state.isLoading = false
      state.data = []
      state.isSuccess = false
      state.errorMessage = ""
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTransactions.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getTransactions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.errorMessage = "";
      state.data = [...state.data,...action.payload.data.transactionDTOList];
      state.isLastPage = action.payload.data.isLastPage
      state.isSuccess = true;
    });
    builder.addCase(getTransactions.rejected, (state, action: any) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message || 'Something went wrong';
      state.data = [];
      state.isSuccess = false;
    });
  },
});

export const { resetUpdateUser, resetTransactionsSlice } = transactionSlice.actions;
