export const cleanObject = (obj: any) => {
  const cleanedObj: any = {};
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (value !== "" && !(Array.isArray(value) && value.length === 0)) {
      cleanedObj[key] = value;
    }
  });
  return cleanedObj;
};
