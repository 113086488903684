import { IUser } from "types/user/user.type";
import constants from "../utils/constants";

export default class BusinessRulesHelper {
    static hasEnoughCredits(user: IUser) {
        return BusinessRulesHelper.hasUnlimitedCredits(user.email) || user.creditBalance > 0;
    }
    static hasUnlimitedCredits(email: string) {
        const splitedEmail = email.split('@')[1]
        return splitedEmail === constants.COMPANY_1_DOMAIN;
    }
}