import React, { useState } from "react";
import Creatable from "react-select/creatable";
import { components } from 'react-select';
import zIndex from "@mui/material/styles/zIndex";
import { MenuPortal } from "react-select/dist/declarations/src/components/Menu";
// MORE: https://react-select.com/home
// SECOND OPTION: https://primereact.org/multiselect/
// THIRD OPTION: https://www.npmjs.com/package/react-multi-select-component
// const options = [
//   { label: "Grapes", value: "grapes" },
//   { label: "Mango", value: "mango" },
//   { label: "Strawberry", value: "strawberry", disabled: true },
// ];

const formatGroupLabel = (data: { label?: any; options: readonly { label?: string, value: any }[] }) => (
  <div className="pb-2">{data?.label}</div>
);

const InputSelect: React.FC<{
  options: [];
  state: [];
  setState: any;
  placeholder: string;
  label?: string;
  isSearchable?: boolean;
  isMulti?: boolean;
  menuPlacement?: "auto" | "bottom" | "top";
  variant?: "dark" | "light";
  id?: string;
  isCopyPaste?: boolean;
  scrollItems?: boolean
  maxHeight?: string;
}> = ({
  options,
  state,
  setState,
  placeholder,
  label,
  isSearchable = true,
  isMulti = true,
  menuPlacement = "auto",
  variant = "dark",
  id, scrollItems,
  isCopyPaste = false,
  maxHeight = "170px",
}) => {
    const bg1 = variant === "dark" ? "var(--color-gray-900)" : "var(--color-paper-400)";
    const customStyles = {
      control: (provided: any, state: { isFocused: any }) => ({
        ...provided,
        outline: "none",
        backgroundColor: bg1,
        fontSize: "14px",
        borderColor: state.isFocused ? "var(--color-primary-500)" : "var(--color-gray-700)",
        borderRadius: 8,
        boxShadow: "none",
        "&:hover": { borderColor: "var(--color-primary-500)" },
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        padding: "6px",
        ...(isMulti && isCopyPaste && {
          maxHeight: `calc(${maxHeight} - 40px)`,
          overflow: "auto",
          marginRight: "10px",
          scrollbarWidth: 'none', /* Firefox */
          msOverflowStyle: 'none',  /* Internet Explorer 10+ */
          '&::-webkit-scrollbar': { /* WebKit */
            width: '0px',
            height: '0px',
          },
        }),
      }),
      placeholder: (provided: any) => ({ ...provided, color: "var(--color-gray-500)", }),
      input: (provided: any) => ({
        ...provided,
        color: "var(--color-white-500)",
      }),
      option: (provided: any, state: { isSelected: any; isFocused: any }) => ({
        ...provided,
        cursor: "pointer",
        backgroundColor: state.isSelected
          ? "var(--color-primary-400)"
          : state.isFocused
            ? "var(--color-primary-500)"
            : bg1,
        color: state.isSelected ? "white" : "var(--color-white-500)",
        "&:active": { backgroundColor: "var(--color-primary-500)" },
        "&:hover": { color: "white" },
      }),
      multiValue: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: "var(--color-primary-500)",
        borderRadius: 4,
      }),
      multiValueLabel: (provided: any) => ({
        ...provided,
        color: "white",
        ...(isMulti && isCopyPaste && {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }),
      }),
      multiValueRemove: (provided: any) => ({
        ...provided,
        padding: "0 2px",
        color: "white",
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        "&:hover": {
          color: "white",
          backgroundColor: "var(--color-primary-400)",
        },
      }),

      menuPortal: (provided: any) => ({
        ...provided,
        zIndex: 999999,
      }),
      menu: (provided: any) => ({
        ...provided,
        borderRadius: 8,
        padding: "2px 0",
        fontSize: "14px",
        border: "1px solid var(--color-gray-700)",
        backgroundColor: bg1,
        ...(isMulti && isCopyPaste && {
          maxHeight: maxHeight,
          overflow: "hidden",
        }),
      }),
      menuList: (provided: any) => ({
        ...provided,
        ...(isMulti && isCopyPaste && {
          maxHeight: maxHeight,
          position: "relative",
          textAlign: "left",
          
          ...(scrollItems ? ({
            direction: "",
            overflowY: "scroll",
          }): ({
            direction: "rtl",
            overflowY: "hidden",
          }))
        }),
      }),
      indicatorSeparator: (provided: any) => ({ ...provided, display: "none" }),
      clearIndicator: (provided: any) => ({
        ...provided,
        color: "var(--color-gray-500)",
        cursor: "pointer",
        paddingRight: 0,
        paddingLeft: 0,
        "&:hover": { color: "var(--color-primary-500)" },
        svg: { width: 18 },
      }),
      dropdownIndicator: (provided: any) => ({
        ...provided,
        color: "var(--color-gray-500)",
        cursor: "pointer",
        "&:hover": { color: "var(--color-primary-500)" },
        svg: { width: 18 },
      }),
      singleValue: (provided: any) => ({ ...provided, color: "white" }),
    };
    const menuListUiHandler = (): void => {
      const seniorityMenuList = document.getElementById(id || "");
      const parentElement = document.getElementById("menu-parent");
      if (parentElement?.clientHeight) {
        if (
          parentElement.clientHeight > 350 &&
          parentElement?.clientHeight < 400
        ) {
          if (
            seniorityMenuList?.getBoundingClientRect()?.y &&
            seniorityMenuList?.getBoundingClientRect()?.y > 200 &&
            seniorityMenuList?.getBoundingClientRect()?.y < 400
          ) {
            parentElement?.scrollBy({ top: 120, behavior: "smooth", });
          }
        } else if (
          parentElement.clientHeight > 400 &&
          seniorityMenuList?.getBoundingClientRect()?.y &&
          seniorityMenuList?.getBoundingClientRect()?.y > 400
        ) {
          parentElement?.scrollBy({ top: 80, behavior: "smooth", });
        }
      }
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();

      const pastedData = event.clipboardData.getData('text');
      const newOptions = pastedData
        .split(/[\n,]+/)
        .map((name) => name.trim())
        .filter((name) => name && !state.some((opt: any) => opt.label === name))
        .slice(0, 500 - state.length)
        .map((name) => ({ label: name, value: name }));

      setState([...state, ...newOptions]);
    };

    const CustomInput = (props: any) => (
      <components.Input {...props} onPaste={isCopyPaste ? handlePaste : undefined} />
    );
    return (
      <div
        id={id || undefined} className="gap-2 flex flex-col"
        onClick={() => {
          if (id) menuListUiHandler();
        }}
      >
        {label && <p className="text-sm">{label}</p>}
        <Creatable
          menuPlacement={menuPlacement}
          menuPortalTarget={document.body}
          options={options}
          value={state}
          onChange={setState}
          placeholder={`${placeholder}...`}
          styles={customStyles}
          isClearable
          isSearchable={isSearchable}
          isMulti={isMulti}
          formatGroupLabel={formatGroupLabel}
          formatCreateLabel={(inputValue: any) => `Search ${inputValue}`}
          components={{ Input: CustomInput }}
        />
      </div>
    );
  };
export default InputSelect;
