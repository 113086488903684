/* eslint-disable react-hooks/exhaustive-deps */
import Container from "components/atoms/layout/Container/Container";
import UserController from "controllers/user";
import React, { useEffect, } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { reset } from "store/slices/auth.slice";
import { getBillingPortalUrl } from "store/slices/card.slice";
import { getPersonalInfo } from "store/slices/user.slice";
import { useAppContext } from "utils/context/AppContext";
import { AppPagePathENUM, AppSchemeENUM } from "utils/enums/appENUM";
import theme from "../../../assets/themes";
import Button from "../../atoms/Button/Button";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import Profile from "../Profile/Profile";
import { HeaderItem } from "./HeaderItem";
import styles from "./style.module.scss";
const Header: React.FC<any> = ({ loggedIn, token, showMenu = true }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isSuccess: AuthLogoutIsSuccess } = useAppSelector((state) => state.auth.logout);
  const { billingPortalIsLoading } = useAppSelector((state) => state.cards);
  const { data: user } = useAppSelector((state) => state.user);
  const { app } = useAppContext();
  const handleLogout = async () => {
    await UserController.Auth.logout({ user, dispatch });
    navigate("/signin");
  };
  useEffect(() => {
    if (!AuthLogoutIsSuccess) return;
    navigate("/signin");
    dispatch(reset());
  }, [AuthLogoutIsSuccess]);
  useEffect(() => { if (token && !AuthLogoutIsSuccess) dispatch(getPersonalInfo()) }, []);
  return <Container>
    <header className={styles.header}>
      <div className={styles.header_container}>
        <Link to="/" className={styles.header_logo}><img src={theme["logo"]} alt="logo" className={styles.header_logo} /></Link>
        {showMenu && <>
          {loggedIn ? (
            <>
              <nav className={styles.header_menu}>
                <div className="w-fit">
                  <HeaderItem name={AppPagePathENUM.PIXEL_KEYWORD} icon="pulse" label="Leads" />
                </div>
                <div className="w-fit">
                  <HeaderItem name={AppPagePathENUM.LEADSEARCH_KEYWORD} icon="search" />
                </div>
                <div className="w-fit">
                  <HeaderItem name={AppPagePathENUM.SCORING} icon="score" label="Lead Scoring" />
                </div>
                <div className="w-fit">
                  <HeaderItem name={AppPagePathENUM.ANALYTICS_KEYWORD} icon="analytics" />
                </div>
              </nav>
              <nav className={styles.header_nav}>
                {user && <>
                  {/* {!user.isInvitedUser && !user.gatewayCustomerId && app.scheme === AppSchemeENUM.QUALIGENCE && 
                    <Button type="primary" onClick={() => navigate("/buy-credits")}>Billing Portal</Button>
                  } */}
                  {!(user.hasUnlimitedCredits || user.isInvitedUser) && <>
                    <Button iconName="credits" onClick={async () => {
                      if (!user.gatewayCustomerId) {
                        navigate("/user/billing")
                        setTimeout(() => { toast.warn("Please add a payment method to your account before buying credits.") }, 900);
                        return
                      }
                      const billingData = await dispatch(getBillingPortalUrl())
                      const url = billingData.payload.data;
                      if (!url) {
                        toast.error("Something went wrong. Please try again later.")
                        return
                      }
                      window.open(url, "_blank")
                    }}>
                      {billingPortalIsLoading ? "Redirecting..." : "Billing Portal"}
                    </Button>
                    {/* {showBuyCredits && <Popup onClose={() => setShowBuyCredits(false)} type="modal" noButton bodyClass={styles.header_nav_popup}><BuyCredits /></Popup>} */}
                  </>}
                  <Profile
                    name={{ name: user.firstName, lastName: user.lastName, }}
                    credits={user.creditBalance}
                    unlimitedCredits={user.hasUnlimitedCredits}
                    logout={handleLogout} dropDown
                  />
                </>}
              </nav>
              <div className={styles.header_nav_burger}><BurgerMenu /></div>
            </>
          ) : (
            <nav className={styles.header_nav}>
              <Button type="primary" onClick={() => navigate("/signin")}>Log In</Button>
              <Button type="primary" onClick={() => app.scheme === AppSchemeENUM.IDENTITY_MATRIX ? navigate("/pricing") : navigate("/signup")}>Sign Up</Button>
            </nav>
          )}
        </>}
      </div>
    </header>
  </Container>
};
export default Header;
