import React from "react";
import Skeleton from "../Skeleton/Skeleton";
import { Icon, Container } from "components";

const LeadScoringSkeleton: React.FC = () => {
  return <Container>
    <div className="flex sm:gap-6"><div className="items-center flex gap-3 text-white-500">
      <Icon name="score" size={30} />
      <h1 className="font-bold capitalize text-2xl">Lead Scoring</h1>
    </div></div>
    <div className="grid grid-cols-1 p-5 gap-6 bg-gray-900 overflow-x-auto rounded-lg">
      <div className="flex flex-col gap-2">
        <Skeleton width={185} height={30} />
        <Skeleton width={687} height={12} />
      </div>
      <div className="flex items-center gap-2 -mt-2 mb-4">
        <Skeleton width={87} height={12} />
        <Skeleton width={185} height={30} />
      </div>
      <div className="flex justify-between">
        <div className="flex flex-col items-center gap-4">
          <Skeleton width={100} height={32} />
          {[...Array(10)].map((_, index) => <Skeleton width={400} height={32} key={index} />)}
        </div>

        <div className="flex flex-col items-center gap-4">
          <Skeleton width={100} height={32} />
          {[...Array(10)].map((_, index) => <Skeleton width={400} height={32} key={index} />)}
        </div>

        <div className="flex flex-col items-center gap-4">
          <Skeleton width={100} height={32} />
          <div className="flex justify-between gap-4">
            <div className="flex-col ">
              {[...Array(10)].map((_, index) => {
                return <div className="flex justify-between mb-[14px] gap-4" key={index}>
                  {[...Array(8)].map((_, index) => <Skeleton width={40} height={32} key={index} />)}
                </div>
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-4">
          <Skeleton width={0} height={32} />
          {[...Array(10)].map((_, index) => <Skeleton width={60} height={32} key={index} />)}
        </div>
      </div>
    </div>
  </Container>
}
export default LeadScoringSkeleton;