import React, { useCallback, useEffect, useMemo, useState } from "react"
import { FormProvider, useForm } from 'react-hook-form'
import { useSearchParams } from "react-router-dom"
import Button from "../Button/Button"
import Icon from "../Icon/Icon"
import Tooltip from "../Tooltip/Tooltip"
import { FilterItem } from "./FilterItem"
import { SegmentEventENUM } from "utils/enums/segmentENUM"
import { cleanObject } from "utils/object"
export type FilterItemProps = {
    icon: string
    label: string
    name: string
    placeholder?: string
} & (FilterTypeSelectable | FilterTypeSearchable)
export interface Options {
    label: string, value: string | number
}
interface FilterTypeSelectable {
    type: 'selectable',
    options: Options[] | { label: string, options: Options[] }[],
    isMulti?: boolean
}
interface FilterTypeSearchable {
    type: 'searchable'
}
interface FilterProps {
    filters: FilterItemProps[]
    handleFilter: (filters: any) => void
}

export const Filter: React.FC<FilterProps> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [showFilterPanel, setShowFilterPanel] = useState(false)
    const form = useForm({
        defaultValues: props.filters.map(item => ({ [item.name]: item.type === 'searchable' ? '' : [] }))
    })
    const isDisabled = useMemo(() => {
        return !props.filters.some(item => {
            const value = form.watch(item.name as any) as any;
            return value !== "" && value?.length >= 1;
        });
    }, [form.watch()])

    const sendTrack = useCallback(() => {
        const filters: any = form.watch();
        const eventEnum = SegmentEventENUM.ANALYTICS_FILTER_APPLIED;
        const analyticsPayload = {
          title: filters?.job_titles, industry: filters?.industries, location: filters?.locations_person, department: filters?.departments,
        }
        const cleanedFilters = cleanObject(analyticsPayload);
        window.analytics.track(eventEnum, cleanedFilters)
      }, [form.watch()])

    const handleSubmitFilter = useCallback(() => {
        const filters = form.watch();
        const keys = Object.keys(filters);
        setSearchParams((prev) => {
            const startDate = prev.get('startDate')
            const endDate = prev.get('endDate')
            const newParams = new URLSearchParams();
            keys.forEach((key: any) => {
                const values: any = filters?.[key];
                if (values && (Array.isArray(values) ? values.length > 0 : values)) {
                    if (Array.isArray(values)) {
                        newParams.set(key, JSON.stringify(values.map((item: any) => item.value)));
                    } else {
                        newParams.set(key, values.value);
                    }
                }
            });
            startDate && newParams.set('startDate', startDate)
            endDate && newParams.set('endDate', endDate)
            return newParams;
        });
        sendTrack()
        props.handleFilter(filters)
    }, [form, searchParams, setSearchParams]);
    const handleClearFilter = useCallback(() => {
        form.reset()
        const newParams = new URLSearchParams();
        props.filters.map(item => newParams.delete(item.name))
        setSearchParams(() => newParams)
        props.handleFilter([])
    }, [form, props])
    useEffect(() => {
        props.filters.map(filter => {
            const params = searchParams.get(filter.name)
            const parsedParams = params && JSON.parse(params)
            if (parsedParams && filter.type === 'selectable') {
                let data: any = []
                filter?.options?.map((item: any) => {
                    if (item?.options?.length > 0) {
                        item?.options?.map((filterI: any) => {
                            parsedParams.map((i: string) => {
                                if (i == filterI.value) {
                                    data.push(filterI)
                                    return
                                }
                            })
                        })
                    } else {
                        parsedParams.map((i: string) => {
                            if (i == item.value) {
                                data.push(item)
                                return
                            }
                        })
                    }
                })
                form.setValue(`${filter.name}` as any, data)
            }
        })
    }, [])
    return (
        <FormProvider {...form}>
            <div className="md:hidden w-full">
                <Button className="w-full" onClick={() => setShowFilterPanel(true)}><Icon name="filter" /> Show  Filters</Button>
            </div>
            <div className={`${showFilterPanel ? '!top-0 !left-0' : 'hidden  -top-[400px] left-[-400px]'} md:hidden fixed z-[999999] bg-black/40 backdrop-blur-md h-full w-full`} onClick={() => setShowFilterPanel(false)}></div>
            <div className={`transition-all h-full md:h-auto duration-200 fixed  md:pt-0
                ${!showFilterPanel ? '-left-[400px] top-0' : '  top-0 left-0 z-[99999999] '}
              md:static bg-gray-900  md:rounded-lg md:flex flex-col min-w-[320px] max-w-[320px] md:h-[76vh]`}>
                <div className="flex flex-row  p-5 justify-between gap-2 w-full">
                    <Tooltip isFullWidth content={isDisabled ? "Kindly select atleast one filter" : ""}>
                        <Button
                            disabled={isDisabled}
                            onClick={() => handleSubmitFilter()}
                            iconName="filter"
                            iconSize={17}
                            size="sm" className="w-full"
                            isProcessingTitle="Fetching..."
                        >
                            Apply Filters
                        </Button>
                    </Tooltip>
                    <Button
                        disabled={isDisabled} size="sm"
                        onClick={handleClearFilter}
                        className="w-fit" iconName="trash" iconSize={14}
                    >Clear</Button>
                </div>
                <ul id="menu-parent" className="SearchMainFilter">
                    {props.filters.map(item => <FilterItem {...item} key={item.name} handleClearField={handleSubmitFilter} />)}
                </ul>
            </div>
        </FormProvider>
    )
}