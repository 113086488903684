import { InputField } from "components";
import { FC, useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import InputSelect from "../InputSelect/InputSelect";
import { ClientICPFilterOperatorENUM } from "components/modules/SearchMain/SearchMainHelper";
import { Options } from ".";

export const FilterItem: FC<any> = ({ type, state, name, label, labelInput, icon, options, placeholder, isSearchable = true, isMulti = true, handleClearField }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchInput, setSearchInput] = useState<string | number>("");
    const methods = useFormContext()
    const isActive = useMemo(() => Boolean(methods.watch(name)?.length), [methods.watch(name)])
    useEffect(() => {
        if (state === searchInput || type !== "input") return;
        setSearchInput(state as string);
    }, [state]);

    return (
        <li className={`${isActive ? "is_active" : ""} ${isOpen ? "is_open" : ""}`} onClick={() => { if (!isOpen) setIsOpen(!isOpen) }}>
            <div className="content-title flex items-center justify-evenly gap-2" onClick={() => setIsOpen(!isOpen)}>
                <div className="w-full flex items-center gap-2">
                    <Icon name={icon} size={20} />
                    {label}
                </div>
                {isActive && (
                    <Button size="xxs"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            methods.setValue(name, [])
                            handleClearField()
                        }}
                    >
                        {type !== "searchable" ? 1 : methods.watch(name)?.length}
                        <Icon name="close" size={12} />
                    </Button>
                )}
                <Icon name={isOpen ? "caret-up" : "caret-down"} size={15} />
            </div>
            <div className={`px-[20px] transition-all duration-500 overflow-hidden ${isOpen ? 'max-h-[4200px]  pb-4' : 'max-h-0'}`}>
                {type === "searchable" ? (
                    <form className="flex gap-2 items-center" onSubmit={(event) => { event.preventDefault() }}>
                        <Controller control={methods.control} name={name} defaultValue=''
                            render={({ field }) => <InputField variant="dark" placeholder={placeholder ?? label} size="small" value={field.value} onChange={(event: any) => field.onChange(event.target.value)} />} />
                    </form>
                ) : (
                    <Controller control={methods.control} name={name} defaultValue=''
                        render={({ field }) => {
                            return <InputSelect maxHeight="400px" scrollItems id={label === "Seniority" ? "seniority-select" : ""} label={labelInput} options={options} state={field.value}
                                placeholder={placeholder ?? label} isSearchable={isSearchable} isMulti={isMulti}
                                setState={(value: any) => {
                                    const hasIsKnowBefore = methods.watch(name).length > 0 && methods.watch(name).find((item: Options) => item.value === ClientICPFilterOperatorENUM.IS_REQUIRED)
                                    const isRequiredValueSelected = value.find((item: Options) => item.value === ClientICPFilterOperatorENUM.IS_REQUIRED)
                                    if (isRequiredValueSelected && !hasIsKnowBefore) {
                                        methods.setValue(name, [{ label: "Is Known", value: ClientICPFilterOperatorENUM.IS_REQUIRED }])
                                        handleClearField()
                                        return
                                    }
                                    if (!value.length || value === null) {
                                        methods.setValue(name, [])
                                        handleClearField()
                                        return
                                    }
                                    if (hasIsKnowBefore) value = value.filter((item: Options) => item.value !== ClientICPFilterOperatorENUM.IS_REQUIRED)

                                    field.onChange(value)
                                }}
                            />
                        }
                        }
                    />
                )}
            </div>
        </li>
    );
};
