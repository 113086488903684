import { Card } from "components/atoms/Card"
import { CardItem } from "components/atoms/CardItem"
import { LeadProfileDTO } from "utils/dtos/leadDTO"

export const PersonalInfo: React.FC<{ lead: LeadProfileDTO }> = ({ lead }) => {
    return <Card title="Person Info">
        <CardItem prefix="Business Email" value={lead.emailWork?.email} validity={lead.emailWork?.validity} isBadge isCopy showIcon />
        <CardItem prefix="Personal Email" value={lead.emailPersonal?.email} validity={lead.emailPersonal?.validity} isBadge isCopy showIcon />
        <CardItem prefix="Alt Email" value={lead.emailAlt?.email} validity={lead.emailAlt?.validity} isBadge isCopy borderBottom showIcon />
        <CardItem prefix="Phone" value={lead.phone?.number} validity={lead.phone?.validity} isBadge isCopy showIcon />
        <CardItem prefix="Alt Phone" value={lead.phoneAlt?.number} validity={lead.phoneAlt?.validity} isBadge isCopy borderBottom showIcon />
        <CardItem prefix="Personal Address" value={lead.address?.street} />
        <CardItem prefix="Personal City" value={lead.address?.city} />
        <CardItem prefix="Personal State" value={lead.address?.state} />
    </Card>
}