import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { ISubscription, ISubscriptionState } from "types/transaction_subscription/subscription.type";
import DomainHelper from "helpers/DomainHelper";

const baseUrl = DomainHelper.getAPIMain()
const initialState: ISubscriptionState = {
  isLoading: false,
  data: null,
  isSuccess: false,
  errorMessage: "",
  isLastPage: false,
  cancelSubscription: {
    data: "",
    isLoading: false,
    isSuccess: false,
    successMessage: "",
    errorMessage: "",
  },
  createSubscription: {
    data: {} as ISubscription,
    isLoading: false,
    isSuccess: false,
    successMessage: "",
    errorMessage: "",
  },
  updateSubscription: {
    data: {} as ISubscription,
    isLoading: false,
    isSuccess: false,
    successMessage: "",
    errorMessage: "",
  }
};

export const getSubscriptions = createAsyncThunk("subscription/get", async (_, thunkAPI) => {
  try {
    const response = await axios.post(`${baseUrl}subscription/get`, { withCredentials: true });
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
export const cancelSubscription = createAsyncThunk("subscription/cancel",
  async (subscriptionData: ISubscription, thunkAPI) => {
    try {
      const response = await axios.post(`${baseUrl}subscription/cancel`, {
        subscriptionId: subscriptionData.subscriptionId,
        withCredentials: true,
      });
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
});
export const createSubscription = createAsyncThunk("subscription/create", async (data: any, thunkAPI) => {
  try {
    const responseSubscription = await axios.post(`${baseUrl}subscription/create`,
      { subscriptionOptions: {
          paymentMethodId: data.paymentMethodId,
          numberOfCredits: +data.numberOfCredits,
          isSubscription: data.isSubscription,
          frequency: data.frequency,
          couponId: data?.couponId
      }},
      { withCredentials: true }
    );
    return thunkAPI.fulfillWithValue(responseSubscription.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
export const updateSubscription = createAsyncThunk("subscription/update", async (data: any, thunkAPI) => {
  try {
    const responseSubscription = await axios.post(`${baseUrl}subscription/update`,
      { subscriptionOptions: {
          paymentMethodId: data.paymentMethodId,
          numberOfCredits: +data.numberOfCredits,
          isSubscription: data.isSubscription,
          frequency: data.frequency,
          subscriptionData: data.subscriptionData,
          couponId: data?.couponId
        },
      },
      { withCredentials: true }
    );
    return thunkAPI.fulfillWithValue(responseSubscription.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    resetSubscriptionSlice: (state) => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscriptions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSubscriptions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.errorMessage = "";
      state.data = action.payload.data;
      state.isSuccess = true;
    });
    builder.addCase(getSubscriptions.rejected, (state, action: any) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message || "Something went wrong";
      state.isSuccess = false;
    });
    builder.addCase(cancelSubscription.pending, (state, action) => {
      state.cancelSubscription.isLoading = true;
    });
    builder.addCase(cancelSubscription.fulfilled, (state, action) => {
      state.cancelSubscription.isLoading = false;
      state.cancelSubscription.errorMessage = "";
      state.cancelSubscription.data = action.payload.data;
      state.cancelSubscription.isSuccess = true;
    });
    builder.addCase(cancelSubscription.rejected, (state, action: any) => {
      state.cancelSubscription.isLoading = false;
      state.cancelSubscription.errorMessage = action.payload?.message || "Something went wrong";
      state.cancelSubscription.data = null;
      state.cancelSubscription.isSuccess = false;
    });
    builder.addCase(createSubscription.pending, (state) => {
      state.createSubscription.isLoading = true;
    });
    builder.addCase(createSubscription.rejected, (state, action: any) => {
      state.createSubscription.isLoading = false;
      state.createSubscription.errorMessage = action.payload?.message || "Something went wrong";
      state.createSubscription.data = action.payload?.data || null
      state.createSubscription.isSuccess = false;
    });
    builder.addCase(createSubscription.fulfilled, (state, action) => {
      state.createSubscription.isLoading = false;
      state.createSubscription.errorMessage = "";
      state.createSubscription.data = action.payload.data;
      state.createSubscription.isSuccess = true;
    });
    builder.addCase(updateSubscription.pending, (state) => {
      state.updateSubscription.isLoading = true;
    });
    builder.addCase(updateSubscription.rejected, (state, action: any) => {
      state.updateSubscription.isLoading = false;
      state.updateSubscription.errorMessage = action.payload?.message || "Something went wrong";
      state.updateSubscription.data = initialState.data!;
      state.updateSubscription.isSuccess = false;
    });
    builder.addCase(updateSubscription.fulfilled, (state, action) => {
      state.updateSubscription.isLoading = false;
      state.updateSubscription.errorMessage = "";
      state.updateSubscription.data = action.payload.data;
      state.updateSubscription.isSuccess = true;
    });
  },
});

export const { resetSubscriptionSlice } = subscriptionSlice.actions;
