import { FaChartLine } from 'react-icons/fa';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from '../Skeleton/Skeleton';

const ChartSkeleton = () => {
  return (
    <div className="flex flex-1 h-full flex-col justify-between lato-bold gap-2 bg-gray-900 py-5 mt-2 max-h-[120px] rounded-[12px]">
      <div className="flex flex-row justify-between">
        <Skeleton width={124} height={20} />
        <div className='flex flex-row gap-4'>
          <div className={`flex lato flex-row items-center gap-2 `}>
            <Skeleton width={16} height={12} />
            <Skeleton width={120} height={12} />
          </div>
          <div className={`flex lato flex-row items-center gap-2 `}>
            <Skeleton width={12} height={12} />
            <Skeleton width={120} height={12} />
          </div>
          <div className={`flex lato flex-row items-center gap-2 `}>
            <Skeleton width={12} height={12} />
            <Skeleton width={120} height={12} />
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className='flex flex-col gap-4 mt-4'>
          <Skeleton width={24} height={16} />
          <Skeleton width={24} height={16} />
          <Skeleton width={24} height={16} />
          <Skeleton width={24} height={16} />
        </div>
        <FaChartLine size={44} className='animate-pulse text-paper-400' />
        <div></div>
      </div>
      <div className='flex flex-row justify-between w-full'>
        <Skeleton width={64} height={16} />
        <Skeleton width={64} height={16} />
        <Skeleton width={64} height={16} />
        <Skeleton width={64} height={16} />
      </div>
    </div>
  );
};
export default ChartSkeleton;