import { useNavigate, useLocation } from "react-router-dom";
import constants from "utils/constants";
import Icon from "../Icon/Icon";
interface BreadcumbProps {
    fullName?: string | null
    appSource: string | null
}
export const Breadcumb: React.FC<BreadcumbProps> = ({ appSource, fullName }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const UrlSearchParams = window.location.href?.split("?")?.[1];
    return (
        <div className="breadcumb">
            <p className="capitalize cursor-pointer"
                onClick={() => navigate(`/${(appSource === constants.PIXEL_KEYWORD || location.state?.from.includes(constants.PIXEL_KEYWORD))
                    ? constants.PIXEL_KEYWORD : constants.LEADSEARCH_KEYWORD
                    }${UrlSearchParams?.length > 0 ? `?${UrlSearchParams}` : ""}`)}>
                {(appSource === constants.PIXEL_KEYWORD || location.state?.from.includes(constants.PIXEL_KEYWORD)) ? constants.PIXEL_KEYWORD : constants.LEADSEARCH_KEYWORD}
            </p>
            <div className="divider"><Icon name="chevron-right" size={10} /></div>
            <span>{fullName}</span>
        </div>
    )
}