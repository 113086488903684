import {ClientICPFilterOperatorENUM} from "../components/modules/SearchMain/SearchMainHelper";

export default class EntryFormatter {
    static hasValue(entry: any): boolean {
        if (entry === undefined || entry === null) {
            return false;
        }
        if (typeof entry === 'number') return true;
        const trimmedEntry = entry.trim();
        return trimmedEntry !== '' && trimmedEntry.toLowerCase() !== 'unknown';
    }
    static exists(obj: any): boolean {
        return obj !== undefined && obj !== null && obj !== false;
    }
    static formatNumber(number: number) {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'K';
        } else {
            return number.toString();
        }
    }
    static formatLink(link: string) {
        if (!link) return ''
        if (!/^https?:\/\//i.test(link)) link = 'https://' + link;

        const urlObject = new URL(link);
        if (!urlObject.hostname.startsWith('www.')) urlObject.hostname = 'www.' + urlObject.hostname;

        return urlObject.href;
    }
    static firstLetterUppercase(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    static formatJobTitle(jobTitle: string | null | undefined) {
        if (!jobTitle) return "-"
        let title = jobTitle
            title = title.replace(/\bceo\b/gi, 'CEO')
        return title
    }
    static isObjectEmpty(obj: any) {
        if (typeof obj !== 'object') return true
        if (!this.exists(obj)) return true
        if (Object.keys(obj).length > 0) return false;
        return true
    }
    static dateIntoUnixTimestamp(date: any) {
        return Math.floor(new Date(date).getTime() / 1000)
    }
    static isArrayEmpty(data: any): boolean {
        if (!this.exists(data) || !data || !Array.isArray(data)) return true;
        return data.length === 0;
    }
    static arrayCreateFromNumber(n: number, startFromOne = true) {
        if (startFromOne) return Array.from({length: n}, (_, i) => i +1)
        return Array.from(Array(n).keys())
    }
    static arrayCreateStartingFromNumber(startingNumber: number, length: number) {
        const resultArray = [];
        for (let i = 0; i < length; i++) {
          resultArray.push(startingNumber + i);
        }
        return resultArray;
    }
    static isValidHttpUrl(string: string) {
        let url: URL;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }
    static displayPrice(price: number) {
        if (!price) return 0.00
        var price_formatted = new Intl.NumberFormat('en-US', {
            currency: "USD",
            style: 'currency', // 'decimal'
            // currencyDisplay: "symbol",
            // signDisplay: "exceptZero",
            // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        return price_formatted.format(price)
    }
    // static formatPrice(price: number) {
    //     return parseFloat(Number(price).toFixed(2))
    // }
    static sleep(ms: number) {
        const delay = ms
        var start = new Date().getTime();
        while (new Date().getTime() < start + delay);
    }

    static replaceLabelByValue (value: string) {
        switch (value) {
            case ClientICPFilterOperatorENUM.IS_REQUIRED:
                return "Is Known";
            default:
                return value;
        }
    }
}
