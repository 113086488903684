import React from "react";
import {default as MenuUI} from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import theme from "../../../assets/themes"
import Icon from "components/atoms/Icon/Icon";
const Menu: React.FC<{
    anchor: null | HTMLElement;
    setAnchor: any;
    items?: {
        description?: string;
        icon?: string;
        title: string;
        onClick: Function,
        isSelected?: boolean,
    }[],
    isOpen: boolean,
    children?: React.ReactNode
    position?: "left" | "center" | "right";
}> = ({ items = [], isOpen, anchor, setAnchor, children, position = 'left' }) => {
    return <MenuUI
            anchorEl={anchor}
            open={isOpen}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: position,
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: position,
            }}
            sx={{ overflowY: 'auto' }}
            onClose={()=> setAnchor(null)}
            slotProps={{
                paper: {
                    style: {
                        background: theme["--dropDown-bg"],
                        color: theme["--text-color-W"],
                        border: `1px solid ${theme["--border"]}`,
                        padding: 0,
                        marginTop: 10,
                        maxWidth: 330,
                        width: '100%',
                        borderRadius: 10,
                        WebkitBoxShadow: "4px 6px 5px 0px rgba(0,0,0,0.2)",
                        boxShadow: "4px 6px 5px 0px rgba(0,0,0,0.2)",
            }}}}
            MenuListProps={{ disablePadding: true, variant: "menu" }}
        >
            {items.length > 0 &&
                 items.length > 0 && items.map((i, index) => {
                    return <MenuItem
                            key={'menu-item'+index}
                            onClick={()=> {
                                if (i.isSelected) return
                                i.onClick();
                                setAnchor(null)
                            }}
                            style={{
                                color: i.isSelected ? 'white': `var(--color-white-500)`,
                                background: i.isSelected ? `var(--color-primary-500)` : 'transparent',
                                borderBottom: `1px solid ${theme["--border"]}`,
                                padding: "10px 16px",
                                gap: 5,
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: 14,
                                fontFamily: '"Inter", sans-serif',
                                textShadow: `3px 2px 3px ${theme["--color-gradient-black-500"]}`
                            }}
                            selected={i.isSelected}
                        >
                            <div className="flex flex-row items-center w-full">
                                {i.icon && <div className="mr-3"><Icon name={i.icon} size={25}/></div>}
                                <div className="flex flex-col">
                                    <span className="font-medium">{i.title}</span>
                                    <span className="text-sm text-gray-400 mt-px">{i.description}</span>
                                </div>
                                {i.isSelected && <Icon name="check" />}
                            </div>
                        </MenuItem>
                    
                })}
                {children && children}
        </MenuUI>
};

export default Menu;
