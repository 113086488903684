import React from "react";
import Icon from "../Icon/Icon";
import Skeleton from "../layout/Skeleton/Skeleton";

const LeadScore: React.FC<{ value?: number, threshold: number, loading: boolean, removeBg?: boolean }> = ({ value, threshold, loading, removeBg = false }) => {
  if (loading) return <Skeleton width={40} height={25} />
  if (value === undefined) value = 0;
  const level = value <= threshold * 0.33 ? "low" : value <= threshold * 0.66 ? "medium" : "high";
  const style =
    level === "high"
      ? `bg-greendark-500 text-[#5EC0A3]`
      : level === "medium"
        ? `bg-darkyellow-500 text-[#E6A457]`
        : `bg-darkred-500 text-[#EB5C5C]`;
  if (removeBg) {
    return (
      <div className="flex w-full justify-center items-center">
        <div className={` flex gap-2 w-fit justify-around items-center text-sm ${style} !bg-transparent flex p-0 !text-[26px]`}>
          <Icon size={26} name={level === "high" ? `star` : level === "medium" ? `face-meh` : `thumbs-down`} />
          {value}
        </div>
      </div>
    )
  }
  return (
    <div className="flex w-full justify-center items-center">
      <div className={`p-2 rounded-xl flex gap-2 w-fit justify-around min-w-[60px] max-w-[60px]  items-center text-sm ${style}`}>
        <Icon size={19} name={level === "high" ? `star` : level === "medium" ? `face-meh` : `thumbs-down`} />
        {value}
      </div>
    </div>
  );
}
export default LeadScore;