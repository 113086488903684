import { FilterItemProps } from "components/atoms/Filter";
import { GROUPED_OPTIONS_JOBTITLE, OPTIONS_DEPARTMENT, OPTIONS_INDUSTRY, OPTIONS_LOCATION, } from "components/modules/SearchMain/SearchMainHelper";
import dayjs from "dayjs";
import { useCallback, useEffect, useReducer, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getClientAnalytics, getClientAnalyticsICP, getClientAnalyticsVisitors, getClientGraph, getPanelConvertingVisitors, getPanelConvertionICP, getPanelDurationICP, getPanelVolumeVisitors, getQualityVolume, getQualityVolumePerson } from "store/slices/client.slice";
import { getClientICP } from "store/slices/icp.slice";
import { ActionUseAnalytics } from "../types/index.type";

export const useAnalytics = () => {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams()
    const [activePage, setActivePage] = useState('Overview')

    const visitorsMetrics = useAppSelector(state => state.client.metrics);
    const visitorsICP = useAppSelector(state => state.client.icp)
    const visitorGraph = useAppSelector(state => state.client.graph)
    const threshold = useAppSelector(state => state.icp.data?.threshold);
    const panelVolumeVisitors = useAppSelector(state => state.client.panelVolumeVisitors);
    const panelConvertingVisitors = useAppSelector(state => state.client.panelConvertingVisitors);
    const panelConvertionICP = useAppSelector(state => state.client.panelConvertionICP);
    const panelDurationICP = useAppSelector(state => state.client.panelDurationICP);
    const panelQualityVolume = useAppSelector(state => state.client.panelQualityVolume);
    const panelQualityVolumePerson = useAppSelector(state => state.client.panelQualityVolumePerson);

    const tabs = [{ name: 'Overview', icon: 'page' }, { name: 'User Journey', icon: 'compass' },
        //TODO Remover isso quando for attribuition pra produção
        //  { name: 'Attribution', icon: 'shuffle' }
    ]
    function reducer(state: any, action: ActionUseAnalytics) {
        if (action.type === 'update-date') {
            setSearchParams((prev) => {
                const newParams = new URLSearchParams(prev);
                action?.newDate?.[0] && newParams.set('startDate', action?.newDate?.[0])
                action?.newDate?.[1] && newParams.set('endDate', action?.newDate?.[1])
                return newParams
            })
            return { ...state, startDate: action?.newDate?.[0], endDate: action?.newDate?.[1], }
        }
        if (action.type === 'update-filters') {
            return { ...state, filters: { ...action.filters } }
        }
    }
    function init() {
        const startDate = searchParams.get('startDate')
        const endDate = searchParams.get('endDate')
        const jobParams = searchParams.get('job_titles')
        const industryParams = searchParams.get('industries') ?? null
        const departamentsParams = searchParams.get('departments') ?? null
        const userLocationparams = searchParams.get('locations_person') ?? null
        return {
            startDate: startDate ? startDate : dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
            endDate: endDate ? endDate : dayjs().format('YYYY-MM-DD'),
            filters: {
                job_titles: jobParams ? JSON.parse(jobParams) : null,
                industries: industryParams ? JSON.parse(industryParams) : null,
                locations_person: userLocationparams ? JSON.parse(userLocationparams) : null,
                departments: departamentsParams ? JSON.parse(departamentsParams) : null,
            }
        }
    }
    const [filterApplyed, filterDispatch] = useReducer(reducer, {
        startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
        filters: {
            job_titles: null,
            industries: null,
            locations_person: null,
            departments: null,
        }
    }, init);
    const filters: FilterItemProps[] = [
        {
            name: 'job_titles',
            icon: 'job',
            label: 'Title',
            placeholder: 'Ente job titles',
            options: GROUPED_OPTIONS_JOBTITLE,
            type: 'selectable'
        },
        {
            icon: 'industry',
            name: 'industries',
            label: 'Industry',
            type: 'selectable',
            options: OPTIONS_INDUSTRY,
        },
        {
            icon: 'user-location',
            name: 'locations_person',
            label: 'Location',
            placeholder: 'User Location',
            options: OPTIONS_LOCATION,
            type: 'selectable'
        },
        {
            icon: 'category',
            name: 'departments',
            label: 'Department',
            placeholder: 'Select a department',
            options: OPTIONS_DEPARTMENT,
            type: 'selectable'
        },
    ]
    const filteringData = () => {
        const { startDate, endDate, filters } = filterApplyed
        const filtersKeys = Object.keys(filters)
        let filtersTreated = {}
        filtersKeys.map(key => {
            const value = filters[key]
            if (!value) return
            if (value === 'string') return Object.assign(filtersTreated, { [key]: value })
            if (value.length < 1) return
            return Object.assign(filtersTreated, { [key]: JSON.stringify(value?.map((item: any) => item.value || item)) })
        })
        return { startDate, endDate, ...filtersTreated }
    }

    useEffect(() => {
        if (activePage === tabs[1].name
            //TODO Remover isso quando for attribuition pra produção
            //  || activePage === tabs[2].name
        ) return
        const { startDate, endDate, ...filterApplyed } = filteringData()
        // TODO: remove isso daqui caso precise buscar as informacoes da api 
        // dispatch(getPanelDurationICP({ date_start: startDate, date_end: endDate, ...filterApplyed }))
        // dispatch(getPanelConvertionICP({ date_start: startDate, date_end: endDate, ...filterApplyed }))
        // dispatch(getQualityVolume({ date_start: startDate, date_end: endDate, ...filterApplyed }))
        // dispatch(getQualityVolumePerson({ date_start: startDate, date_end: endDate, ...filterApplyed }))
        // dispatch(getPanelConvertingVisitors({ date_start: startDate, date_end: endDate, ...filterApplyed }))
        // dispatch(getPanelVolumeVisitors({ date_start: startDate, date_end: endDate, ...filterApplyed }))
        dispatch(getClientAnalyticsVisitors({ date_start: startDate, date_end: endDate, ...filterApplyed }))
        dispatch(getClientGraph({ date_start: startDate, date_end: endDate, ...filterApplyed }))
        dispatch(getClientAnalyticsICP({ date_start: startDate, date_end: endDate, ...filterApplyed }))

    }, [dispatch, filterApplyed, activePage]);
    useEffect(() => {
        if (activePage === tabs[0].name) return
        const { startDate, endDate, ...filterApplyed } = filteringData()
        dispatch(getClientAnalytics({ date_start: startDate, date_end: endDate, ...filterApplyed }))
    }, [filterApplyed, activePage])
    useEffect(() => {
        if (activePage === tabs[1].name
            //TODO Remover isso quando for attribuition pra produção
            //  || activePage === tabs[2].name
        ) return
        dispatch(getClientICP())
    }, [])


    return {
        filters, tabs, visitorsMetrics, visitorsICP, threshold, visitorGraph, filterApplyed, activePage,
        panelVolumeVisitors, panelConvertingVisitors, panelQualityVolumePerson, panelQualityVolume, panelDurationICP, panelConvertionICP,
        filterDispatch, setActivePage
    }
}