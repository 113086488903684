import { Icon, Tooltip } from "components";
import { toast } from "react-toastify";

export const CopyToClipboard: React.FC<{ text: string }> = ({ text }) => {
    return <Tooltip content="Copy Contact">
        <span className="cursor-pointer text-white-500"
            onClick={() => {
                navigator.clipboard.writeText(text);
                toast.success("Copied successfully", { toastId: "emailCopied" });
            }}>
            <Icon name="copy" size={17} />
        </span></Tooltip>
}