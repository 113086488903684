import { Card } from "components/atoms/Card"
import { CardItem } from "components/atoms/CardItem"
import { useMemo } from "react"
import { LeadProfileDTO } from "utils/dtos/leadDTO"
import EntryFormatter from "utils/EntryFormatter"
import pageDetails from "utils/pageDetails"

export const Conversions: React.FC<{ lead: LeadProfileDTO }> = ({ lead }: any) => {
    const preparedData = useMemo(() => {
        return Object.keys(JSON.parse(lead.formData)).map((key) =>
            ({ key: EntryFormatter.firstLetterUppercase(key), value: JSON.parse(lead.formData)[key] })
        )
    }, [lead])
    return <Card title={pageDetails.pixelConversion.title}>
        {preparedData.map((item, index) => <CardItem key={index} prefix={item.key} value={item.value} />)}
    </Card>
}