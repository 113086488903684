import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  addCreditsToCompany,
  getCompany,
  getTeamUserCreditsHistory,
  resetAddCredits,
  resetAddToCompany,
  resetAutoRecharge,
  resetResetCompanyUserCredits,
  resetTeamUserCreditsHistory,
} from "store/slices/company.slice";
import { Button, Container, Heading, Icon, InputField, Loading, Popover, Popup, Table } from "components";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { setCurrentPage } from "store/slices/ui.slice";
import CompanyTeamItem from "components/modules/Company/Team/Item/CompanyTeamItem";
import CompanyTeamInviteForm from "components/modules/Company/Team/Invite/Form";
import EntryFormatter from "utils/EntryFormatter";
import CompanyTeamHistory from "components/modules/Company/Team/History/History";
import CompanyRemoveTeamMember from "components/modules/Company/Team/RemoveTeamMember/RemoveTeamMember";
import { EUserRoles } from "types/user/user.type";


const CompanyPage: React.FC = () => {
  const
  [showPopupUserInvite, setShowPopupUserInvite] = useState<boolean>(false),
  [showPopupHistory, setShowPopupHistory] = useState<boolean>(false),
  [showPopupRemoveTeamMember, setShowPopupRemoveTeamMember] = useState<boolean>(false),
  isMobile = window.innerWidth <= 810
  const navigate = useNavigate();

  const [creditsToAdd, setCreditsToAdd] = useState<number>(0);
  const [currentUser, setCurrentUser] = useState<string>("");
  const { isLoading, isSuccess, company, errorMessage } = useAppSelector((state) => state.company);
  const {
    isLoading: isLoadingHistory,
    isSuccess: isSuccessHistory,
    errorMessage: errorMessageHistory,
    data: history,
  } = useAppSelector((state) => state.company.teamUserCreditsHistory);

  const {
    isLoading: addCreditsLoading,
    isSuccess: addCreditsIsSuccess,
    errorMessage: addCreditsErrorMessage,
    successMessage: addCreditsSuccessMessage,
  } = useAppSelector((state) => state.company.addCredits);

  const {
    isLoading: resetCreditsLoading,
    isSuccess: resetCreditsSuccess,
    errorMessage: resetCreditsErrorMessage,
    successMessage: resetCreditsSuccessMessage,
  } = useAppSelector((state) => state.company.resetCreditsFromUser);

  const {
    isLoading: addToCompanyLoading,
    isSuccess: addToCompanyIsSuccess,
    errorMessage: addToCompanyErrorMessage,
    successMessage: addToCompanySuccessMessage,
  } = useAppSelector((state) => state.company.addCreditsToCompany);

  const {
    isLoading: autoRechargeLoading,
    isSuccess: autoRechargeSuccess,
    errorMessage: autoRechargeErrorMessage,
    successMessage: autoRechargeSuccessMessage,
  } = useAppSelector((state) => state.company.autoRecharge);

  const { data: user, isSuccess: isSuccessUser } = useAppSelector((state) => state.user);
  const isAdmin = user?.userRole === EUserRoles.ADMIN 

  const dispatch = useAppDispatch();
  const handleAddCreditsToCompany = async () => {
    if (creditsToAdd < 1 || !creditsToAdd) {
      return toast.error("Please fill correct value", {toastId: 'error-correct-value'});
    } else if (user.creditBalance < creditsToAdd) {
      return toast.error("You don't have enough credits");
    }
    await dispatch( addCreditsToCompany({ companyId: company.id, nOfCredits: creditsToAdd, }) );
    setAnchorAdd(null)
  },
  handleTeamUserCreditsHistory = (userId: string) => {
    dispatch(resetTeamUserCreditsHistory())
    setShowPopupHistory(true)
    dispatch(getTeamUserCreditsHistory({userId}))
  }
  const handleRemoveTeamMember = (userId: string) => {
    setCurrentUser(userId)
    setShowPopupRemoveTeamMember(true)
  }
  const [anchorAdd, setAnchorAdd]= useState<null | HTMLElement>(null);
  const openAdd = Boolean(anchorAdd);

  useEffect(() => {
   window.analytics.page("Company")
   dispatch(setCurrentPage("company"));
  }, []);

  
  
  useEffect(() => {
    if (!isSuccessUser) return
    if (user.isInvitedUser) navigate("/dashboard");
  }, [isSuccessUser]);

  useEffect(() => {
    if (addToCompanyIsSuccess && addToCompanySuccessMessage) {
      toast.success(addToCompanySuccessMessage);
    } else if (addToCompanyErrorMessage) {
      toast.error(addToCompanyErrorMessage);
    }
    dispatch(getCompany());
    dispatch(resetAddToCompany());
  }, [addToCompanyErrorMessage, addToCompanyIsSuccess]);

  useEffect(() => {
    if (addCreditsIsSuccess || addCreditsSuccessMessage) {
      toast.success(addCreditsSuccessMessage);
      dispatch(getCompany());
    } else if (addCreditsErrorMessage) {
      toast.error(addCreditsErrorMessage);
    }
    dispatch(resetAddCredits());
  }, [addCreditsIsSuccess, addCreditsErrorMessage]);

  useEffect(() => {
    if (resetCreditsSuccess || resetCreditsSuccessMessage) {
      toast.success(resetCreditsSuccessMessage);
      dispatch(getCompany());
    } else if (resetCreditsErrorMessage) {
      toast.error(resetCreditsErrorMessage);
    }
    dispatch(resetResetCompanyUserCredits());
  }, [resetCreditsErrorMessage, resetCreditsSuccess]);

  useEffect(() => {
    if (autoRechargeSuccess || autoRechargeSuccessMessage) {
      toast.success(autoRechargeSuccessMessage);
      dispatch(getCompany());
    } else if (autoRechargeErrorMessage) {
      toast.error(autoRechargeErrorMessage);
    }
    dispatch(resetAutoRecharge());
  }, [autoRechargeErrorMessage, autoRechargeSuccess]);
  return (<>
    <Container>
        <Heading
          className="text-2xl"
          title="Company Team" icon="people"
          description="Get your project up and running faster by directly inviting your team members to your company."
        />
        <div className="interface shadow-none">
          <div className="bg-[var(--color-gray-900)] interface_heading"><div className="interface_heading_row items-start sm:items-end">
            <div className="w-full">
              {!EntryFormatter.isObjectEmpty(company) && <>
                <h3 className="text-xl">{company.name}</h3>
                <div className="mt-2 flex items-center gap-3">
                  <p className="hidden sm:flex text-sm text-gray-300">Company credits:</p>
                  <div className="badge primary mr-1"><Icon name="credits" /> {company.creditBalance || 0}</div>
                  <Button
                    onClick={(e: React.FormEvent<HTMLButtonElement>) => setAnchorAdd(e.currentTarget)}
                    iconName="plus" type="secondary"
                    size="sm" style={{width: 'auto', height: 32}}
                  >Add Credits</Button>
                </div>
              </>}
                {/* use later
                <ToggleButton
                  state={view} setState={setView}
                  items={[
                    {label: "Active Users", value: "active", icon: "people", onClick: ()=>setView("active"), isSelected: view === "active"},
                    // {label: "Invited", value: "invited", icon: "paperplane", onClick: ()=>setView("invited"), isSelected: view === "invited"},
                ]} /> */}
            </div>
            {isAdmin && <Button style={{ width: "max-content"}} iconName="user-plus" size="sm" onClick={() => setShowPopupUserInvite(true)}>Invite team member</Button> }
          </div></div>
          <Table className="bg-[var(--color-gray-900)]" style={{ height: '548px', overflowY: 'auto' }}>
            <thead className="bg-[var(--color-gray-900)]"><tr>
              <th className="w-3/6 sm:w-1/4 text-left">Email</th>
              <th className="w15 hidden sm:table-cell text-left">Name</th>
              <th className="w20 hidden sm:table-cell">{isAdmin && 'Credits'}</th>
              <th className="hidden sm:table-cell w-auto border-none">{isAdmin && 'Actions'}</th>
            </tr></thead>
            <tbody className="bg-[var(--color-gray-900)]">
              {!isLoading
                ? <>
                  {isSuccess && <>
                    {company && company.users.length > 0
                      ? company?.users?.map((user) => (
                        <CompanyTeamItem
                          user={user} key={user.id}
                          handleTeamUserCreditsHistory={handleTeamUserCreditsHistory}
                          addCreditsLoading={addCreditsLoading && currentUser === user.id}
                          resetCreditsLoading={resetCreditsLoading && currentUser === user.id}
                          autoRechargeLoading={autoRechargeLoading && currentUser === user.id}
                          setCurrentUser={setCurrentUser}
                          handleRemoveTeamMember={handleRemoveTeamMember}
                        />
                      ))
                      : <tr><td colSpan={isMobile ? 2:4}><div className="interface_noResults">
                          <Icon name="people" size={60} />
                          <p className="text-lg">Start by inviting team members</p>
                          <ol className="text-sm text-gray-300 list-decimal pl-5">
                            <li style={{ listStyleType: 'decimal' }}>To begin, invite your team members to collaborate effectively.</li>
                            <li style={{ listStyleType: 'decimal' }}>Make sure that each member has the necessary access to contribute.</li>
                            <li style={{ listStyleType: 'decimal' }}>Monitor team progress and provide support as needed.</li>
                          </ol>
                          {isAdmin && <Button
                            iconName="user-plus" size="sm"
                            onClick={() => setShowPopupUserInvite(true)}
                          >Invite Team Member</Button>}
                        </div></td></tr>
                    }
                  </>}
                  {errorMessage && (
                    <tr><td colSpan={isMobile ? 2:4}><div className="interface_noResults">
                      <Icon name="people" size={60} />
                      <p className="text-lg">{errorMessage}</p>
                    </div></td></tr>
                  )}
                </>
                : <tr><td colSpan={isMobile ? 2:4}><Loading height="220px" /></td></tr>
              }
            </tbody>
          </Table>
          <div className="bg-[var(--color-gray-900)] interface_footer"><div className="interface_heading_row items-start sm:items-end">
              { company && company?.users?.length > 0 && <div className="w-full">
                  <div className="mt-2 flex items-center gap-1">
                    <p className="hidden sm:flex text-sm text-gray-300">Showing</p>
                    <div className="text-sm text-white-500">1-{company.users.length}</div>
                    <p className="hidden sm:flex text-sm text-gray-300">of</p>
                    <div className="text-sm text-white-500">{company.users.length}</div>
                  </div>
              </div>}
          </div></div>
        </div>
    </Container>
    <Popover isOpen={openAdd} anchor={anchorAdd} setAnchor={setAnchorAdd}><div className="gap-3 flex flex-col">
      <b className="text-sm">Add Credits to the Company</b>
      <div className="flex items-center gap-3 w-full">
        <InputField
          maxLength={4}
          onChange={(e: React.FormEvent<HTMLInputElement>) => setCreditsToAdd(+e.currentTarget.value)}
          disabled={addToCompanyLoading}
          iconName="credits" autoFocus
          size="small" width="auto"
          placeholder="Enter the amount"
        />
        <Button
          onClick={handleAddCreditsToCompany}
          isProcessing={addToCompanyLoading}
          size="sm"
        >Confirm</Button>
        {/* TODO - Arman's work (check what is that) <Button onClick={() => setShowHistory(true)}>Show History</Button> */}
    </div></div></Popover>
    {showPopupHistory &&
      <Popup onClose={()=>setShowPopupHistory(false)}>
          <CompanyTeamHistory
            history={history || []}
            errorMessageHistory={errorMessageHistory}
            isLoadingHistory={isLoadingHistory}
            isSuccessHistory={isSuccessHistory}
          />
      </Popup>
    }
    {showPopupUserInvite &&
      <Popup
        closeOnOutsideClick={false}
        width="sm" onClose={()=>setShowPopupUserInvite(false)}
      >
        <CompanyTeamInviteForm closePopup={()=>setShowPopupUserInvite(false)} />
      </Popup>
    }
    {showPopupRemoveTeamMember &&
      <Popup
        closeOnOutsideClick={false}
        onClose={()=>setShowPopupRemoveTeamMember(false)}
      >
        <CompanyRemoveTeamMember memberId={currentUser} closePopup={()=>setShowPopupRemoveTeamMember(false)} />
      </Popup>
    }
  </>);
};
export default CompanyPage;