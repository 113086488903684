import React, { useEffect, useMemo, useRef, useState } from 'react';
import { flexRender, Row } from '@tanstack/react-table';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { InterfacePixelLeadData, getLeadStats, setLeadStats } from 'store/slices/insights';
import Skeleton from 'components/atoms/layout/Skeleton/Skeleton';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import constants from 'utils/constants';
import { getPersonalInfo } from 'store/slices/user.slice';
import Loading from 'components/atoms/Loading/Loading';

const PulseRow: React.FC<{ row: Row<InterfacePixelLeadData>; }> = ({ row }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [isVisible, setIsVisible] = useState(false); // Track row visibility

  searchParams.set('app_source', constants.PIXEL_KEYWORD)
  const observerRef = useRef<HTMLTableRowElement | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const { isLoading: CRIsLoading, isSuccess: CRIsSuccess, data: CRData } = useAppSelector((state) => state.insights.contactsRevealed.find((x) => x.id === row.original.id)) ?? {};
  const { leadsStats } = useAppSelector((state) => state.insights);
  const stats = leadsStats.find((i) => i.leadId === row.original.id);

  const getRowStats = useMemo(() => async () => {
    if (stats || !isVisible) return; // Fetch only if visible and not already fetched
    if (!row.original.duration || !row.original.pageViews || !row.original.visits) {
      dispatch(getLeadStats({ leadId: row.original.id }));
    }
  }, [row.original.id, isVisible]);
  useEffect(() => { getRowStats() }, [isVisible]);
  useEffect(() => {
    if (!stats) return;
    dispatch(setLeadStats({
      id: row.original.id,
      duration: stats.duration,
      visits: stats.visits,
      pageViews: stats.pageViews,
      score: stats.leadScore,
    } as any));
  }, [stats]);
  useEffect(() => {
    if (!CRIsSuccess || !CRData) return;
    setTimeout(() => { dispatch(getPersonalInfo()); }, 10000);
  }, [CRIsSuccess]); // eslint-disable-line
  // Set up Intersection Observer
  useEffect(() => {
    const callback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          if (observer.current) { observer.current.disconnect(); }
        }
      });
    }
    if (observerRef.current && !observer.current) {
      observer.current = new IntersectionObserver(callback, {
        root: null, threshold: 0.1, // Trigger when 10% of the row is visible
      });
      observer.current.observe(observerRef.current);
    }
    return () => observer.current?.disconnect();
  }, []);
  return <tr ref={observerRef}>
    {row.getVisibleCells().map((cell) => {
      const value = cell.getValue() as any;
      const valueRendered = flexRender(cell.column.columnDef.cell, cell.getContext())
      const currentParams = searchParams.toString();
      const urlPrepared = `/lead/${row.original.id}?${currentParams}` || ''
      return <td key={cell.id} className={`min-w-[150px]`}
        style={{ textAlign: cell.column.id === 'orgName' ? 'left' : 'center', width: cell.column.columnDef.meta?.size ?? 'auto' }}
        title={typeof value === 'string' && value.length > 20 ? value : ''}
      >
        {(['visits', 'pageViews', 'duration'].includes(cell.column.id) && !value) ? <>
          {!stats?.visits && cell.column.id === 'visits' && <Skeleton width={40} height={20} />}
          {!stats?.pageViews && cell.column.id === 'pageViews' && <Skeleton width={40} height={20} />}
          {!stats?.duration && cell.column.id === 'duration' && <Skeleton width={40} height={20} />}</>
          :
          <Link to={urlPrepared} state={{ from: location.pathname }} replace={false}>
            {cell.column.id === 'isContactRevealed' ? <>{CRIsLoading ? <Loading height="auto" spinnerSize={32} /> : valueRendered}</> : valueRendered}
          </Link>}
      </td>
    })}
  </tr>
};
export default PulseRow;