import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import EntryFormatter from "utils/EntryFormatter"
import constants from "utils/constants"

export const useTitlePage = () => {
    const location = useLocation()
    useEffect(() => {
        if (location.pathname === '/') {window.document.title = `${EntryFormatter.firstLetterUppercase(constants.PIXEL_KEYWORD)} - ${constants.BRAND_NAME}`
        return}
        const [_, pathname] = location.pathname.split('/')
        const [firstLetter, ...rest] = pathname
        const concat = `${firstLetter.toUpperCase()}${rest.join('')}`
        window.document.title = `${concat} - ${constants.BRAND_NAME}`
    }, [location])
}