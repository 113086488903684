import React, { useEffect, useState } from "react";
import { ConfigProvider, DatePicker } from "antd";
import dayjs from "dayjs";

interface DateRangePickerProps {
  setValue: (value: (string | null)[]) => void;
  state: string[];
  useValue?: boolean
  format?: any
}

const { RangePicker } = DatePicker;

const customTheme = {
  components: {
    DatePicker: {
      addonBg: "var(--color-paper-600)",
      cellActiveWithRangeBg: "var(--color-primary-500)",
      activeBorderColor: "var(--color-primary-400)",
      activeShadow: "var(--color-primary-400)"
    }
  },
  token: {
    colorBgContainer: "var(--color-gray-900)",
    colorPrimary: "#813efb",
    colorText: "#ffffff",
    colorBorder: "#323441",
    colorBgElevated: "#262836",
    colorIcon: "#ffffff",
    colorTextBase: "rgb(209 213 219)",
    colorBgBase: "#813efb",
  },
}
const DateRangePicker: React.FC<DateRangePickerProps> = ({
  setValue,
  state,
  useValue,
  format
}) => {
  return (
    <ConfigProvider theme={customTheme}>
      <RangePicker
        maxDate={dayjs()}
        value={useValue ? state : (state.length === 2 ? [dayjs(state[0]), dayjs(state[1])] : state.length === 0 ? [null, null] : [dayjs(state[0]), null])} // Atualizado para usar a propriedade value
        allowEmpty={[false, true]}
        allowClear={false}
        onChange={(dates, dateStrings) => {
          if (!dates) {
            return setValue([]);
          }
          if (dateStrings[1] === "") dateStrings.pop();
          setValue(dateStrings);
        }}
        placeholder={["Start date", "End date"]}
        {...(format && { format })}
        style={{
          width: "100%",
          padding: "10px",
        }}
      />
    </ConfigProvider>
  );
};
export default DateRangePicker;
