
export enum EnrichmentOrigin {
    IDENTITY_MATRIX = 1,
    TRAFFIC_INTEL = 2,
    BULK_ENRICHMENT = 3,

    SINGLE_ENRICHMENT_EMAIL = 4,
    SINGLE_ENRICHMENT_LINKEDIN = 5,
    SINGLE_ENRICHMENT_PHONE = 6,
    SINGLE_ENRICHMENT_NAME_COMPANY = 7,
    SINGLE_ENRICHMENT_ADDRESS = 8,
}
export enum EnrichmentTaskStatusENUM {
    SUCCESS = "200",
    PROCESSING = "102",
    CREDITS_INSUFFICIENT = "402",
    
    SUCCESS_OLD = "success",
    PROCESSING_OLD = "processing",
    FAILURE_OLD = "failure",
    ERROR_OLD = "error",
}
export enum EnrichmentContactRevealSourceENUM { SEARCH = "search", PIXEL = "pixel" }