import Icon from "components/atoms/Icon/Icon";
import Loading from "components/atoms/Loading/Loading";
import BulkItem from "components/modules/Bulk/Item/Item";
import { EnrichmentDTO } from "utils/dtos/enrichmentDTO";

const LeadHistoryBodyBulk: React.FC<{
  data: EnrichmentDTO[];
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
  isMobile: boolean;
  tableColumns: number;
}> = ({ data, isLoading, isSuccess, errorMessage, isMobile, tableColumns }) => {
  return (
    <>
      <thead>
        <tr style={{background: "var(--color-gray-900)", overflow: "hidden", border: "none" }}>
          <th
            style={{
              fontSize: "13px",
              fontWeight: "normal",
              color: "var(--color-gray-400)",
              background: "var(--color-gray-900)",
              border: "none",
            }}
            className="w25 text-left "
          >
            File Name
          </th>
          <th
            style={{
              fontSize: "13px",
              fontWeight: "normal",
              color: "var(--color-gray-400)",
              background: "var(--color-gray-900)",
              border: "none",
            }}
            className="w25  table-cell"
          >
            Leads
          </th>

          <th
            style={{
              fontSize: "13px",
              fontWeight: "normal",
              color: "var(--color-gray-400)",
              background: "var(--color-gray-900)",
              border: "none",
            }}
            className="w25  table-cell"
          >
            Date & Time
          </th>

          <th
            style={{
              fontSize: "13px",
              fontWeight: "normal",
              color: "var(--color-gray-400)",
              background: "var(--color-gray-900)",
              border: "none",
            }}
            className="w25"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody
        style={{
          backgroundColor: "var(--color-gray-900)",
          border: "none",
          boxShadow: "none",
        }}
      >
        {!isLoading ? (
          <>
            {isSuccess && data.length > 0 && data.map((item: EnrichmentDTO, i) => <BulkItem index={i} data={item} key={item.enrichmentId} />)}
            {(errorMessage || (isSuccess && !data.length)) && (
              <tr>
                <td colSpan={isMobile ? 2 : tableColumns}>
                  <div className="flex flex-col items-center w-full py-20 gap-5">
                    <Icon name="document" size={50} />
                    <p className="text-lg">No Lists found</p>
                  </div>
                </td>
              </tr>
            )}
          </>
        ) : (
          <tr style={{ borderTop: "1px solid var(--color-paper-600)" }}>
            <td colSpan={isMobile ? 2 : tableColumns}>
              <Loading height="220px" />
            </td>
          </tr>
        )}
      </tbody>
    </>
  );
};

export default LeadHistoryBodyBulk;
