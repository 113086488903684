export default class TrackingHelper {
  static getUtmMetrics() {
    // Retrieve the 'utm_params' cookie value
    const cookieValue = this.getCookie('utm_params');
    // Parse and return the JSON object if the cookie exists
    if (cookieValue) {
      try {
        return JSON.parse(cookieValue);
      } catch (e) {
        console.error('Error parsing utm_params cookie:', e);
        return null;
      }
    } else {
      // Return null if the cookie does not exist
      return null;
    }
  }
  // Function to get the value of a cookie by name
  private static getCookie(name: any) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
  }
  static storeUTMparams() {
    // List of query parameters to capture
    var paramsToCapture = ['utm_campaign', 'utm_source', 'utm_medium', 'fbclid', 'gclid', 'utm_term', 'utm_content'];

    // Function to get the value of a query parameter by name
    function getQueryParam(name: any) {
      var regex = new RegExp('[?&]' + name + '=([^&#]*)', 'i');
      var match = regex.exec(window.location.search);
      return match ? decodeURIComponent(match[1]) : null;
    }

    // Function to set a cookie with a specific name, value, and expiration time (in hours)
    function setCookie(name: any, value: any, hours: any) {
      var expires = '';
      if (hours) {
        var date = new Date();
        date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
        expires = '; expires=' + date.toUTCString();
      }
      // Set cookie for the current subdomain
      var domainParts = window.location.hostname.split('.');
      var baseDomain = domainParts.slice(-2).join('.');
      document.cookie = name + '=' + encodeURIComponent(value) + expires + '; path=/; domain=' + baseDomain;
    }

    // Retrieve existing utm_params cookie
    var existingParams = this.getCookie('utm_params');
    var existingParamsObj = existingParams ? JSON.parse(existingParams) : {};

    // Object to store new parameters
    var newParamsObj: any = {};

    // Flag to check if new parameters are found
    var newParamsFound = false;

    // Iterate over the list of parameters to capture
    paramsToCapture.forEach(function(param) {
      var value = getQueryParam(param);
      if (value) {
        var key = param.replace(/^utm_/, ''); // Remove 'utm_' prefix from the key
        newParamsObj[key] = value;
        // Check if the parameter is new or has a different value
        if (existingParamsObj[key] !== value) {
          newParamsFound = true;
        }
      }
    });

    // If new parameters are found, update the cookie
    if (newParamsFound) {
      var newParamsJson = JSON.stringify(newParamsObj);
      setCookie('utm_params', newParamsJson, 2); // Set cookie to expire in 2 hours
    }
  }
  static getUTMParametersViaURL(referrer: any, urlParams: URLSearchParams) {
      const getMediumSource = this.getUTMMediumAndSourceReferrer(referrer, urlParams);
      return {
        utmSource: getMediumSource.source || null,
        utmMedium: getMediumSource.medium || null,
        utmTerm: urlParams.get("utm_term") || null,
        utmCampaign: urlParams.get("utm_campaign") || null,
        utmContent: urlParams.get("utm_content") || null,
  };}
  private static getUTMMediumAndSourceReferrer(referrer: any, urlParams: any) {
      var source = urlParams.get("utm_source") || null;
      var medium = urlParams.get("utm_medium") || null;
      if (referrer && !source && !medium) {
        const referrerHostname = new URL(referrer).hostname.replace("www.", "");
        const searchEngines = [ "google", "bing", "yahoo", "duckduckgo", "yandex", "baidu" ];
        for (var engine of searchEngines) {
          if (referrerHostname.includes(engine)) {
            if (!source) source = engine;
            if (!medium) medium = "organic";
            break;
        }}
        if (!source) {
          source = referrerHostname;
          medium = "referral";
      }}
      return { source, medium };
  }
}