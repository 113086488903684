export enum SegmentEventENUM {
    PLAN_CLICKED = "Plan Clicked",
    LEAD_FILTER_APPLIED = "Lead Filter Applied",
    DATABASE_FILTER_APPLIED = "Database Filter Applied",
    ANALYTICS_FILTER_APPLIED = "Analytics Filter Applied",
    LEAD_PAGE_ADDED = "Lead Page Added",
    LEAD_PAGE_UPDATED = "Lead Page Updated",
    LEAD_PAGE_DELETED = "Lead Page Deleted",
    LEAD_THRESHOLD_SET = "Lead Threshold Set",
    INTEGRATION_EXPLORED = "Integration Explored",
    CLAY_WEBHOOK_SAVED = "Clay Webhook Saved",
    CLAY_WEBHOOK_DELETED = "Clay Webhook Deleted",
    ACCOUNT_CREATED = "Account Created",
}