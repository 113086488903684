/* eslint-disable react-hooks/exhaustive-deps */
import { Route, Routes, useLocation } from "react-router-dom";
import Signup from "../pages/User/Auth/Signup";
import Signin from "../pages/User/Auth/Signin";
import ResetPasswordPage from "../pages/ResetPassword/ForgotPassword";
import MyAccount from "../pages/MyAccount/MyAccount";
import Home from "../pages/Dashboard/Dashboard";
import AccessDenied from "../pages/AccessDenied/AccessDenied";
import React, { useEffect, useState } from "react";
import Protected from "layouts/ProtectedLayout/ProtectedLayout";
import ProtectedBack from "layouts/ProtectedLayout/ProtecteBack";
import Main from "pages/Main/Main";
import Error from "pages/Error/Error";
import Billing from "pages/Billing/Billing";
import SuccessPage from "pages/SuccessPage/SuccessPage";
import { useAppSelector } from "store/hooks";
import EntryFormatter from "utils/EntryFormatter";
import TermsOfService from "pages/Institucional/TermsOfService";
import CompanyPage from "pages/Company/CompanyTeam";
import PulseModule from "components/modules/Pulse/PulseModule";
import SearchMainModule from "components/modules/SearchMain/SearchMainModule";
import LeadViewModule from "components/modules/Lead/View/LeadViewModule";
import constants from "utils/constants";
import LeadHistoryBulk from "components/modules/Lead/History/HistoryBulk";
import SignupConfirmAccount from "pages/User/Auth/SignupConfirmAccount";
import Pricing from "pages/Plan/Pricing";
import { IntercomContext } from "utils/context/IntercomContext";
import LeadScoringModule from "components/modules/scoring/LeadScoringModule";
import IntegrationsModule from "components/modules/IntegrationsModule";
import CompanyPages from "../components/molecules/CompanyPages/CompanyPages";
import AnalyticsModule from "components/modules/Analytics/AnalyticsModule";
import AnalyticsViewBlock from "components/modules/Analytics/AnalyticsViewBlock";
import CheckYourEmail from "pages/CheckYourEmail/CheckYourEmail";
import UpdatePasswordPage from "pages/UpdatePassword/UpdatePassword";
import MaintenancePage from "pages/Maintenance/MaintenancePage";
declare global {
  interface Window {
    analytics?: any;
  }
}
const localStorageSessionStartTimeKey = "sessionStartTime";
const MyRouter: React.FunctionComponent = () => {
  // const isMobile = window.innerWidth <= 810; // Adjust the breakpoint as needed
  const location = useLocation();
  const [locationCurrent, setLocationCurrent] = useState("");
  const { data: user } = useAppSelector((state) => state.user);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const handleSessionIsOver = () => {
    // const durationInSeconds = (Date.now() - Number(localStorage.getItem(localStorageSessionStartTimeKey))) / 1000;
    // SegmentAgent.event(
    //   { userId: user.userId,
    //     durationInMilliSeconds: Math.floor(durationInSeconds) * 1000,
    //     durationInSeconds: Math.floor(durationInSeconds),
    //     durationInMinutes: Math.floor(durationInSeconds / 60),
    //   },
    //   user.userId, "USER_SESSION_DURATION", user.platformBrandId
    // );
    localStorage.removeItem(localStorageSessionStartTimeKey);
  };
  useEffect(() => {
    if (EntryFormatter.isObjectEmpty(user) || (locationCurrent && locationCurrent === location.pathname && isPageLoaded)) return;
    setIsPageLoaded(true);
    setLocationCurrent(location.pathname);
  }, [user, location]);
  useEffect(() => {
    if (EntryFormatter.isObjectEmpty(user) || location.pathname === "/user/billing/success") return;
    const storedStartTime = localStorage.getItem(localStorageSessionStartTimeKey);
    if (!storedStartTime) {
      // SegmentAgent.event(
      //   { dateStarted: new Date() },
      //   user.userId,
      //   "USER_SESSION_START",
      //   user.platformBrandId
      // );
      localStorage.setItem( localStorageSessionStartTimeKey, Date.now().toString() )
    }
    window.addEventListener("beforeunload", handleSessionIsOver);
    return () => {window.removeEventListener("beforeunload", handleSessionIsOver);};
  }, [user]);
  return ( 
    <Routes>
      <Route path="/" element={<Protected><IntercomContext><Home /></IntercomContext></Protected>} />
      <Route path="/dashboard" element={<Protected><IntercomContext><Home /></IntercomContext></Protected>} />
      <Route path={`/${constants.PIXEL_KEYWORD}`} element={<Protected><IntercomContext><PulseModule /></IntercomContext></Protected>} />
      <Route path={`/${constants.LEADSEARCH_KEYWORD}`} element={<Protected><IntercomContext><SearchMainModule /></IntercomContext></Protected>} />
      <Route path="/scoring" element={<Protected><IntercomContext><LeadScoringModule /><CompanyPages /></IntercomContext></Protected>} />
      <Route path="/integrations" element={<Protected><IntercomContext><IntegrationsModule /></IntercomContext></Protected>} />
      <Route path="/lead/:leadId" element={<Protected><LeadViewModule /></Protected>} />
      <Route path="/analytics" element={<Protected><AnalyticsModule/></Protected>} />
      <Route path="/analytics/view" element={<Protected><AnalyticsViewBlock /></Protected>} />
      <Route path="/user/myAccount" element={<Protected><MyAccount /></Protected>} />
      <Route path="/user/billing" element={<Protected><Billing /></Protected>} />
      <Route path="/user/billing/success" element={<Protected><SuccessPage /></Protected>} />
      <Route path="/company" element={<Protected><CompanyPage /></Protected>} />
      <Route path="/accessDenied" element={<AccessDenied />} />
      <Route path="/forgot-password" element={<ProtectedBack><ResetPasswordPage /></ProtectedBack>} />
      <Route path="/reset-password" element={<UpdatePasswordPage />} />
      <Route path="/signup" element={<ProtectedBack><Signup /></ProtectedBack>} />
      <Route path="/signup/confirm-account" element={<ProtectedBack><CheckYourEmail /></ProtectedBack>} />
      {/* <Route path="/signup/confirm-password" element={<ProtectedBack><SignupConfirmAccount /></ProtectedBack>} /> */}
      <Route path="/auth/verifyAccount/:token" element={<SignupConfirmAccount />} />
      <Route path="/signin" element={<ProtectedBack><Signin /></ProtectedBack>} />
      <Route path="/pricing" element={<ProtectedBack><Pricing /></ProtectedBack>} />
      {/* <Route path="/buy-credits" element={<Protected><BuyCreditsModule /></Protected>} /> */}
      <Route path="/welcome" element={<ProtectedBack><Main /></ProtectedBack>} />
      {/* Institucional */}
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/service-unavailable" element={<MaintenancePage />} />
      <Route path="*" element={<Error />} />
      {/* Mobile Routes */}
      {/* <Route path="/buyCredits" element={isMobile  ? <Protected><BuyCredits /></Protected> : <Error />} /> */}
      <Route path="/exports" element={<Protected><LeadHistoryBulk /></Protected>} />
    </Routes>
  );
};
export default MyRouter;
