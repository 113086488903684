import React, { useEffect } from 'react';
import styles from './style.module.scss';
import DomainHelper from 'helpers/DomainHelper';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface MaintenancePageProps {
  expectedReturnTime?: string;
  contactEmail?: string;
}
 
const MaintenancePage: React.FC<MaintenancePageProps> = ({
  expectedReturnTime = 'soon!',
  contactEmail = 'support@identitymatrix.ai'
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkServerStatus = async () => {
      const baseUrl = DomainHelper.getAPIMain();

      try {
        const response = await axios.get(`${baseUrl}testing/run`, { 
          timeout: 5000 // Aumenta o timeout
        });

        // If we receive any response (even 401 Unauthorized), it means the server is online
        console.log('Server is online, redirecting to dashboard...');
        navigate('/dashboard', { replace: true });
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          // If we receive a response, even with an error, the server is online
          console.log('Server is online (responded with error), redirecting...');
          navigate('/dashboard', { replace: true });
        } else {
          console.error('Server is still offline:', error);
          // Server is still offline, doing nothing
        }
      }
    };

    console.log('Starting server status check');
    checkServerStatus();
    const interval = setInterval(checkServerStatus, 10000);

    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <div className={styles['maintenance-container']}>
      <div className={styles['maintenance-card']}>
        <div className={styles['logo']}>
          <img src="/logo.png" alt="IdentityMatrix Logo" />
        </div>
        <h1>We're rebooting the matrix.</h1>
        <img className={styles['icon']} src="/maintenance.png" alt="Maintenance Icon" />
        <p>
          <a href="https://identitymatrix.ai/" target="_blank" rel="noopener noreferrer">
            Identity Matrix
          </a> will be back online <span className={styles['highlight']}>{expectedReturnTime}</span>
        </p>
        <div className={styles['divider']}></div>
        <div className={styles['contact']}>
          <p>Need assistance?</p>
          <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
        </div>
      </div>
    </div>
  );
};

export default MaintenancePage;