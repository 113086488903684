import React from "react";
import SkeletonLoading from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const Skeleton: React.FC<{
  width?: string | number;
  height?: string | number;
}> = ({ 
  width = "auto",
  height = "auto",
}) => {
  return (
    <SkeletonLoading
      width={width} height={height}
      duration={3.5}
      style={{ 
        opacity: 0.33,
        borderRadius: 8
      }}
      baseColor='var(--color-paper-400)' highlightColor='var(--color-paper-300)' 
    />
  );
};
export default Skeleton;