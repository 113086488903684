import { ISvg } from "../../types/components/svgIcons.type";
import { ReactComponent as VisaCard } from "./visaCard.svg";
import { ReactComponent as MasterCard } from "./masterCard.svg";
import { ReactComponent as Amex } from "./amex.svg";
import { ReactComponent as Discover } from "./discover.svg";

const Svg: ISvg = {
  visa: VisaCard,
  mastercard: MasterCard,
  amex: Amex,
  discover: Discover,
};

export default Svg;
