import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { useForm } from "react-hook-form";
import Button from "../../components/atoms/Button/Button";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { toast } from "react-toastify";
import { updateUserPassword } from "store/slices/user.slice";
import { completePasswordReset, resetPasswordResetState, verifyResetLink } from "store/slices/auth.slice";
import Loading from "components/atoms/Loading/Loading";
import { Icon, InputField } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import theme from "../../assets/themes";

const UpdatePasswordPage: React.FC = () => {
  const [isResetMode, setIsResetMode] = useState(false);
  const [resetParams, setResetParams] = useState<{ userId: string, id: string } | null>(null);
  const [pageReady, setPageReady] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const userUpdateLoading = useAppSelector((state) => state.user.updateUser.isLoading);
  const resetLoading = useAppSelector((state) => state.auth?.completePasswordReset?.isLoading || false);
  const resetSuccess = useAppSelector((state) => state.auth?.completePasswordReset?.isSuccess || false);
  const resetError = useAppSelector((state) => state.auth?.completePasswordReset?.isError || null);
  const resetLinkLoading = useAppSelector((state) => state.auth?.resetLink?.isLoading || false);
  const resetLinkValid = useAppSelector((state) => state.auth?.resetLink?.isSuccess || false);
  const resetLinkError = useAppSelector((state) => state.auth?.resetLink?.isError || null);
  const isLoading = isResetMode ? resetLoading : userUpdateLoading;
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userId = params.get('userId');
    const id = params.get('id');
    if (userId && id) {
      setIsResetMode(true);
      setResetParams({ userId, id });
      dispatch(verifyResetLink({ userId, token: id }));
    } else {
      if (!isResetMode) {
        setPageReady(true);
      }
    }
  }, [location, dispatch]);
  useEffect(() => {
    document.title = isResetMode ? "Reset Password" : "Update Password";
    window.analytics?.page(isResetMode ? "Reset Password" : "Update Password");
    
    const container = document.getElementById("bg-container");
    if (container) {
      container.style.backgroundImage = `url(${theme["bgImage"]})`;
    }
    return () => {
      document.title = theme.serviceName || ""; 
    };
  }, [isResetMode]);
  useEffect(() => {
    if (isResetMode && resetSuccess) {
      toast.success("Password reset successful! You can now log in with your new password.");
      dispatch(resetPasswordResetState());
      navigate("/signin");
    }
    if (isResetMode && resetError) {
      toast.error(resetError || "Failed to reset password. The link may be expired or invalid.");
      dispatch(resetPasswordResetState());
    }
  }, [resetSuccess, resetError, isResetMode, dispatch, navigate]);
  useEffect(() => {
    if (isResetMode && !resetLinkLoading) {
      setPageReady(true);
    }
  }, [isResetMode, resetLinkLoading]);
  const save = (data: any) => {
    if(!data.newPassword) {
      toast.error("Password is required");
      return;
    }
    if (isResetMode) {
      if (!resetParams) return;
      if (data.newPassword !== data.confirmPassword) {
        toast.error("Passwords do not match");
        return;
      }
      dispatch(completePasswordReset({
        userId: resetParams.userId,
        id: resetParams.id,
        newPassword: data.newPassword
      }));
    } else {
      dispatch(updateUserPassword(data));
    }
  };
  const renderForm = () => (
    <form
      onSubmit={handleSubmit(save)}
      className={styles.main_container_form}
    >
     {isResetMode && resetLinkValid ? (
        <>
          <InputField
            iconName="lock" type="password" autoFocus name="newPassword" errors={errors} register={register}
            validationSchema={{
              required: "Password is required",
              pattern: {
                value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/,
                message: "Minimum eight characters, at least one upper case English letter, one lower case English letter, one number and one special character",
              }}}
            placeholder="Enter new password"
          />
          <InputField
            iconName="lock" type="password" name="confirmPassword" errors={errors} register={register}
            validationSchema={{
              required: "Confirm password is required",
              validate: (val: string) => {
                if (watch('newPassword') !== val) {
                  return "Your passwords do not match";
                }}}}
            placeholder="Confirm new password"
          />
          <Button 
            action="submit" size="lg" className="w-full" iconName="lock" isProcessing={isLoading} isProcessingTitle="Setting new password" type="secondary">
            Set New Password
          </Button>
        </>
      ) : !isResetMode && (
        <>
          <InputField
            iconName="lock" type="password" autoFocus name="newPassword" errors={errors} register={register}
            validationSchema={{
              required: "Password is required",
              pattern: {
                value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/,
                message: "Minimum eight characters, at least one upper case English letter, one lower case English letter, one number and one special character",
              }}}
            placeholder="Enter new password"
          />
          <Button 
            action="submit" size="lg" className="w-full" iconName="lock" isProcessing={isLoading} isProcessingTitle="Updating password" type="secondary">
            Update Password
          </Button>
        </>
      )}
    </form>
  );
  if (!pageReady || isLoading) {
    return (
      <div className={styles.main} id="bg-container">
        <div className={styles.loading_overlay}>
          <Loading />
        </div>
      </div>
    );}
  return (
    <div className={styles.main} id="bg-container">
      <div className={styles.main_container}>
        <img 
          src={theme["logo"]} 
          alt="logo" 
          className={styles.main_container_logo} 
          onClick={() => navigate("/signin")} 
        />
        <div className={`${styles.container_wrapper} bg-paper-500`}>
          <div className={styles.icon_container}>
            <Icon name="lock" size={70} />
          </div>
          <h1 className={styles.page_title}>
            {isResetMode && resetLinkValid ? "Create New Password" : !isResetMode ? "Update Password" : ""}
          </h1>
          {isResetMode && !resetLinkValid ? (
            <div className={styles.error_container}>
              <h2 className={styles.error_title}>Expired or Invalid Link</h2>
              <p className={styles.error_message}>
                The password reset link you are trying to use has expired or is invalid.
                Please request a new password reset link.
              </p>
              <Button 
                action="button" size="lg" className="w-full" iconName="email" type="secondary" onClick={() => navigate("/forgot-password")}>
                Request New Link
              </Button>
            </div>
          ) : (
            <>
              <p className={styles.description}>
                {isResetMode 
                  ? "Please enter and confirm your new password." 
                  : "Enter your new password to update your account."}
              </p>
              {renderForm()}
              {isResetMode && (
                <div className={`link ${styles.back_link}`} onClick={() => navigate("/signin")}>
                  Go Back
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );};

export default UpdatePasswordPage;