import { Icon } from 'components';
import React from 'react';
interface ProgressBarProps {
    used: number;
    available: number;
    label: string;
    color?: string;
    limitColor?: string;
    iconName?: string
    removeValueLabel?: boolean
    size?: 'normal' | 'small'
}
const ProgressBar: React.FC<ProgressBarProps> = ({
    used,
    available,
    label,
    color = 'var(--color-primary-500)',
    limitColor = 'red',
    iconName,
    size = 'normal',
    removeValueLabel
}) => {
    const calculatePercentage = (used: number, available: number) => {
        return available > 0 ? (used / available) * 100 : 0;
    };
    const usedSanitized = used >= available ? available : used;
    const sizeClass = {
        normal: 'h-2 bg-[var(--color-paper-300)] ',
        small: 'h-[2px] bg-[#323135]'
    }
    return (
        <div className="flex flex-col gap-2 w-full">
            <div className="flex justify-between items-center w-full">
              <div className='flex flex-row'>
                {iconName && <Icon name={iconName} className='mr-2' />}
                <p className="text-sm font-medium">{label}</p>
              </div>
                {!removeValueLabel && <p className="text-xs text-right">{usedSanitized}/{available}</p>}
            </div>
            <div className={`w-full rounded-lg ${sizeClass[size]} overflow-hidden`}>
                <div
                    className={`h-2 rounded-lg transition-all duration-300`}
                    style={{
                        width: `${calculatePercentage(used, available)}%`,
                        backgroundColor: color
                    }}
                />
            </div>
        </div>
    );
};

export default ProgressBar;