import React, { Dispatch, SetStateAction, useState } from "react";
import { EUserRoles, EUserRolesDTO, ICompanyUser } from "types/user/user.type";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { toast } from "react-toastify";
import {
  addCreditsToUser,
  handleAutoRecharge,
  resetCompanyUserCredits,
} from "store/slices/company.slice";
import Button from "components/atoms/Button/Button";
import InputField from "components/atoms/InputField/InputField";
import Tooltip from "components/atoms/Tooltip/Tooltip";
import Popover from "components/atoms/Popover/Popover";
import Icon from "components/atoms/Icon/Icon";
import Loading from "components/atoms/Loading/Loading";
import BusinessRulesHelper from "helpers/BusinessRulesHelper";

const CompanyTeamItem: React.FC<{
  user: ICompanyUser;
  addCreditsLoading: boolean;
  resetCreditsLoading: boolean;
  autoRechargeLoading: boolean;
  setCurrentUser: Dispatch<SetStateAction<string>>;
  handleTeamUserCreditsHistory: (userId: string) => void;
  handleRemoveTeamMember: (userId: string) => void;
}> = ({ 
  user, addCreditsLoading, resetCreditsLoading, 
  setCurrentUser, 
  handleTeamUserCreditsHistory,
  handleRemoveTeamMember,
  autoRechargeLoading
}) => {
  
  const { creditBalance, id: companyId } = useAppSelector((state) => state.company.company);
  const [creditCount, setCreditCount] = useState<number>(0);
  const [autoRechargeAmount, setAutoRechargeAmount] = useState<number>(user.autoRechargeAmount);

  const [anchorAdd, setAnchorAdd]= useState<null | HTMLElement>(null);
  const openAdd = Boolean(anchorAdd);

  const [anchorReset, setAnchorReset]= useState<null | HTMLElement>(null);
  const openReset = Boolean(anchorReset);

  const [anchorRecharge, setAnchorRecharge]= useState<null | HTMLElement>(null);
  const openRecharge = Boolean(anchorRecharge);

  const dispatch = useAppDispatch();

  const { data: userLogged } = useAppSelector((state) => state.user);
  const isAdmin = userLogged?.userRole === EUserRoles.ADMIN

  const handleAddCredits = () => {
    setCurrentUser(user.id);
    if (Number.isNaN(creditCount) || creditCount < 1 || !creditCount) {
      return toast.error("Please enter an amount");
    } else if (creditCount > creditBalance) {
      return toast.error("Company has not enought credits");
    }
    dispatch( addCreditsToUser({ nOfCredits: creditCount, userId: user.id }) );
  };
  const handleSetAutoRechargeAmount = (e: React.FormEvent<HTMLInputElement>) => {
    if (Number.isNaN(+e.currentTarget.value)) return toast.error("Please enter an amount", {toastId: "autoRechargeAmount"})
    setAutoRechargeAmount(+e.currentTarget.value);
  };
  const handleSetAutoRechargeSubmit = (action: 'save' | 'reset') => {
    if (autoRechargeAmount < 1) return toast.warning("Please enter an amount greater than 0", {toastId: "autoRechargeAmount"});
    setCurrentUser(user.id);
    dispatch(
      handleAutoRecharge({
        amount: autoRechargeAmount,
        isAutoRecharge: action === 'save' ? true : false,
        userId: user.id,
      })
    );
  }
  const hasUnlimitedCredits = BusinessRulesHelper.hasUnlimitedCredits(user.email);

  return <tr className="flex flex-col sm:table-row border-t-2 border-t-paper-600 pb-2 md:pb-0 sm:border-none gap-1 md:gap-0">
      <td>{user.email} <small className="md:hidden  text-slate-400 h-[20px]"><br/>Email</small></td>
      <td className=" sm:table-cell"><div className="flex flex-col">
          <h1>{`${user.firstName} ${user.lastName}`}</h1>
          <small className="text-slate-400">{EUserRolesDTO[user.userRole]}</small>
      </div></td>
      {isAdmin && <td className=" sm:table-cell"><div className="flex items-center gap-2 justify-center">
        <Icon name="credits" size={20} />
        <div className="truncate">
          {!hasUnlimitedCredits ? user.creditBalance : "Unlimited Credits"}
        </div>
      </div></td>}
      {!hasUnlimitedCredits && isAdmin && user.userRole !== EUserRoles.ADMIN &&  <td className=" sm:table-cell"><div className="flex justify-center gap-2 sm:gap-3">
        <Tooltip content="Add Credits">
          <Button iconName="plus" size="xs" iconSize={18} onClick={(e: React.FormEvent<HTMLButtonElement>) => setAnchorAdd(e.currentTarget)} />
        </Tooltip>
        <Popover isOpen={openAdd} anchor={anchorAdd} setAnchor={setAnchorAdd}><div className="gap-3 flex flex-col">
          <b className="text-sm">Add Credits to Member</b>
          <div className="flex items-center gap-3 w-full">
            <InputField
              onChange={(e: React.FormEvent<HTMLInputElement>) =>setCreditCount(+e.currentTarget.value)}
              disabled={addCreditsLoading}
              iconName="credits" autoFocus
              size="small" width="auto" placeholder="Enter the amount"
            />
            <Button
              onClick={handleAddCredits}
              disabled={addCreditsLoading} size="sm"
            >{addCreditsLoading ? "Processing..." : "Confirm"}</Button>
        </div></div></Popover>
        <Tooltip content="Reset Credits">
          <Button iconName="refresh" size="xs" iconSize={17}
            onClick={(e: React.FormEvent<HTMLButtonElement>) => setAnchorReset(e.currentTarget)} />
        </Tooltip>
        <Popover isOpen={openReset} anchor={anchorReset} setAnchor={setAnchorReset}><div className="flex items-center gap-3 w-full">
          <b className="text-sm">Reset Credits</b>
          <Button
            disabled={resetCreditsLoading} size="sm"
            onClick={() => {
              setCurrentUser(user.id)
              dispatch( resetCompanyUserCredits({userId: user.id, companyId}) )
            }}
          >{!resetCreditsLoading ? 'Confirm' : 'Processing...'}</Button>
        </div></Popover>
        <Tooltip content="Auto Recharge">
          <Button iconName="credits" size="xs" iconSize={17}
            onClick={(e: React.FormEvent<HTMLButtonElement>) => setAnchorRecharge(e.currentTarget)} />
        </Tooltip>
        <Popover isOpen={openRecharge} anchor={anchorRecharge} setAnchor={setAnchorRecharge}><div className="gap-4 flex flex-col">
          <div className="flex items-center gap-3">
            <b className="text-sm">Auto Recharge</b>
            <Tooltip content="The recharge is made when the user spends all their credits">
              <Icon name="info" size={16} className="text-gray-300" />
            </Tooltip>
          </div>
          <div className="flex items-center gap-3">
            <InputField
              width="auto"
              iconName="credits" autoFocus
              size="small"
              type="text"
              placeholder="Enter the amount"
              value={autoRechargeAmount}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>handleSetAutoRechargeAmount(e)}
            />
          </div>
          {!autoRechargeLoading 
            ? <div className="flex items-center gap-3">
                <Button
                  onClick={() => {handleSetAutoRechargeSubmit('save')}}
                  iconName="save" size="sm"
                >Save</Button>
                <Button
                  onClick={() => {handleSetAutoRechargeSubmit('reset')}}
                  iconName="refresh" size="sm"
                >Reset</Button>
            </div>
            : <Loading height="auto" />
          }
        </div></Popover>
        <Tooltip content="Credits History">
          <Button iconName="document" size="xs" iconSize={18} onClick={() => {handleTeamUserCreditsHistory(user.id)}} />
        </Tooltip>
        <Tooltip content="Remove Team Member">
          <Button iconName="trash" size="xs" iconSize={18} onClick={() => {handleRemoveTeamMember(user.id)}} />
        </Tooltip>
      </div></td>}
  </tr>
};

export default CompanyTeamItem;
