import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import style from "./style.module.scss";
import { Icon } from "components";
import theme from "../../assets/themes";
import constants from '../../utils/constants';

const CheckYourEmail: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const container = document.getElementById("bg-container");
    if (!container) return;
    container.style.backgroundImage = `url(${theme["bgImage"]})`;
  }, []);
  return <div className={`${style.container} sm:h-screen h-auto`} id="bg-container">
    <div className={style.logo}>
      <img className={style.img} src={theme["logo"]} alt="logo" onClick={() => navigate("/welcome")} />
    </div>
    <div className={`${style.container_wrapper} bg-paper-500`}>
      <div className="flex justify-center items-center bg-primary-500 w-28 h-28 rounded-full text-white-500 mb-7"><Icon name="user-check" size={70} /></div>
      <div className={style.wrapper_up}>
        <h1 className="text-2xl text-center font-bold mb-5">🚀 Welcome on board! Your account has been created!</h1>
        <p className="text-center mb-5 text-base text-gray-300">
          To complete your registration, please confirm your email address. We've sent a confirmation email to your inbox. Open it and click the link to verify your email and finish setting up your account.
        </p>
        <p className="text-center text-base text-gray-300">
          For any help, please contact our team by the email{" "}
          <a className="link text-primary-500 underline" href={`mailto:${constants.COMPANY_1_EMAIL_SUPPORT}`}>{constants.COMPANY_1_EMAIL_SUPPORT}</a>
          . We are here to help you enjoy the full range of services we offer.
        </p>
      </div>
    </div>
  </div>
};
export default CheckYourEmail
