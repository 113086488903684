import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { convertQueryString } from "utils/workWithData";
import { CouponDTO } from "utils/dtos/couponDTO";
import EntryFormatter from "utils/EntryFormatter";
import DomainHelper from "helpers/DomainHelper";

const baseUrl = DomainHelper.getAPIMain()
interface ICoupon {
  isLoading: boolean,
  isSuccess: boolean,
  data: CouponDTO | null,
  errorMessage: string,
}
const initialState: ICoupon = {
  isLoading: false,
  isSuccess: false,
  data: null,
  errorMessage: "Something went wrong",
}
export const getCouponItem = createAsyncThunk("coupon/getCouponItem", async (queryParams: any, thunkAPI) => {
  try {
    const query = convertQueryString(queryParams);
    const url = `${baseUrl}coupon/fetch/item?${query}`;
    const response = await axios.get(url, { withCredentials: true });
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
}})
export const couponSlice = createSlice({
  name: "coupon", initialState, 
  reducers: {
    resetGetCouponItemSuccess: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = "";
    },
    resetGetCouponItemData: (state) => { state.data = null; }
  },
  extraReducers: (builder) => {
    builder.addCase(getCouponItem.pending, (state) => {
      state.isLoading = true
    });
    builder.addCase(getCouponItem.fulfilled, (state, action) => {
      EntryFormatter.sleep(1200);
      state.isLoading = false;
      state.isSuccess = true;
      state.data = action.payload.data || null;
    });
    builder.addCase(getCouponItem.rejected, (state, action: any) => {
      EntryFormatter.sleep(1200);
      state.isLoading = false;
      state.isSuccess = true;
      state.data = null;
      state.errorMessage = action.payload?.message || "An error has occurred";
    });
  }
});
export const { resetGetCouponItemSuccess, resetGetCouponItemData } = couponSlice.actions;