import { Card } from "components/atoms/Card"
import { CardItem } from "components/atoms/CardItem"
import { LeadProfileDTO } from "utils/dtos/leadDTO"
import EntryFormatter from "utils/EntryFormatter"

export const CompanyInfo: React.FC<{ lead: LeadProfileDTO }> = ({ lead }) => {
    return <Card title="Company Info">
        <CardItem prefix="Job Title" value={lead.linkedInTitle} borderBottom />
        <CardItem prefix="Name" value={lead.organization?.name} />
        <CardItem prefix="Industry" value={lead.organization?.industry} />
        <CardItem prefix="Website" value={lead?.organization?.websiteUrl} isLink link={EntryFormatter.formatLink(lead?.organization?.websiteUrl || '-')} />
        <CardItem prefix="Linkedin" value={lead?.organization?.linkedinUrl} isLink link={EntryFormatter.formatLink(lead?.organization?.linkedinUrl || '-')} borderBottom />
        <CardItem prefix="Address" value={lead.organization?.address?.street} />
        <CardItem prefix="City" value={lead.organization?.address?.city} />
        <CardItem prefix="State" value={lead.organization?.address?.state} />
    </Card>
}