import React, { CSSProperties, ReactNode, useEffect, useState } from "react";
import theme from '../../../assets/themes'
const ThemeProvider: React.FC<{ children: ReactNode, className: string }> = ({ children, className }) => {
  // const showDebugger = EnvHelper().isDebugTheme()
  // const showDebugger = false
  // const [actualTheme, setActualTheme] = useState<'default' | 'qualigence'>('default')
  // const [isOpen, setIsOpen] = useState(false)
  // const themeSchemas = {
  //   default: qualigenceTheme,
  //   qualigence: defaultTheme
  // }
  // const handleSelectTheme = (themeName: 'default' | 'qualigence') => {
  //   document.body.style.setProperty("--bg-color", themeSchemas[actualTheme]["--bg-color"], "important")
  //   document.body.setAttribute("data-theme", themeName)
  //   setActualTheme(themeName)
  // }
  // useEffect(() => {
  //   if (!showDebugger) {
  //     document.body.style.setProperty("--bg-color", theme["--bg-color"], "important")
  //     // document.body.style.setProperty("--scrollbar-background", theme["--scrollbar-background"], "important")
  //     // document.body.style.setProperty("--scrollbar-hover-color", theme["--scrollbar-hover-color"], "important")
  //   }
  // }, [])
  useEffect(() => {
    document.body.style.setProperty("--bg-color", theme["--bg-color"], "important")
    // document.body.style.setProperty("--scrollbar-background", theme["--scrollbar-background"], "important")
    // document.body.style.setProperty("--scrollbar-hover-color", theme["--scrollbar-hover-color"], "important")
  }, [])
   return <div className={className} style={theme as CSSProperties}>{children}</div>;
  //  return <div className={className} style={(showDebugger ? themeSchemas : theme) as CSSProperties}>
  //    {showDebugger && <div className={`p-2 rounded-full bg-gray-900 fixed bottom-1 justify-center w-fit flex gap-4 transition-all duration-200 pl-6 ${isOpen ? '-left-0' : '-left-[160px]'} items-center`}>
  //      <button
  //        onClick={() => handleSelectTheme('default')}
  //        className={`text-white-500 flex items-center gap-2 p-2 rounded-full min-w-8 min-h-8 bg-gray-500  ${actualTheme === 'default' ? 'pointer-events-none ring-primary-500 ring-2' : ''}`}>
  //      </button>
  //      <button
  //        onClick={() => handleSelectTheme('qualigence')}
  //        className={`text-white-500 flex items-center gap-2 p-2 rounded-full min-w-8 min-h-8 bg-white-500 ${actualTheme === 'qualigence' ? ' pointer-events-none ring-primary-500 ring-2' : ''}`}>
  //      </button>
  //      <button className=" text-white-500 flex items-center gap-2 p-2 rounded-full hover:bg-gray-700 w-7 h-7  ring-primary-500 ring-2 bg-primary-500" onClick={() => {fakeTracking({id: '46569877-2a5a-406a-a585-2c7987c44f17'})}}>
  //         <Icon name="network" size={12} />
  //      </button>
  //      <button className=" text-white-500 flex items-center gap-2 p-2 rounded-full hover:bg-gray-700 w-7 h-7"  onClick={() => {setIsOpen(e => !e)}}>
  //        {isOpen ? <Icon name="chevron-left" size={12} /> : <Icon name="chevron-right" size={12} />}
  //      </button>
  //    </div>}
  //    {children}
  //  </div>;
};
export default ThemeProvider;