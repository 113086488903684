import React from 'react';
import Icon from '../Icon/Icon';
import Skeleton from '../layout/Skeleton/Skeleton';
import LeadScore from '../LeadScore/LeadScore';

interface StatsCardProps {
    title: string;
    value: string | number | null;
    subtitle?: string
    iconName?: string;
    color?: string;
    isLoading?: boolean
    threshold?: number
}
const StatsCard: React.FC<StatsCardProps> = ({ title, value, iconName, subtitle, color, isLoading, threshold }) => {
    if (isLoading) {
        return <div className="flex flex-1 h-full flex-col lato-bold gap-2 bg-gray-900 p-5 max-h-[120px] rounded-[12px]">
            <Skeleton width={124} height={20} />
            <div className={`flex lato flex-row items-center gap-2 ${color ?? 'text-white-500'}`}>
                <Skeleton width={24} height={24} />
                <Skeleton width={184} height={24} />
            </div>
            {subtitle ? <Skeleton width={104} height={16} /> : null}
        </div>
    }
    return (
        <div className="flex flex-1 h-full w-full flex-col lato-bold gap-2 bg-gray-900 p-5 rounded-[12px]">
            <h1 className="lato font-semibold text-sm leading-5 truncate ">{title}</h1>
            <div className={`flex lato flex-row items-center gap-2 ${color ?? 'text-white-500'}`}>
                {iconName && <Icon size={24} name={iconName} />}
                {threshold ? <div><LeadScore removeBg={true} value={Number(value)} threshold={threshold} loading={false} /></div> :
                    <p className={`lato font-semibold leading-7 truncate  ${color ?? 'text-white-500'}`} style={{ fontSize: '26px' }}>{value}</p>}
            </div>
            {subtitle ? <small className='text-xs lato font-normal lato leading-4 text-gray-400'>{subtitle}</small> : null}
        </div>
    );
};

export default StatsCard;
