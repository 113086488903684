import Icon from "../Icon/Icon"
import { useTabs } from "./hook"
export const Tab = ({ handleChangeTab }: { handleChangeTab?: (active: string) => void }) => {
    const { active, listItems, handleSetActive } = useTabs()
    return <div className="border-2 transition border-gray-900 rounded-lg  min-h-[40px] max-h-[40px] overflow-hidden flex flex-row ">
        {
            listItems.map(item => {
                const isActive = item.name === active
                const activeClass = `w-full md:w-fit  text-sm leading-5 bg-gray-900 font-lato px-3 transition-all flex items-center gap-2 duration-300 hover:bg-primary-500 text-white-500 ${isActive ? 'bg-primary-500' : ''}`
                return <button type="button" key={item.name} className={activeClass} onClick={() => {
                    handleSetActive(item.name)
                    handleChangeTab && handleChangeTab(item.name)
                }}><Icon name={item.icon} />{item.label || item.name}</button>
            })
        }
    </div>
}