import { Filter } from "components/atoms/Filter";
import Icon from "components/atoms/Icon/Icon";
import { Tab } from "components/atoms/Tab/Tab";
import { TabContent, TabProvider } from "components/atoms/Tab/hook";
import Container from "components/atoms/layout/Container/Container";
import DateRangePicker from "components/molecules/DateRangePicker/DateRangePicker";
import { useEffect, useRef, } from "react";
import { useAppDispatch } from "store/hooks";
import { setCurrentPage } from "store/slices/ui.slice";
import constants from "utils/constants";
import "./../SearchMain/index.scss";
import { useAnalytics } from "./model/useAnalytics";
import AnalyticClientStats from "./templates/AnalyticClientStats";
import AnalyticsBody from "./templates/AnalyticsBody";

const AnalyticsModule = () => {
  const dispatch = useAppDispatch();
  const {
    filters, tabs, visitorsMetrics, visitorsICP, filterApplyed, visitorGraph, threshold, 
    panelVolumeVisitors, panelConvertingVisitors, panelQualityVolumePerson, panelQualityVolume, panelDurationICP, panelConvertionICP,
    filterDispatch, setActivePage
  } = useAnalytics()
  const scrollableRef = useRef<HTMLDivElement>(null);
  const scrollToEnd = () => { if (scrollableRef.current) { scrollableRef.current.scrollTo({ left: scrollableRef.current.scrollWidth, behavior: "smooth" }); } };
  useEffect(() => { window.analytics.page("Analytics"); dispatch(setCurrentPage(constants.ANALYTICS_KEYWORD)) }, [])
  return <Container>
    <TabProvider defaultActive="Overview" listItems={tabs} >
      <div className="flex flex-col md:flex-row gap-6">
        <div className="w-full md:w-fit items-center flex  text-white-500 gap-2">
          <Icon name="analytics" size={30} />
          <h1 className="font-bold capitalize text-2xl">Analytics</h1>
        </div>
        <Tab handleChangeTab={value => setActivePage(value)} />
        <div className="flex items-center gap-4 flex-row h-fit md:w-fit w-full ml-auto">
          <div className="w-full justify-center md:max-w-[550px] min-w-[300px]">
            <DateRangePicker state={[filterApplyed.startDate, filterApplyed.endDate]} setValue={(value) => filterDispatch({ type: 'update-date', newDate: value as string[] })} />
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-6">
        <Filter filters={filters} handleFilter={filters => filterDispatch({ type: 'update-filters', filters })} />
        <TabContent>
          {({ active }: any) => {
            if (active === 'Overview') return <div className="w-full h-[76vh]">
              <AnalyticClientStats panelQualityVolumePerson={panelQualityVolumePerson} panelQualityVolume={panelQualityVolume} panelDurationICP={panelDurationICP}
                panelConvertionICP={panelConvertionICP} icp={visitorsICP} panelVolumeVisitors={panelVolumeVisitors} panelConvertingVisitors={panelConvertingVisitors}
                graph={visitorGraph} metrics={visitorsMetrics} threshold={threshold || 0} />
            </div>
            //TODO Remover isso quando for attribuition pra produção
            // if (active === 'Attribution') return <div className="p-5 bg-gray-900 w-full h-[76vh] overflow-x-auto rounded-lg" ref={scrollableRef}>
            //   <AnalyticClientAttribuition scrollToEnd={scrollToEnd} />
            // </div>
            return <div className="p-5 bg-gray-900 h-[76vh] w-full overflow-x-auto rounded-lg" ref={scrollableRef}>
              <AnalyticsBody scrollToEnd={scrollToEnd} />
            </div>
          }}
        </TabContent>
      </div>
    </TabProvider >
  </Container >
};
export default AnalyticsModule;