import constants from "./constants";

const pageDetails = {
    pixelList: {
      title: constants.PIXEL_KEYWORD,
      description: ''
    },
    databaseList: {
      title: constants.LEADSEARCH_KEYWORD,
      description: ''
    },
    pixelConversion: {
      title: 'Conversions',
      description: ''
    }
}

export default pageDetails;