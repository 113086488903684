import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { deleteTeamMember, getCompany, resetDeleteTeamMember } from "store/slices/company.slice";
import Button from "components/atoms/Button/Button";
import Heading from "components/atoms/layout/Heading/Heading";

const CompanyRemoveTeamMember: React.FC<{
  closePopup: () => void;
  memberId: string;
}> = ({
  closePopup,
  memberId
}) => {
  const { errorMessage, isLoading, isSuccess, successMessage } = useAppSelector((state) => state.company.deleteTeamMember);

  const dispatch = useAppDispatch();

  const handleRemoveMember: () => void = () => {
    dispatch(deleteTeamMember(memberId)); 
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(successMessage);
      dispatch(getCompany());
      closePopup();
    } else if (errorMessage) {
      toast.error(String(errorMessage), { toastId: "deleteMemberError" });
    }
    dispatch(resetDeleteTeamMember());
  }, [successMessage, errorMessage]);

  return (
    <div className="flex flex-col w-full gap-6">
      <Heading icon="trash" title="Remove Team Member" description="Remove a member from your team." />
      <p className="text-left md:text-lg text-white-300">Are you sure you want to remove this team member? <br/>This action is irreversible and will permanently revoke their access to your team.</p>
      <div className="flex flex-row w-full justify-end gap-3">
      <Button action="submit" iconName="close" type="outline" onClick={closePopup} disabled={isLoading} >
        Cancel
      </Button>
      <Button action="submit" iconName="trash" onClick={handleRemoveMember} isProcessing={isLoading} >
        Confirm
      </Button>
      </div>
    </div>
  );
};
export default CompanyRemoveTeamMember;