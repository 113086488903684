import { useEffect } from "react";
import "./App.scss";
import "react-datepicker/dist/react-datepicker.css";
import MyRouter from "./routes/Router";
import ThemeProvider from "components/atoms/Theme/Theme";
import theme from "./assets/themes"
import { AppContextProvider } from "utils/context/AppContext";

function App() {
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
        const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
    document.title = theme["serviceName"] || ""
  }, []);
  return (
    <AppContextProvider>
      <ThemeProvider className="App">
        <MyRouter />
      </ThemeProvider>
    </AppContextProvider>
  );
}
export default App;