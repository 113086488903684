import Icon from "components/atoms/Icon/Icon";
import Container from "components/atoms/layout/Container/Container";
import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector,  } from "store/hooks";
import Loading from "components/atoms/Loading/Loading";
import { setCurrentPage } from "store/slices/ui.slice";
import { paragon } from "@useparagon/connect";
import { getParagonAuthToken } from "store/slices/crm.slice";
import EntryFormatter from "utils/EntryFormatter";
import Button from "components/atoms/Button/Button";
import constants from "utils/constants";
import { getBillingPortalUrl } from "store/slices/card.slice";
import { toast } from "react-toastify";
import CrmContainer from "components/molecules/CrmContainer/CrmContainer";
import { SegmentEventENUM } from "utils/enums/segmentENUM";
import { cleanObject } from "utils/object";
const IntegrationsModule: FC = () => {
  const dispatch = useAppDispatch();
  const {data: user} = useAppSelector((state) => state.user);
  const {data: authToken, isSuccess: authIsSuccess, isLoading: authIsLoading} = useAppSelector((state) => state.crm.paragonAuth);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [userParagon, setUserParagon] = useState<any>();
  const { billingPortalIsLoading } = useAppSelector((state) => state.cards);
  const sendTrack = (integrationName: string) => {
    const eventEnum = SegmentEventENUM.INTEGRATION_EXPLORED;
    const integrationExploredPayload = {
        crmClicked: integrationName,
        firstname: user.firstName,
        lastname: user.lastName,
        email: user.email,
    }
    const cleanedFilters = cleanObject(integrationExploredPayload);
    window.analytics.track(eventEnum, cleanedFilters)
  }
  useEffect(() => {
    window.analytics.page("Integrations")
    dispatch(setCurrentPage("integrations"));
    if (EntryFormatter.isObjectEmpty(user) || authIsLoading) return;
    dispatch(getParagonAuthToken())
  }, [user]);
  useEffect(() => {
    if (!authIsSuccess) return
    paragon.authenticate(authToken.projectId || '',authToken.authToken || '')
      .then(() => {setUserParagon(paragon.getUser())})
      .catch((error) => {console.log(error);})
      .finally(() => { setIsAuthenticating(false) })
  }, [authIsSuccess]);
  if (isAuthenticating) return <Loading />
  return <Container>
    <div className="flex sm:gap-6"><div className="items-center flex gap-3 text-white-500">
      <Icon name="layers" size={35} />
      <div className="flex flex-col gap-1">
        <h3 className="sm:text-xl text-2xl w-min font-bold">Integrations</h3>
        <p className="text-gray-400 sm:text-base text-sm">Create your own custom integration with any of the following CRMs.</p>
    </div></div></div>
    <div className="grid grid-cols-1 sm:grid-cols-3 p-5 gap-6 bg-gray-900 rounded-lg">
      {!authToken.isClientAuthorized && <div className={`border border-solid border-gray-700 rounded-xl p-4 bg-paper-400 flex flex-col items-start gap-3`}>
        <h1 className="font-bold text-lg">Enhance Your Experience</h1>
        <p className="text-[15px] leading-[1.4rem] text-gray-300">Your current plan doesn't include access to most of the integrations. To unlock it and enjoy even more benefits, consider upgrading your subscription. More info, contact our team by the email <a className="link" href={`mailto:${constants.COMPANY_1_EMAIL_SUPPORT}`}>{constants.COMPANY_1_EMAIL_SUPPORT}</a>.</p>
        <Button onClick={async () => {
          const billingData = await dispatch( getBillingPortalUrl() )
          const url = billingData.payload.data;
          if (!url) {
            toast.error("Something went wrong. Please try again later.")
            return
          }
          window.open(url, "_blank")
        }} iconName="membership">
          {billingPortalIsLoading ? "Redirecting..." : "Upgrade Now"}</Button>
      </div>}
      {paragon && paragon.getIntegrationMetadata().length > 0
        ? <>
          <a onClick={() => sendTrack("Zapier")} href="https://zapier.com/apps/identity-matrix/integrations" target="_blank" rel="noreferrer"
            className={`border border-solid border-gray-700 rounded-xl p-4 bg-paper-400 flex flex-col items-start gap-3 cursor-pointer hover:bg-gray-700 transition-all duration-300`}
          >   <Icon name="zapier" size={70} color="#ff4f00" />
              <h3 className="font-bold text-[19px]">Zapier</h3>
              <div className={`flex items-center gap-1 font-bold text-[14px] text-green-500`}><Icon name="check-circle" size={15} className="text-green-500" />Available</div>
          </a>
          {paragon.getIntegrationMetadata().map((integration) => {
            const isEnabled = userParagon?.integrations?.[integration.type]?.enabled;
            return <div key={integration.type}
              className={`border border-solid border-gray-700 rounded-xl p-4 bg-paper-400 flex flex-col items-start gap-3 ${!authToken.isUserAuthorized ? ``:`cursor-pointer hover:bg-gray-700 transition-all duration-300`}`}
              onClick={() => {
                sendTrack(integration.name)
                if (!authToken.isUserAuthorized) return
                paragon.connect(integration.type, {onSuccess: () => {setUserParagon(paragon.getUser())}, onError: (e) => {}})
            }}>
              <img src={integration.icon} className="!h-[70px]" />
              <h3 className="font-bold text-[19px]">{integration.name}</h3>
              <div className={`flex items-center gap-1 font-bold text-[14px] ${!isEnabled ? "text-gray-500" : "text-green-500"}`}>
                {isEnabled ? <Icon name="check-circle" size={15} className="text-green-500" /> : <Icon name="refresh" size={15} className="text-gray-500" />}
                {isEnabled ? "Connected" : "Not connected"}
            </div></div>
          })}
        </> : <h3 className="text-white-500 text-md">No integrations available</h3>
      }
    </div>
    <CrmContainer />
  </Container>
};
export default IntegrationsModule;
