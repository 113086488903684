export const toNumber = (value?: string | number | null) => value ? value.toLocaleString('fr-FR').replace(/\u202F/g, ',') : '0';
export const toMinuteSeconds = (dateInSeconds?: number | null) => {
    if (dateInSeconds) {
        const convertedValue = Number(String(dateInSeconds).replace('.', ''))
        const hours = Math.floor(convertedValue / 3600);
        const minutes = Math.floor((convertedValue % 3600) / 60);
        const remainingSeconds = convertedValue % 60;
        if (hours > 0) {
            return `${hours}h ${minutes}m ${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}s`;
        }
        return `${minutes}m ${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}s`;
    }
    return '-';
};
