import axios, { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import theme from "../assets/themes";
import { resetAuthSlice } from "store/slices/auth.slice";
import { resetCardSlice } from "store/slices/card.slice";
import { resetListSlice } from "store/slices/list.slice";
import { resetTransactionsSlice } from "store/slices/transactions.slice";
import { resetUpdateUser } from "store/slices/user.slice";
import DomainHelper from "helpers/DomainHelper";

interface Props { children: any }
const instance = axios.create({
  baseURL: DomainHelper.getAPIMain(),
  headers: {
    "Content-Type": "application/json",
    "platform-domain": theme["domain"],
    "Access-Control-Allow-Origin": DomainHelper.getAPPUrl()
}});
instance.interceptors.request.use((config) => {
    const token = localStorage.getItem("accessToken");
    if (token) { config.headers["Authorization"] = `Bearer ${token}`; }
    return config;
  },
  (error) => {return Promise.reject(error);}
);
interface Props {children: any;}
export const AxiosInterceptor: React.FC<Props> = ({ children }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isSet, setIsSet] = useState(false);
  
  useEffect(() => {
    setIsSet(true);
    const resInterceptor = (response: AxiosResponse) => response
    const errInterceptor = (error: AxiosError) => {
      // TODO as the backend now sends a 401 status response, it needs to be reviewed
      if (!error.status || error.code === 'ERR_NETWORK' || error.code === 'ECONNABORTED') {
        navigate('/service-unavailable');
        }
       if (error.response && error.response.status === 401) {
        dispatch(resetAuthSlice());
        dispatch(resetCardSlice());
        dispatch(resetListSlice());
        dispatch(resetTransactionsSlice());
        dispatch(resetUpdateUser());
        localStorage.removeItem("accessToken")
        navigate("/signin");
        // toast.error("Sign in to continue", {toastId: "errorSignin"});
      } 
      return Promise.reject(error);
    };
    const interceptor = instance.interceptors.response.use(resInterceptor, errInterceptor);
    return () => instance.interceptors.response.eject(interceptor);
  }, []);
  
  return isSet && children;
};
export default instance;
