/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ExpandedState,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getExpandedRowModel,
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getFilteredRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import Tooltip from "components/atoms/Tooltip/Tooltip";
import Icon from "components/atoms/Icon/Icon";
import { useAppDispatch, useAppSelector } from "store/hooks";
import Loading from "components/atoms/Loading/Loading";
import SearchMainRow from "./SearchMainRow";
import { LeadProfileDTO } from "utils/dtos/leadDTO";
import { getEmailBest, getPhoneBest } from "utils/workWithData";
import EntryFormatter from "utils/EntryFormatter";
import Button from "components/atoms/Button/Button";
import { getContactRevealedByID } from "store/slices/data.slice";
import { useNavigate, useSearchParams } from "react-router-dom";
import ExportModal from "./ExportModal";
import Popup from "components/molecules/Popup/Popup";
import InputField from "components/atoms/InputField/InputField";
import constants from "utils/constants";
import { toast } from "react-toastify";
import { setTriggerApplyFilters } from "store/slices/ui.slice";
import { useTableSelect } from "../../../utils/hooks/useTableSelect";
import SelectAllCheckbox from "../../molecules/SelectAllCheckbox/SelectAllCheckbox";
import { EnrichmentContactRevealSourceENUM } from "../../../utils/enums/enrichmentENUM";
import BulkSearch from "components/molecules/BulkSearch/BulkSearch";
import { resetUploadData } from "store/slices/list.slice";
import PulseListSkeleton from "components/atoms/layout/PulseListSkeleton/PulseListSkeleton";
import Skeleton from "components/atoms/layout/Skeleton/Skeleton";
const SearchMainBody: FC<{
  searchPeople: string | number;
  setSearchPeople: Dispatch<SetStateAction<string | number>>;
  filterJobTitle: any[];
  filterCompany: any[];
  filterLinkedin?: any[];
  searchLinkedin?: string | number
  clientId?: string;
  setClientId?: Dispatch<SetStateAction<string>>;
  filterIndustry: any[];
  filterLocationPerson: any[];
  filterLocationCompany: any[];
  filterGender: any[];
  filterSeniority: any[];
  filterDepartment: any[];
  filterCompanyHeadcount: any[];
  filterIncomeRange: any[];
  filterCompanyRevenue: any[];
  isSearching: boolean;
  setIsSearching: (newVal: boolean) => void;
  isAppliedFilter: boolean;
  setIsAppliedFilter: (newVal: boolean) => void;
  pageCurrent: number;
  setPageCurrent: (newVal: number) => void;
  orderColumn:
  | "person_name"
  | "company_name"
  | "job_title"
  | "date"
  | undefined;
  setOrderColumn: Dispatch<
    SetStateAction<
      "person_name" | "company_name" | "job_title" | "date" | undefined
    >
  >;
  orderMethod: "asc" | "desc";
  setOrderMethod: Dispatch<SetStateAction<"asc" | "desc">>;
  fetchResults: () => Promise<void>;
}> = ({
  searchPeople,
  setSearchPeople,
  filterLinkedin,
  filterLocationPerson,
  filterLocationCompany,
  filterIncomeRange,
  filterGender,
  filterSeniority,
  filterDepartment,
  filterJobTitle,
  filterIndustry,
  filterCompany,
  filterCompanyHeadcount,
  filterCompanyRevenue,
  clientId,
  setClientId,
  isSearching,
  setIsSearching,
  pageCurrent,
  setPageCurrent,
  orderColumn,
  setOrderColumn,
  orderMethod,
  setOrderMethod,
  fetchResults,
  isAppliedFilter,
  setIsAppliedFilter,
}) => {
    const [showPopupSearchBulk, setShowPopupSearchBulk] =
      useState<boolean>(false);
    const {
      isLoading: isLoadingBulkEnrichment,
      isSuccess: isSuccessBulkEnrichment,
      message: messageBulkEnrichment,
    } = useAppSelector((state) => state.lists.uploadData);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const currentPageId = useAppSelector((state) => state.ui.currentPage);
    const {
      isLoadingData,
      isSuccessData,
      leadsOnPage: leads,
      count,
    } = useAppSelector((state) => state.searchMain);
    // ⭐ Export
    const {
      unselectedRows,
      setUnselectedRows,
      selectedRows,
      setSelectedRows,
      isModalExportOpen,
      setIsModalExportOpen,
      isAllListSelected,
      setIsAllListSelected,
      setAnchorExportSelectMenu,
      isExportSelectMenuOpen,
      anchorExportSelectMenu,
      resetExport,
      isAllRowsInPageSelected,
      handleSelectPeoplePage,
      handleSelectAllPeople,
      isSelectAllChecked,
    } = useTableSelect({ leads, count });
    const [isLoading, setIsLoading] = useState(true);
    const [searchPeopleInput, setSearchPeopleInput] = useState<string | number>(
      ""
    );
    const [clientIdInput, setClientIdInput] = useState<string | number>(
      ""
    );
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    // ⭐ Pagination
    const pageSize = 25;
    const [searchParams, setSearchParams] = useSearchParams();
    const personLocationFromUrl = searchParams.get("person_location")
      ? JSON.parse(searchParams.get("person_location") || "")
      : [];
    const genderFromUrl = searchParams.get("gender")
      ? JSON.parse(searchParams.get("gender") || "")
      : [];
    const jobTitleFromUrl = searchParams.get("job_title")
      ? JSON.parse(searchParams.get("job_title") || "")
      : [];
    const filterLinkedinFromUrl = searchParams.get("linkedin")
      ? JSON.parse(searchParams.get("linkedin") || "")
      : [];
    const seniorityFromUrl = searchParams.get("seniority")
      ? JSON.parse(searchParams.get("seniority") || "")
      : [];
    const departmentFromUrl = searchParams.get("department")
      ? JSON.parse(searchParams.get("department") || "")
      : [];
    const industryFromUrl = searchParams.get("industry")
      ? JSON.parse(searchParams.get("industry") || "")
      : [];
    const companyNameFromUrl = searchParams.get("company_name")
      ? JSON.parse(searchParams.get("company_name") || "")
      : [];
    const companyLocationFromUrl = searchParams.get("company_location")
      ? JSON.parse(searchParams.get("company_location") || "")
      : [];
    const companyHeadcountFromUrl = searchParams.get("company_headcount")
      ? JSON.parse(searchParams.get("company_headcount") || "")
      : [];
    const clientIdFromUrl = searchParams.get("client_id") || "";
    const getQuery = () => {
      return {
        page: searchParams.get("current_page") || pageCurrent,
        size: pageSize,
        search_name: searchPeople || searchParams.get("name") || "",
        ...(filterLinkedin && {
          linkedin:
            filterLinkedin.length > 0
              ? JSON.stringify(filterLinkedin.map((i) => i.value))
              : filterLinkedinFromUrl.length > 0
                ? JSON.stringify(filterLinkedin.map((i: any) => i.value))
                : ``,
        }),
        client_id: clientIdFromUrl || clientId,
        job_titles:
          filterJobTitle.length > 0
            ? JSON.stringify(filterJobTitle.map((i) => i.value))
            : jobTitleFromUrl.length > 0
              ? JSON.stringify(jobTitleFromUrl.map((i: any) => i.value))
              : ``,
        companies:
          filterCompany.length > 0
            ? JSON.stringify(filterCompany.map((i) => i.value))
            : companyNameFromUrl.length > 0
              ? JSON.stringify(companyNameFromUrl.map((i: any) => i.value))
              : ``,
        company_headcounts:
          filterCompanyHeadcount.length > 0
            ? JSON.stringify(filterCompanyHeadcount.map((i) => i.value))
            : companyHeadcountFromUrl.length > 0
              ? JSON.stringify(companyHeadcountFromUrl.map((i: any) => i.value))
              : ``,
        company_revenues:
          filterCompanyRevenue.length > 0
            ? JSON.stringify(filterCompanyRevenue.map((i) => i.value))
            : ``,
        industries:
          filterIndustry.length > 0
            ? JSON.stringify(filterIndustry.map((i) => i.value))
            : industryFromUrl?.length > 0
              ? JSON.stringify(industryFromUrl.map((i: any) => i.value))
              : ``,
        genders:
          filterGender.length > 0
            ? JSON.stringify(filterGender.map((i) => i.value))
            : genderFromUrl?.length > 0
              ? JSON.stringify(genderFromUrl.map((i: any) => i.value))
              : ``,
        seniorities:
          filterSeniority.length > 0
            ? JSON.stringify(filterSeniority.map((i) => i.value))
            : seniorityFromUrl.length > 0
              ? JSON.stringify(seniorityFromUrl.map((i: any) => i.value))
              : ``,
        departments:
          filterDepartment.length > 0
            ? JSON.stringify(filterDepartment.map((i) => i.value))
            : departmentFromUrl.length > 0
              ? JSON.stringify(departmentFromUrl.map((i: any) => i.value))
              : ``,
        locations_person:
          filterLocationPerson.length > 0
            ? JSON.stringify(filterLocationPerson.map((i) => i.value))
            : personLocationFromUrl.length > 0
              ? JSON.stringify(personLocationFromUrl.map((i: any) => i.value))
              : ``,
        locations_company:
          filterLocationCompany.length > 0
            ? JSON.stringify(filterLocationCompany.map((i) => i.value))
            : companyLocationFromUrl.length > 0
              ? JSON.stringify(companyLocationFromUrl.map((i: any) => i.value))
              : ``,
        income_ranges:
          filterIncomeRange.length > 0
            ? JSON.stringify(filterIncomeRange.map((i) => i.value))
            : ``,
        order_column: orderColumn,
        order_method: orderMethod,
      };
    };

    // Restore selected rows from URL params on load
    useEffect(() => {
      const selectedRowsFromParams = searchParams.get("selected_ids");
      if (selectedRowsFromParams) {
        setSelectedRows(selectedRowsFromParams.split(","));
      }
    }, [searchParams]);

    // Update URL when `selectedRows` changes
    useEffect(() => {
      if (selectedRows.length > 0) {
        searchParams.set("selected_ids", selectedRows.join(","));
      } else {
        searchParams.delete("selected_ids");
      }
      setSearchParams(searchParams);
    }, [selectedRows, searchParams, setSearchParams]);

    useEffect(() => {
      const handleResize = () => {
        setScreenSize(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const mobileSize = 810;
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [expanded, setExpanded] = useState<ExpandedState>({});
    const columns = useMemo<ColumnDef<LeadProfileDTO>[]>(
      () =>
        screenSize <= mobileSize
          ? [{
            accessorKey: "name",
            accessorFn: (row) => row.nameFirst ? row.nameFirst + " " + (row.nameLast !== row.nameFirst ? row.nameLast : "") : "-",
            header: () => "Name",
            enableSorting: true,
            cell: ({ getValue }) => {
              const value: any = getValue();
              return <div className="flex items-center gap-3 px-3 py-2  h-[59px] max-h-[59px]">
                {value}
              </div>
            }
          }]
          : [
            {
              accessorKey: "name",
              accessorFn: (row) => {
                return row.nameFirst ? row.nameFirst + " " + (row.nameLast !== row.nameFirst ? row.nameLast : "") : "-";
              },
              header: () => "Name",
              enableSorting: true,
              meta: { size: "20%" },
              cell: ({ getValue }) => {
                const value: any = getValue();
                return <div className="flex items-center gap-3 px-3 py-2  h-[59px] max-h-[59px]">
                  {value}
                </div>
              }
            },
            {
              accessorKey: "jobTitle",
              accessorFn: (row) => EntryFormatter.formatJobTitle(row.linkedInTitle),
              header: () => "Title",
              enableSorting: true,
              meta: { size: "20%" },
              cell: ({ getValue }) => {
                const value: any = getValue();
                return <div className="flex items-center gap-3 px-3 py-2  h-[59px] max-h-[59px]">
                  {value}
                </div>
              },
            },
            {
              accessorKey: "companyName",
              accessorFn: (row) => row.organization?.name ?? "-",
              header: () => "Company",
              enableSorting: true,
              meta: { size: "20%" },
              cell: ({ row, getValue }) => {
                const value: any = getValue();
                return <div className="flex items-center gap-3 px-3 py-2  h-[59px] max-h-[59px]">
                  {value}
                </div>
              },
            },
            {
              accessorKey: "email",
              accessorFn: (row) => getEmailBest(row) ?? null,
              header: () => "Email",
              enableSorting: true,
              meta: { size: "20%" },
              cell: ({ row, getValue }) => {
                if (row.original.hideContactInfo) {
                  return (
                    <div className="flex justify-center items-center gap-2 p-3 h-[59px] max-h-[59px]">
                      <Button
                        iconName="credits" iconSize={15} size="xs"
                        onClick={(e: any) => {
                          e.preventDefault()
                          e.stopPropagation();
                          dispatch(getContactRevealedByID({ leadId: row.original.leadId })).unwrap().then().catch((error) => { toast.error(error?.message); });
                        }}
                      >Reveal Contact</Button>
                    </div>
                  );
                }
                const value: any = getValue();
                if (value) return <div className="h-[59px] !max-h-[59px] items-center justify-center flex"><div className="badge green sm truncate px-3 py-2">{value}</div></div>
              },
            },
            {
              accessorKey: "phone",
              accessorFn: (row) => getPhoneBest(row) ?? null,
              header: () => "Phone",
              enableSorting: true,
              meta: { size: "20%" },
              cell: ({ getValue }) => {
                const value: any = getValue();
                if (value) return <div className="h-[59px] !max-h-[59px] items-center justify-center flex"><div className="badge green sm truncate px-3 py-2">{value}</div></div>
              },
            },
          ],
      [screenSize]
    );
    const table = useReactTable({
      data: useMemo(() => leads, [leads]),
      columns,
      filterFns: {},
      state: { columnFilters, expanded },
      initialState: { pagination: { pageSize, pageIndex: pageCurrent - 1 } },
      onColumnFiltersChange: setColumnFilters,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      paginateExpandedRows: false,
      onExpandedChange: setExpanded,
      getExpandedRowModel: getExpandedRowModel(),
    });
    const getMinWidth = (screenSize: number) => {
      return screenSize < 640 ? '442px' : '800px';
    };
    useEffect(() => {
      if (searchParams.get("current_page"))
        setPageCurrent(Number(searchParams.get("current_page")));
      if (!isLoading) return;
    }, []); // eslint-disable-line
    useEffect(() => {
      if (!isSuccessBulkEnrichment) return;
      toast.success(messageBulkEnrichment, { autoClose: 12000 });
      toast.success("You will be able to export it once it is processed.", { autoClose: 13500, });
      setShowPopupSearchBulk(false);
      dispatch(resetUploadData());
    }, [isSuccessBulkEnrichment]);
    useEffect(() => {
      setSearchParams((params) => {
        const newParams = new URLSearchParams(params);
        newParams.set("current_page", String(pageCurrent));
        return newParams;
      });
    }, [orderColumn, orderMethod, pageCurrent]); // eslint-disable-line
    useEffect(() => {
      if (isLoading) return;
      resetExport();
    }, [
      searchPeople,
      filterLinkedin,
      filterJobTitle,
      filterCompany,
      filterIndustry,
      filterDepartment,
      filterCompanyHeadcount,
      filterCompanyRevenue,
      filterLocationPerson,
      filterLocationCompany,
      filterGender,
      filterIncomeRange,
      filterSeniority,
      clientId

    ]);
    useEffect(() => {
      if (isLoading) return;
      if (!searchParams.get("current_page")) setPageCurrent(1);
    }, [
      searchPeople,
      filterLinkedin,
      filterJobTitle,
      filterCompany,
      filterIndustry,
      filterLocationPerson,
      orderColumn,
      clientId,
      orderMethod,
    ]); // eslint-disable-line
    useEffect(() => {
      if (!isSuccessData) return;
      setIsLoading(false);
    }, [isLoadingData, isSuccessData]);
    useEffect(() => {
      if (searchPeople === searchPeopleInput) return;
      setSearchPeopleInput(searchPeople as string);
    }, [searchPeople]);
    
    return (
      <>
        {showPopupSearchBulk && (
          <Popup onClose={() => setShowPopupSearchBulk(false)} noButton>
            <BulkSearch />
          </Popup>
        )}

        <div className={`flex px-3 sm:px-5 borderBottom min-w-[${getMinWidth(screenSize)}] gap-3 justify-between items-center min-h-[70px]`}>
          <form
            className="flex gap-2 items-center"
            onSubmit={(e) => {
              e.preventDefault();
              setSearchPeople(searchPeopleInput);
              setIsAppliedFilter(true);
              setPageCurrent(1);
              // fetchResults()
            }}
          >
            <InputField
              placeholder="Search People"
              size="tiny"
              style={{ minWidth: '100px' }}
              value={searchPeopleInput}
              onChange={(e: any) => setSearchPeopleInput(e.target.value)}
            />
            <Button
              iconName="search"
              iconSize={16}
              size="xs"
              onClick={() => {
                setSearchPeople(searchPeopleInput);
                dispatch(setTriggerApplyFilters(true));
              }}
            >
              Search
            </Button>
          </form>
          <div className="flex items-center text-sm gap-5">
            {isSearching || isLoadingData ? (
              <Loading height="auto" spinnerSize={38} />
            ) : (
              <>
                {isAllListSelected || selectedRows?.length > 0 ? (
                  <div className="flex items-center gap-5">
                    <p className="text-gray-400">
                      <b className="text-white-500">
                        {isAllListSelected
                          ? Number(
                            (count > constants.LIMIT_ENRICHMENT_ATTEMPT_PER_TASK
                              ? constants.LIMIT_ENRICHMENT_ATTEMPT_PER_TASK
                              : count) - unselectedRows.length
                          ).toLocaleString()
                          : selectedRows.length}
                      </b>{" "}
                      selected
                    </p>
                    <Tooltip content="Export selected leads to CSV">
                      <Button
                        iconName="download"
                        size="xs"
                        className="float-right"
                        onClick={() => setIsModalExportOpen(true)}
                      >
                        Export
                      </Button>
                    </Tooltip>
                  </div>
                ) : (
                  <p className="text-gray-400 sm:block hidden">
                    <b className="text-white-500 mr-1">
                      {EntryFormatter.formatNumber(count)}
                    </b>{" "}
                    results found
                  </p>
                )}
                <Button
                  iconName="search"
                  size="xs"
                  disabled={isLoadingBulkEnrichment}
                  onClick={() => setShowPopupSearchBulk(true)}
                >
                  Bulk Search
                </Button>
                <Button
                  onClick={() => navigate("/exports")}
                  iconName="document"
                  size="xs"
                >
                  Export Lists
                </Button>
              </>
            )}
          </div>
        </div>
        <table className={`min-w-[${getMinWidth(screenSize)}]`}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const textAlign = ["name", "companyName", "jobTitle"].includes(header.id) ? "left" : "center";
                  const canSort = header.column.getCanSort();
                  // const canFilter = header.column.getCanFilter();
                  const sortedState = header.column.getIsSorted();
                  let columnName = "date" as | "date" | "person_name" | "job_title" | "company_name";
                  switch (header.id) {
                    case "name":
                      columnName = "person_name";
                      break;
                    case "jobTitle":
                      columnName = "job_title";
                      break;
                    case "companyName":
                      columnName = "company_name";
                      break;
                  }
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ textAlign, width: header.column.columnDef.meta?.size ?? "auto" }}
                    >
                      {columnName === "person_name" ? (
                        <SelectAllCheckbox
                          count={count}
                          setAnchorExportSelectMenu={setAnchorExportSelectMenu}
                          isSelectAllChecked={isSelectAllChecked}
                          isAllListSelected={isAllListSelected}
                          resetExport={resetExport}
                          isAllRowsInPageSelected={isAllRowsInPageSelected}
                          handleSelectPeoplePage={handleSelectPeoplePage}
                          handleSelectAllPeople={handleSelectAllPeople}
                          isExportSelectMenuOpen={isExportSelectMenuOpen}
                          anchorExportSelectMenu={anchorExportSelectMenu}
                          header={header}
                        />
                      ) : (
                        <>
                          {canSort ? (
                            <div className={`flex ${textAlign === 'center' ? `justify-center` : ``}`}>
                              <div
                                className={"group cursor-pointer select-none w-max"}
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                <div className="flex items-center w-auto gap-2 group-hover:bg-paper-600 group-hover:text-white-500 px-2 py-1 rounded-xl transition">
                                  {flexRender(header.column.columnDef.header, header.getContext())}
                                  {{
                                    asc: <Tooltip content="Sort Ascending"><Icon name="sort-up" size={16} /></Tooltip>,
                                    desc: <Tooltip content="Sort Descending"><Icon name="sort-down" size={16} /></Tooltip>,
                                  }[sortedState as string] ?? null}
                                </div>
                              </div>
                            </div>
                          ) : (
                            flexRender(header.column.columnDef.header, header.getContext())
                          )}
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
        </table>
        <div className={`h-full overflow-y-auto min-w-[${getMinWidth(screenSize)}]`}>
          <table
            className={`main${table.getRowCount() === 0 || isSearching || isLoadingData
              ? " h-full"
              : ""
              }`}
          >
            <tbody>
              {isSearching || isLoadingData ? (
                <PulseListSkeleton removeHeader />
              ) : (
                <>
                  {table.getRowCount() === 0 ? (
                    // ⭐ Empty State
                    <tr className="noHover"><td colSpan={columns.length}><div className="flex gap-3 items-center justify-center">
                      <Icon name="search" size={28} />
                      <h1 className="text-base">No matching results were found</h1>
                    </div></td></tr>
                  ) : (
                    table.getRowModel().rows.map((row) => {
                      return <SearchMainRow
                        isSelected={
                          isAllListSelected
                            ? !unselectedRows.includes(row.original.leadId)
                            : selectedRows.includes(row.original.leadId)
                        }
                        setSelectedRows={isAllListSelected ? setUnselectedRows : setSelectedRows}
                        row={row} key={row.id}
                      />
                    })
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        <table className={`min-w-[${getMinWidth(screenSize)}]`}>
          <tfoot>
            <tr>
              <td colSpan={columns.length}>
                <div className="flex items-center justify-between">

                  <div className="flex items-center gap-1">
                    Showing{" "}
                    {
                      isLoading ? <Skeleton width={40} height={24} /> :
                        <strong className="font-bold text-white-500">
                          {table.getRowCount() > 0 ? (
                            <>
                              {EntryFormatter.formatNumber(
                                (pageCurrent - 1) * pageSize + 1
                              )}
                              -
                              {EntryFormatter.formatNumber(
                                pageCurrent * pageSize > count
                                  ? count
                                  : pageCurrent * pageSize
                              )}
                            </>
                          ) : (
                            0
                          )}
                        </strong>
                    }
                    {"of "}
                    {
                      isLoading ? <Skeleton width={40} height={24} /> :
                        <strong className="font-bold text-white-500">
                          {EntryFormatter.formatNumber(count || table.getRowCount())}
                        </strong>
                    }
                  </div>
                  <div className="flex sm:flex-row gap-4 sm:gap-6 justify-end">
                    <div className="pagination">
                      <div
                        className={`${pageCurrent === 1 ? `disabled` : ``}`}
                        onClick={() => setPageCurrent(1)}
                      >
                        <Icon name="angle-left-double" size={16} />
                      </div>
                      <div
                        className={`${pageCurrent === 1 ? `disabled` : ``}`}
                        onClick={() => setPageCurrent(pageCurrent - 1)}
                      >
                        <Icon name="angle-left" size={16} />
                      </div>
                      <div
                        className={`${pageCurrent === Math.ceil(count / pageSize)
                          ? `disabled`
                          : ``
                          }`}
                        onClick={() => setPageCurrent(pageCurrent + 1)}
                      >
                        <Icon name="angle-right" size={16} />
                      </div>
                      {currentPageId !== constants.LEADSEARCH_KEYWORD && (
                        <div
                          className={`${pageCurrent === Math.ceil(count / pageSize)
                            ? `disabled`
                            : ``
                            }`}
                          onClick={() => setPageCurrent(pageCurrent + 2)}
                        >
                          <Icon name="angle-right-double" size={16} />
                        </div>
                      )}
                      {/* ⭐ Last page - disabled since query doesn't work right now
            <div
              className={`${pageCurrent === Math.ceil(count / pageSize) ? `disabled` : ``}`}
              onClick={()=> {
                const lastPage = Math.ceil(count / pageSize)
                setPageCurrent(lastPage)
              }}
            ><Icon name="angle-right-double" size={16} /></div> */}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
        {/* {isModalContactRevealSubmittedOpen &&  */}
        {/* <Popup onClose={() => setIsModalContactRevealSubmittedOpen(false)} type="modal"><div className="flex flex-col items-center gap-3 sm:gap-6 px-5 py-5">
      <div className="bg-primary-500 flex justify-center items-center rounded-lg w-[58px] h-[52px]">
        <Loading height="auto" spinnerSize={32} color="var(--text-white-500)" />
      </div>
      <h2 className="text-xl font-bold">Revealing Contact...</h2>
      <div className="space-y-3">
        <p className="text-gray-300 text-base">
          We're diving deep into a vast ocean of data to bring you the best insights. This may take a few seconds. We appreciate your patience!
        </p>
        <p className="text-gray-300 text-base">
          Our system is currently scouring trillions of data points in real-time to provide you with the most accurate and comprehensive result. This process ensures thoroughness but may result in longer wait times compared to other tools. We appreciate your understanding as we prioritize precision over speed.
        </p>
      </div>
    </div></Popup> */}
        {/* } */}
        {isModalExportOpen && (
          <Popup onClose={() => setIsModalExportOpen(false)} type="modal">
            <ExportModal
              source={EnrichmentContactRevealSourceENUM.SEARCH}
              totalRowsInList={count}
              isAllListSelected={isAllListSelected}
              setIsAllListSelected={setIsAllListSelected}
              unselectedRows={unselectedRows}
              selectedRows={selectedRows}
              setIsModalOpen={setIsModalExportOpen}
              setSelectedRows={setSelectedRows}
              query={JSON.stringify(getQuery())}
            />
          </Popup>
        )}
      </>
    );
  };
export default SearchMainBody;
