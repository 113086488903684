import { Icon } from "components"
import { Link, useLocation } from "react-router-dom"
import { useAppSelector } from "store/hooks"
import { AppPagePathENUM } from "utils/enums/appENUM"
interface HeaderItemProps {
    label?: string
    name: AppPagePathENUM
    icon: string
}
export const HeaderItem: React.FC<HeaderItemProps> = ({ label, name, icon }) => {
    const location = useLocation();
    const currentPage = useAppSelector((state) => state.ui.currentPage);
    const isActive = currentPage ? name.includes(currentPage) : name.includes(location.pathname)
    return <Link to={`/${name}`} className={`${isActive && 'active'}`} >
        <div className='flex items-center gap-2 capitalize'>
            <Icon name={icon} /> {label ?? name}
        </div></Link>
}