export enum AppSchemeENUM {
    IDENTITY_MATRIX = 'identity_matrix',
    QUALIGENCE = 'qualigence'
}
export enum AppPagePathENUM {
    CLIENT_ANALYTICS = 'analytics',
    PIXEL_KEYWORD = "leads",
    ANALYTICS_KEYWORD = "analytics",
    LEADSEARCH_KEYWORD = "database",
    SCORING = "scoring",
}