import { LeadProfileDTO } from "utils/dtos/leadDTO";
import { EnrichmentTaskStatusENUM } from "utils/enums/enrichmentENUM";
export default class LeadHelper {
    public static isEnriched(status: EnrichmentTaskStatusENUM) {
        if ([EnrichmentTaskStatusENUM.SUCCESS, EnrichmentTaskStatusENUM.SUCCESS_OLD].includes(status)) return true;
        return false
    }
    public static isReadyForDownload(status: EnrichmentTaskStatusENUM) {
        if ([EnrichmentTaskStatusENUM.SUCCESS, EnrichmentTaskStatusENUM.SUCCESS_OLD].includes(status)) return true;
        return false;
    }
    public static getConfidenceString(confidence: number) {
        if (confidence >= 25) return 'Verified';
        return "Likely";
    }
    public static hasContactData(lead: LeadProfileDTO): boolean {
        if (lead.emailWork || lead.emailPersonal || lead.emailAlt || lead.phone || lead.phoneAlt) 
            return true;
        return false;
    }
}