import React from 'react';

import {
    MdAutoAwesome,
    MdCardMembership, MdCheck, MdLogout, MdOutlineHandshake
} from 'react-icons/md';
import {
    RiFlowChart, RiMailCheckLine, RiMailCloseLine, RiMailStarLine, RiOrganizationChart, RiUserLocationLine,
} from 'react-icons/ri';

import { BiBriefcase, BiCategory, BiCodeBlock, BiMaleFemale, BiPencil, BiSave } from 'react-icons/bi';
import {
    BsBuildings,
    BsEnvelope,
    BsFileEarmarkText,
    BsFolder,
    BsGlobeAmericas,
    BsInfoCircle,
    BsLinkedin,
    BsPersonFill,
    BsPersonFillCheck,
    BsSortDown, BsSortDownAlt,
    BsStars,
    BsTelephone,
    BsTrash3,
} from 'react-icons/bs';
import {
    FaAngleDoubleLeft,
    FaAngleDoubleRight,
    FaAngleLeft, FaAngleRight,
    FaBinoculars,
    FaCaretDown,
    FaCaretLeft,
    FaCaretRight,
    FaCaretUp,
    FaCheckCircle,
    FaChevronDown,
    FaChevronLeft, FaChevronRight,
    FaChevronUp,
    FaLaptopCode,
    FaRegClock,
    FaRegLightbulb,
    FaRegMeh,
    FaRegSadTear,
    FaRegStar,
    FaSearchDollar,
    FaUserCircle,
    FaUserPlus,
} from 'react-icons/fa';
import { FaNetworkWired } from "react-icons/fa";

import { FiCompass, FiDollarSign, FiDownloadCloud, FiFilter, FiLock, FiMail, FiMonitor, FiSearch, FiShuffle, FiThumbsDown } from 'react-icons/fi';

import { HiMenuAlt1, HiOutlineCreditCard, HiPlus, } from 'react-icons/hi';
import { IoMdClose, IoMdTime } from "react-icons/io";
import { IoArrowForwardOutline, IoCalendarOutline, IoEyeOffOutline, IoEyeOutline, IoGitBranchOutline, IoLocationOutline, IoMedalOutline, IoPaperPlaneOutline, IoPeople, IoSpeedometerOutline } from 'react-icons/io5';
import {
    LuBadgePercent,
    LuCoins,
    LuCopy,
    LuLayers,
    LuLoader2,
    LuMonitorX,
    LuMousePointerClick,
    LuRefreshCw,
    LuSearchCheck,
    LuSearchX,
} from 'react-icons/lu';
import { RiLinkM } from "react-icons/ri";
import { TbAlertCircleFilled, TbBrandZapier } from 'react-icons/tb';
import { GrDocumentText } from "react-icons/gr";

interface IIcon {
	name: string;
    size?: number;
    color?: string;
    className?: string
}
const Icon: React.FC<IIcon> = ({
	name,
    size = 20,
    color,
    className
}: IIcon): JSX.Element => {
    const params = { size, color, className }
    switch (name) {
        case 'form': return <GrDocumentText {...params}/>
        case 'data-flow': return <RiFlowChart {...params}/>
        case 'caret-up': return <FaCaretUp {...params} />
        case 'caret-left': return <FaCaretLeft {...params} />
        case 'caret-right': return <FaCaretRight {...params} />
        case 'caret-down': return <FaCaretDown {...params} />

        case 'angle-left-double': return <FaAngleDoubleLeft {...params} />
        case 'angle-left': return <FaAngleLeft {...params} />
        case 'angle-right': return <FaAngleRight {...params} />
        case 'angle-right-double': return <FaAngleDoubleRight {...params} />
        case 'shuffle': return <FiShuffle {...params} />

        case 'chevron-left': return <FaChevronLeft {...params} />
        case 'chevron-right': return <FaChevronRight {...params} />
        case 'chevron-down': return <FaChevronDown {...params} />
        case 'chevron-up': return <FaChevronUp {...params} />

        case 'arrow-right': return <IoArrowForwardOutline {...params} />

        case 'sort-down': return <BsSortDown {...params} />
        case 'sort-up': return <BsSortDownAlt {...params} />

        case 'company': return <BsBuildings {...params} />
        case 'compass': return <FiCompass {...params} />
        case 'job': return <BiBriefcase {...params} />
        case 'industry': return <RiOrganizationChart {...params} />
        case 'seniority': return <IoMedalOutline {...params} />
        case 'category': return <BiCategory {...params} />

        case 'phone': return <BsTelephone {...params} />
        case 'email': return <BsEnvelope {...params} />
        case 'paperplane': return <IoPaperPlaneOutline {...params} />

        case 'search': return <FiSearch {...params} />
        case 'search-check': return <LuSearchCheck {...params} />
        case 'search-x': return <LuSearchX {...params} />
        case 'bulb': return <FaRegLightbulb {...params} />

        case 'download': return <FiDownloadCloud {...params} />
        case 'save': return <BiSave {...params} />
        case 'copy': return <LuCopy {...params} />

        case 'people': return <IoPeople {...params} />
        case 'membership': return <MdCardMembership {...params} />
        case 'user-location': return <RiUserLocationLine {...params} />
        case 'user': return <BsPersonFill {...params} />
        case 'user-circle': return <FaUserCircle {...params} />
        case 'user-check': return <BsPersonFillCheck {...params} />
        case 'user-plus': return <FaUserPlus {...params} />

        case 'pulse': return <FaLaptopCode {...params} />
        case 'analytics': return <IoGitBranchOutline {...params} />
        
        case 'network': return <FaNetworkWired {...params} />
        case 'layers': return <LuLayers {...params} />
        case 'lock': return <FiLock {...params} />
        case 'logout': return <MdLogout {...params} />
        case 'card': return <HiOutlineCreditCard {...params} />
        case 'trash': return <BsTrash3 {...params} />
        case 'globe': return <BsGlobeAmericas {...params} />
        case 'code': return <BiCodeBlock {...params} />
        case 'page': return <FiMonitor {...params} />
        case 'pageNotFound': return <LuMonitorX {...params} />
        case 'mouse': return <LuMousePointerClick {...params} />
        case 'sad': return <FaRegSadTear {...params} />
        case 'gender': return <BiMaleFemale {...params} />

        case 'location': return <IoLocationOutline {...params} />

        case 'menu': return <HiMenuAlt1 {...params} />
        case 'refresh': return <LuRefreshCw {...params} />
        case 'time': return <IoMdTime {...params} />
        case 'clock': return <FaRegClock {...params} />
        case 'eye': return <IoEyeOutline {...params} />
        case 'eye-off': return <IoEyeOffOutline {...params} />

        case 'badge-percent': return <LuBadgePercent {...params} />
        case 'folder': return <BsFolder {...params} />
        case 'document': return <BsFileEarmarkText {...params} />
        case 'info': return <BsInfoCircle {...params} />
        case 'plus': return <HiPlus {...params} />
        case 'close': return <IoMdClose {...params} />
        case 'check': return <MdCheck {...params} />
        case 'check-circle': return <FaCheckCircle {...params} />
        case 'alert-circle': return <TbAlertCircleFilled {...params} />
        case 'bulb': return <FaRegLightbulb {...params} />
        case 'loader': return <LuLoader2 {...params} />
        case 'filter': return <FiFilter {...params} />

        case 'credits': return <LuCoins {...params} />
        case 'revenue': return <FiDollarSign {...params} />
        case 'ia': return <MdAutoAwesome {...params} />
        case 'income': return <FaSearchDollar {...params} />

        case 'handshake': return <MdOutlineHandshake {...params} />
        case 'score': return <IoSpeedometerOutline {...params} />
        case 'star': return <FaRegStar {...params} />
        case 'stars': return <BsStars {...params} />
        case 'face-meh': return <FaRegMeh {...params} />
        case 'thumbs-down': return <FiThumbsDown {...params} />

        case 'linkedin': return <BsLinkedin {...params} />
        case 'zapier': return <TbBrandZapier {...params} />
        case 'mail-open': return <FiMail {...params} />
        case 'mail-check': return <RiMailCheckLine {...params} />
        case 'mail-close': return <RiMailCloseLine {...params} />
        case 'mail-star': return <RiMailStarLine {...params} />
        case 'calendar-alt': return <IoCalendarOutline {...params} />

        case 'edit': return <BiPencil {...params} />
        case 'view': return <FaBinoculars {...params} />
        case 'url': return <RiLinkM  {...params}/>

        default: return <div>no icon</div>
    }
};

export default Icon;
