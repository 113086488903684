import { createContext, useCallback, useContext, useState } from "react"
interface TabProviderInitialProps { defaultActive: string, listItems: { name: string, label?: string, icon: string }[], children: any }
const TabContext = createContext({} as unknown as { active: string, listItems: { name: string, label?: string, icon: string }[], handleSetActive: (key: string) => void })
export const TabProvider = (props: TabProviderInitialProps) => {
    const { defaultActive, listItems, children } = props
    const [active, setActive] = useState(defaultActive)
    const handleSetActive = useCallback((tab: string) => setActive(tab), [active])
    return <TabContext.Provider value={{ active, listItems, handleSetActive }}>{children}</TabContext.Provider>
}
export const TabContent = ({ children }: any) => {
    const { active } = useTabs()
    return children({ active })
}
export const useTabs = () => useContext(TabContext)