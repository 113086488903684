import {Table, Icon, Tooltip, Loading} from "components";
import DropDownIItems from "components/atoms/DropDownItems/DropDownItems";
import React, { useState } from "react";
import { prepareForTable, showDate, showEnrichmentOriginTitle } from "utils/workWithData";
import styles from "./style.module.scss"
import { LeadProfileDTO } from "utils/dtos/leadDTO";
import EntryFormatter from "utils/EntryFormatter";
import { EnrichmentOrigin, EnrichmentTaskStatusENUM } from "utils/enums/enrichmentENUM";
import LeadHelper from "helpers/LeadHelper";
const LeadItem: React.FC<{
  data: LeadProfileDTO;
  isMobile: boolean;
  isOpenContent?: boolean;
}>
= ({ data, isMobile, isOpenContent = false }) => {
  
  const [openContent, setOpenContent] = useState<boolean>(isOpenContent);
  const showName = () => {
    if (data.nameFirst && data.nameLast) return `${data.nameFirst} ${data.nameLast}`;
    return "-";
  }
  , showEmail = () => {
    if (EntryFormatter.exists(data.emailPersonal)) return data.emailPersonal!.email;
    if (EntryFormatter.exists(data.emailWork)) return data?.emailWork!.email;
    if (EntryFormatter.exists(data.emailAlt)) return data.emailAlt!.email;
    if (data.enrichmentInputParams) {
      const objInputParams = JSON.parse(data.enrichmentInputParams)
      if (data.enrichmentRequestOrigin === EnrichmentOrigin.SINGLE_ENRICHMENT_EMAIL) return String(objInputParams);
      if (data.enrichmentRequestOrigin === EnrichmentOrigin.BULK_ENRICHMENT) {
        if (objInputParams?.email) return String(objInputParams.email);
      }
    }
    return "-";
  }
  , showPhoneNumber = () => {
    if (EntryFormatter.exists(data.phone)) return data.phone!.number;
    if (EntryFormatter.exists(data.phoneAlt)) return data.phoneAlt!.number;
    return "-";
  }
  , showEnrichmentOriginIcon = () => {
    let iconName
    switch (data.enrichmentRequestOrigin) {
      case 2: iconName = 'globe'; break;
      case 3: iconName = 'document'; break;
      case 4: iconName = 'email'; break;
      case 5: iconName = 'linkedin'; break;
      case 6: iconName = 'phone'; break;
      case 7: iconName = 'company'; break;
      case 8: iconName = 'user-circle'; break;
    }
    if (iconName) return <Icon name={iconName} />
    return null
  }
  , isEnriched = LeadHelper.isEnriched(data.enrichmentStatus)
  , enrichmentInputParams = EntryFormatter.hasValue(data.enrichmentInputParams) ? JSON.parse(data.enrichmentInputParams) : null
  , date = showDate(data.enrichmentCreatedAt)
  return <>
    <tr
      className={isEnriched ? `colapse-row ${openContent && 'opened'}` :``}
      onClick={()=> isEnriched && setOpenContent(!openContent)}
    >
      <td title={showName()}><div className="flex items-center">
        {EntryFormatter.hasValue(showName()) && showName() !== "-" && <>
          {EntryFormatter.hasValue(data.avatarURL)
            ? <img className="w-12 rounded-full mr-2 border-2 border-gray-300" src={data.avatarURL!} alt="avatar" />
            : <div className="w-12 h-12 rounded-full mr-2 border-2 border-gray-300 flex items-center justify-center text-gray-400 bg-gray-800 font-bold text-xl">{showName()[0]}</div>
          }
        </>}
        <div className="truncate">{showName()}</div>
      </div></td>
      <td className="hidden sm:table-cell truncate" title={!openContent ? showEmail() : ""}>{!openContent && showEmail()}</td>
      <td className="hidden sm:table-cell text-center truncate">{!openContent && showPhoneNumber()}</td>
      <td className="hidden sm:table-cell text-center truncate" title={data.organization?.name || ""}>{!openContent && (data.organization?.name || "-")}</td>
      <td className="hidden sm:table-cell text-center truncate" title={date}>{!openContent ? date : ""}</td>
      <td className="hidden sm:table-cell"><div className="flex justify-center gap-4">
        {!openContent && <>
          <Tooltip content={showEnrichmentOriginTitle(data.enrichmentRequestOrigin)}>
            <span>{showEnrichmentOriginIcon()}</span>
          </Tooltip>
          {EntryFormatter.exists(enrichmentInputParams) && data.enrichmentRequestOrigin === EnrichmentOrigin.TRAFFIC_INTEL && 
            <Tooltip content={enrichmentInputParams?.leadOwner || ""}><Icon name="user-circle" /></Tooltip>
          }
        </>}
      </div></td>
      <td className="text-center">{!openContent &&
        <div className={`badge ${isEnriched ?'primary':'off'}`}>
          {([EnrichmentTaskStatusENUM.PROCESSING, EnrichmentTaskStatusENUM.PROCESSING_OLD].includes(data.enrichmentStatus))
            ? <Loading height="auto" spinnerSize={15} color="var(--color-white-500)" />
            : <Icon name={isEnriched ? "search-check" : "search-x"} size={17} />
          }
          {isEnriched 
            ? "Enriched" 
            : ([EnrichmentTaskStatusENUM.PROCESSING, EnrichmentTaskStatusENUM.PROCESSING_OLD].includes(data.enrichmentStatus))
              ? "Processing..." : "Not Enriched"
          }
        </div>
      }</td>
    </tr>
    {openContent && <tr className="colapse-item"><td colSpan={isMobile ? 2:7}>
      <LeadItemContent data={prepareForTable(data)} isMobile={isMobile} />
    </td></tr>}
  </>
}

function splitObject<T>(originalObject: T, keysForFirstObject: (keyof T)[]): [Partial<T>, Partial<T>] {
  const firstObject: Partial<T> = {};
  const secondObject: Partial<T> = {};
  for (const key in originalObject) {
    if (keysForFirstObject.includes(key as keyof T)) {
      firstObject[key] = originalObject[key];
    } else
      secondObject[key] = originalObject[key];
  }
  return [firstObject, secondObject];
}
const dropDownKeys = [
  "Personal Email",
  "Business Email",
  "Alt Email",
  "Phone",
  "Alt Phone",
]
const LeadItemContent: React.FC<any> = ({ data, isMobile }) => {
  const isEmailKey = (key: string) => {
    if (key.toLowerCase().includes("email")) return "email";
    return "";
  }
  const isPhoneKey = (key: string) => {
    if (key.toLowerCase().includes("phone")) return "number";
    return "";
  };
  const keysForFirstObject: (keyof typeof data)[] = [
    "First Name", "Last Name", "Phone", "Alt Phone", "Business Email", "Personal Email", "Alt Email"
  ]
  const [dataFirst, dataSecond] = splitObject(data, keysForFirstObject);
  const showData = (key: any, data: any) => {
    if (isMobile) {
      return <td>
        <p className="font-bold text-gray-400 mb-1">{key}</p>
        {dropDownKeys.includes(key) 
          ? <DropDownIItems
              data={data[key]}
              type={isEmailKey(key) || isPhoneKey(key)}
              keyType={key}
            />
          : <p className="break-words" title={data[key]}>{data[key]}</p>
        }
      </td>
    }
    return <>
      <td className={`${styles.table_key} w35 font-bold !text-gray-400`} title={key}>{key}</td>
      <td className="truncate">{dropDownKeys.includes(key) 
        ? <DropDownIItems
            data={data[key]}
            type={isEmailKey(key) || isPhoneKey(key)}
            keyType={key}
          />
        : <span title={data[key]}>{
            (EntryFormatter.isValidHttpUrl(data[key]) || data[key].includes("linkedin.com/in/"))
              ? <a className="link" href={!data[key].includes("https://") ? `https://${data[key]}` : data[key]} target="_blank" rel="noreferrer">{data[key]}</a>
              : data[key]
        }</span>
      }</td>
    </>
  }
  return <div className={styles.container}>
    <Table><tbody>
      {Object.keys(dataFirst).map((key, index) => {
        return <tr key={index}>{showData(key, data)}</tr>
      })}
    </tbody></Table>
    <Table><tbody>
      {Object.keys(dataSecond).map((key, index) => (
        <tr key={index}>{showData(key, data)}</tr>
      ))}
    </tbody></Table>
  </div>
}
export default LeadItem;
