const constants = {
  PIXEL_KEYWORD: "leads",
  ANALYTICS_KEYWORD: "analytics",
  LEADSEARCH_KEYWORD: "database",
  SCORING: "scoring",

  SINGLE_LEAD_ENRICHMENT_CREDIT_COST_DEFAULT: 1,
  SINGLE_LEAD_ENRICHMENT_CREDIT_COST_QUALIGENCE: 1, // cents

  LIMIT_ENRICHMENT_ATTEMPT_PER_TASK: 10000,

  CREDITS_PURCHASE_LIMIT_MAXIMUM: 25000,
  CREDITS_PURCHASE_LIMIT_MINIMUM: 50,

  BRAND_NAME: "Identity Matrix",
  COMPANY_1_DOMAIN: "identitymatrix.ai",
  COMPANY_1_EMAIL_SUPPORT: "support@identitymatrix.ai",

  IDENTITY_MATRIX_INTERCOM_KEY: "cogdu9io",
  QUALIGENCE_INTERCOM_KEY: "xyuoksxk",

  EMAIL_VALIDATION_REGEX: /^[\w.+-]+@\w+([\.-]?\w+)*(\.\w{2,})+$/,
}
export default constants;