/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import Container from "components/atoms/layout/Container/Container";
import SearchMainBody from "./SearchMainBody";
import SearchMainFilters from "./SearchMainFilters";
import "./index.scss";
import Icon from "components/atoms/Icon/Icon";
import constants from "utils/constants";
import { useSearchParams } from "react-router-dom";
import { parseJSON } from "utils/parseJson";
import { useAppDispatch } from "store/hooks";
import { getSearchMainCount, getSearchMainData } from "store/slices/searchMain.slice";
import { setCurrentPage } from "store/slices/ui.slice";
import { buildQueryString } from "../../../utils/buildQueryString";

const CONTENT_CLASS = `bg-gray-900 rounded-lg flex flex-col h-[76vh]`;
const SearchMainModule: React.FC<any> = () => {
  // ⭐ Filters
  const [filterJobTitle, setFilterJobTitle] = useState<any[]>([]);
  const [filterSeniority, setFilterSeniority] = useState<any[]>([]);
  const [filterDepartment, setFilterDepartment] = useState<any[]>([]);
  const [filterIndustry, setFilterIndustry] = useState<any[]>([]);
  const [filterCompany, setFilterCompany] = useState<any[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isAppliedFilter, setIsAppliedFilter] = useState(false);
  const [pageCurrent, setPageCurrent] = useState<number>(1);
  const [orderColumn, setOrderColumn] = useState<"person_name" | "company_name" | "job_title" | "date" | undefined>();
  const [orderMethod, setOrderMethod] = useState<"asc" | "desc">("desc");
  const [filterCompanyHeadcount, setFilterCompanyHeadcount] = useState<any[]>([]);
  const [filterCompanyRevenue, setFilterCompanyRevenue] = useState<any[]>([]);
  const [filterLocationCompany, setFilterLocationCompany] = useState<any[]>([]);
  const [filterLocationPerson, setFilterLocationPerson] = useState<any[]>([]);
  const [searchLinkedin, setSearchLinkedin] = useState<string | number>('');
  const [filterGender, setFilterGender] = useState<any[]>([]);
  const [filterIncomeRange, setFilterIncomeRange] = useState<any[]>([]);
  // ⭐ Search Inputs
  const [searchPeople, setSearchPeople] = useState<string | number>("");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // @ts-ignore
    const params = Object.fromEntries([...searchParams]);
    if (params.name) setSearchPeople(params.name);
    if (params.search_linkedin) setSearchLinkedin(params.search_linkedin || '');
    if (params.person_location) setFilterLocationPerson(parseJSON(params.person_location));
    if (params.gender) setFilterGender(parseJSON(params.gender));
    if (params.job_title) setFilterJobTitle(parseJSON(params.job_title));
    if (params.seniority) setFilterSeniority(parseJSON(params.seniority));
    if (params.department) setFilterDepartment(parseJSON(params.department));
    if (params.industry) setFilterIndustry(parseJSON(params.industry));
    if (params.company_name) setFilterCompany(parseJSON(params.company_name));
    if (params.company_location) setFilterLocationCompany(parseJSON(params.company_location));
    if (params.company_headcount) setFilterCompanyHeadcount(parseJSON(params.company_headcount));
    if (params.company_revenue) setFilterCompanyRevenue(parseJSON(params.company_revenue));
    if (params.income_range) setFilterIncomeRange(parseJSON(params.income_range));
  }, [searchParams]);
  const dispatch = useAppDispatch();
  const pageSize = 25;
  const personLocationFromUrl = searchParams.get("person_location")
  const genderFromUrl = searchParams.get("gender")
  const jobTitleFromUrl = searchParams.get("job_title")
  const seniorityFromUrl = searchParams.get("seniority")
  const departmentFromUrl = searchParams.get("department")
  const industryFromUrl = searchParams.get("industry")
  const companyNameFromUrl = searchParams.get("company_name")
  const companyLocationFromUrl = searchParams.get("company_location")
  const companyHeadcountFromUrl = searchParams.get("company_headcount")
  const nameFromUrl = searchParams.get("name")
  const linkedinFromUrl = searchParams.get("linkedin")
  const pageFromUrl = searchParams.get("current_page")
  // const filterDeps = [
  //   searchPeople,
  //   searchLinkedin,
  //   filterJobTitle,
  //   filterCompany,
  //   filterIndustry,
  //   filterLocationPerson,
  //   filterGender,
  //   filterSeniority,
  //   filterDepartment,
  //   filterLocationCompany,
  //   filterCompanyHeadcount,
  // ];
  const queryParams = useCallback(() => {
    // const allEmpty = filterDeps.every(dep => dep === "" || (Array.isArray(dep) && dep.length === 0));
    // if (allEmpty) return { page };
    return {
      page: pageFromUrl,
      size: pageSize,
      search_name: nameFromUrl || "",
      linkedin: buildQueryString(linkedinFromUrl),
      job_titles: buildQueryString(jobTitleFromUrl),
      companies: buildQueryString(companyNameFromUrl),
      company_headcounts: buildQueryString(companyHeadcountFromUrl),
      industries: buildQueryString(industryFromUrl),
      genders: buildQueryString(genderFromUrl),
      seniorities: buildQueryString(seniorityFromUrl),
      departments: buildQueryString(departmentFromUrl),
      locations_person: buildQueryString(personLocationFromUrl),
      locations_company: buildQueryString(companyLocationFromUrl),
      // company_revenues: filterCompanyRevenue.length > 0 ? JSON.stringify(filterCompanyRevenue.map((i) => i.value)) : ``,
      // income_ranges: filterIncomeRange.length > 0 ? JSON.stringify(filterIncomeRange.map((i) => i.value)) : ``,
      order_column: orderColumn,
      order_method: orderMethod,
    };
  }, [nameFromUrl, linkedinFromUrl, jobTitleFromUrl, companyNameFromUrl, industryFromUrl, genderFromUrl, seniorityFromUrl, departmentFromUrl, personLocationFromUrl, companyLocationFromUrl,companyHeadcountFromUrl, orderColumn, orderMethod, pageSize, pageFromUrl]);
  const fetchResults = useCallback(async () => {
    const params = queryParams();
    setIsSearching(true);
    dispatch(getSearchMainCount(params));
    dispatch(getSearchMainData(params));
    setIsSearching(false);
  }, [queryParams]);
  useEffect(() => {
    fetchResults();
  }, [fetchResults]);
  useEffect(() => {
    window.analytics.page("Reveal")
    dispatch(setCurrentPage(constants.LEADSEARCH_KEYWORD));
  }, []);

  return (
    <Container>
      <div className="flex sm:gap-6"><div className="flex basis-1/4"><div className="items-center flex gap-3 text-white-500">
        <Icon name="search" size={30} />
        <h1 className="font-bold capitalize text-2xl">{constants.LEADSEARCH_KEYWORD}</h1>
      </div></div></div>
      <div className="flex sm:gap-6">
        <div className={`${CONTENT_CLASS} basis-1/5 gap-3 sm:flex hidden`}>
          <SearchMainFilters pageCurrent={pageCurrent} setPageCurrent={setPageCurrent}
            isAppliedFilter={isAppliedFilter} setIsAppliedFilter={setIsAppliedFilter} isSearching={isSearching}
            fetchResults={fetchResults} searchPeople={searchPeople} setSearchPeople={setSearchPeople}
            // setSearchLinkedin={setSearchLinkedin} searchLinkedin={searchLinkedin}
            filterIncomeRange={filterIncomeRange} setFilterIncomeRange={setFilterIncomeRange}
            filterGender={filterGender} setFilterGender={setFilterGender} filterJobTitle={filterJobTitle}
            setFilterJobTitle={setFilterJobTitle} filterCompany={filterCompany} setFilterCompany={setFilterCompany}
            filterIndustry={filterIndustry} setFilterIndustry={setFilterIndustry}
            filterLocationPerson={filterLocationPerson} setFilterLocationPerson={setFilterLocationPerson}
            filterLocationCompany={filterLocationCompany} setFilterLocationCompany={setFilterLocationCompany}
            filterSeniority={filterSeniority} setFilterSeniority={setFilterSeniority}
            filterDepartment={filterDepartment} setFilterDepartment={setFilterDepartment}
            filterCompanyHeadcount={filterCompanyHeadcount} setFilterCompanyHeadcount={setFilterCompanyHeadcount}
            filterCompanyRevenue={filterCompanyRevenue} setFilterCompanyRevenue={setFilterCompanyRevenue} />
        </div>
        <div className={`SearchMainBody ${CONTENT_CLASS} w-full sm:w-auto sm:basis-4/5 overflow-x-auto`}
        >
           <SearchMainBody isAppliedFilter={isAppliedFilter} setIsAppliedFilter={setIsAppliedFilter}
            fetchResults={fetchResults} orderMethod={orderMethod} setOrderMethod={setOrderMethod}
            pageCurrent={pageCurrent} setPageCurrent={setPageCurrent} orderColumn={orderColumn}
            setOrderColumn={setOrderColumn} isSearching={isSearching} setIsSearching={setIsSearching}
            searchPeople={searchPeople} setSearchPeople={setSearchPeople} searchLinkedin={searchLinkedin} 
            filterJobTitle={filterJobTitle} filterCompany={filterCompany}
            filterCompanyHeadcount={filterCompanyHeadcount} filterCompanyRevenue={filterCompanyRevenue}
            filterIndustry={filterIndustry} filterLocationPerson={filterLocationPerson}
            filterLocationCompany={filterLocationCompany} filterGender={filterGender}
            filterSeniority={filterSeniority} filterDepartment={filterDepartment}
            filterIncomeRange={filterIncomeRange} />
        </div>
      </div>
      {/* {isModalOpen && (
        <Popup onClose={() => setIsModalOpen(false)} type="modal">
          <ExportModal
            selectedRows={selectedRows}
            setIsModalOpen={setIsModalOpen}
            setSelectedRows={setSelectedRows}
          />
        </Popup>
      )} */}
    </Container>
  );
};
export default SearchMainModule;
