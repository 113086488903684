import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { convertQueryString } from "utils/workWithData";
import { LeadProfileDTO } from "utils/dtos/leadDTO";
import { ContactRevealedDTO } from "utils/dtos/contactRevealDTO";
import {EnrichmentContactRevealSourceENUM} from "../../utils/enums/enrichmentENUM";
import DomainHelper from "helpers/DomainHelper";

const baseUrl = DomainHelper.getAPIMain()
const urlEnrichment = DomainHelper.getAPIEnrichment()
interface ILeadState {
  isLastPage: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  numberOfLeads: number;
  datas: LeadProfileDTO[];
  errorMessage: string | unknown;
  contactsRevealed: ContactRevealedDTO[];
  searchData: {
    data: LeadProfileDTO;
    isLoading: boolean;
    isSuccess: boolean;
    errorMessage: string | unknown;
  };
  leadView: {
    data: LeadProfileDTO | null;
    isLoading: boolean;
    isSuccess: boolean;
    errorMessage: string | unknown;
  };
  exportLead: {
    isLoading: boolean;
    isSuccess: boolean;
    errorMessage: string | unknown;
    data: any;
  };
}
const initialState: ILeadState = {
  isLoading: false,
  isLastPage: false,
  numberOfLeads: 0,
  datas: [],
  isSuccess: false,
  errorMessage: "",
  contactsRevealed: [] as ContactRevealedDTO[],
  searchData: {
    isLoading: false,
    errorMessage: "",
    data: {} as LeadProfileDTO,
    isSuccess: false,
  },
  leadView: {
    isLoading: false,
    errorMessage: null,
    data: null,
    isSuccess: false,
  },
  exportLead: {
    data: null,
    errorMessage: null,
    isLoading: false,
    isSuccess: false,
  },
};

export const getLeadItem = createAsyncThunk("lead/getLeadItem", async (params: {leadId: string, source: string}, thunkAPI) => {
  try {
    const response = await axios.get(`${baseUrl}lead/fetch/item?leadId=${params.leadId}&source=${params.source}`, { withCredentials: true });
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
}});
export const getSingleDataWithEmail = createAsyncThunk("data/getSingleDataWithEmail", async (data: { email: string }, thunkAPI) => {
  try {
    const response = await axios.post(`${urlEnrichment}lead/enrichment/single/email`, data, { withCredentials: true });
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
}});
export const getSingleDataWithLinkedin = createAsyncThunk("data/getSingleDataWithLinkedin", async (data: { linkedInURL: string }, thunkAPI) => {
  try {
    const response = await axios.post(`${urlEnrichment}lead/enrichment/single/linkedin`, data, { withCredentials: true });
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
}});
export const getSingleDataWithNameCompany = createAsyncThunk("data/getSingleDataWithNameCompany",
  async (data: { firstName: string; lastName: string; companyEmailDomain: string }, thunkAPI) => {
    try {
      const response = await axios.post(`${urlEnrichment}lead/enrichment/single/name_company`,
        data, { withCredentials: true }
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
}});
export const getSingleDataWithPhone = createAsyncThunk("data/getSingleDataWithPhone", async (data: { phoneNumber: number }, thunkAPI) => {
    try {
      const response = await axios.post(`${urlEnrichment}lead/enrichment/single/phone_number`, data, { withCredentials: true });
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
}});
export const getContactRevealedByID = createAsyncThunk("data/getContactRevealedByID", async (data: { leadId: string }, thunkAPI) => {
  try {
    const response = await axios.post(`${urlEnrichment}lead/enrichment/contact_reveal/item`,
      {leadId: data.leadId },
      {withCredentials: true}
    );
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
}});
export const exportContactRevealedByIDs = createAsyncThunk("data/exportContactRevealedByIDs",
  async (data: { leadIds: string[]; leadIdsToIgnore: string[], listName: string, query: string, isAllLeadsSelected: boolean, leadHistoryCount: number, source: EnrichmentContactRevealSourceENUM }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${urlEnrichment}lead/enrichment/contact_reveal/bulk`,
        { isAllLeadsSelected: data.isAllLeadsSelected,
          leadIds: data.leadIds,
          leadIdsToIgnore: data.leadIdsToIgnore,
          leadHistoryCount: data.leadHistoryCount,
          query: data.query,
          listName: data.listName,
          source: data.source
        },
        { withCredentials: true }
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
}});
export const getItemEnrichedByUserViaSearchInput = createAsyncThunk("lead/fetch/item_enriched_by_user_via_search_input", async (data: { linkedInURL: string }, thunkAPI) => {
  try {
    const response = await axios.post(`${baseUrl}lead/fetch/item_enriched_by_user_via_search_input`, data, { withCredentials: true });
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
}});
export const getSingleDataWithNameAddress = createAsyncThunk("data/getSingleDataWithNameAddress", async (data: any, thunkAPI) => {
  try {
    const response = await axios.post(`${urlEnrichment}lead/enrichment/single/name_address`, data, { withCredentials: true });
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
}});
export const getSingleDatas = createAsyncThunk("data/getSingleDatas", async (queryParams: any, thunkAPI) => {
  try {
    const query = convertQueryString(queryParams);
    const url = `${baseUrl}lead/history/single?${query}`;
    const response = await axios.get(url);
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
}});
export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    resetDataSlice: (state) => {
      state.isLoading = false;
      state.datas = [];
      state.isSuccess = false;
      state.errorMessage = "";
      state.isLastPage = false;
      state.numberOfLeads = 0;
      state.searchData.data = {} as LeadProfileDTO;
      state.searchData.isLoading = false;
      state.searchData.isSuccess = false;
    },
    resetSearchData: (state) => {
      state.searchData.data = {} as LeadProfileDTO;
      state.searchData.isLoading = false;
      state.searchData.isSuccess = false;
      state.searchData.errorMessage = "";
    },
    resetSearchDataWithoutData: (state) => {
      state.searchData.isLoading = false;
      state.searchData.isSuccess = false;
      state.searchData.errorMessage = "";
    },
    resetExport: (state) => {
      state.exportLead.isLoading = false;
      state.exportLead.isSuccess = false;
      state.exportLead.errorMessage = "";
      state.exportLead.data = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSingleDataWithEmail.pending, (state, action) => {state.searchData.isLoading = true;});
    builder.addCase(getSingleDataWithEmail.fulfilled, (state, action) => {
      state.searchData.isLoading = false;
      state.searchData.isSuccess = true;
      state.searchData.errorMessage = action.payload.message || "";
      state.searchData.data = action.payload.data;
    });
    builder.addCase(getSingleDataWithEmail.rejected, (state, action: any) => {
      state.searchData.isLoading = false;
      state.searchData.errorMessage = action.payload?.message;
      state.searchData.data = {} as LeadProfileDTO;
      state.searchData.isSuccess = false;
    });
    builder.addCase(exportContactRevealedByIDs.pending, (state, action) => { state.exportLead.isLoading = true; });
    builder.addCase(exportContactRevealedByIDs.fulfilled, (state, action) => {
      state.exportLead.isLoading = false;
      state.exportLead.isSuccess = true;
      state.exportLead.errorMessage = action.payload.message || "";
      state.exportLead.data = action.payload.data;
    });
    builder.addCase(exportContactRevealedByIDs.rejected, (state, action: any) => {
      state.exportLead.isLoading = false;
      state.exportLead.errorMessage = action.payload?.message;
      state.exportLead.data = {};
      state.exportLead.isSuccess = false;
    });
    builder.addCase(getSingleDataWithNameAddress.pending, (state, action) => { state.searchData.isLoading = true; });
    builder.addCase(getSingleDataWithNameAddress.fulfilled, (state, action) => {
      state.searchData.isLoading = false;
      state.searchData.isSuccess = true;
      state.searchData.errorMessage = action.payload.message || "";
      state.searchData.data = action.payload.data;
    });
    builder.addCase(getSingleDataWithNameAddress.rejected, (state, action) => {
      state.searchData.isLoading = false;
      state.searchData.errorMessage = action.payload;
      state.searchData.data = {} as LeadProfileDTO;
      state.searchData.isSuccess = false;
    });
    builder.addCase(getSingleDataWithLinkedin.pending, (state, action) => { state.searchData.isLoading = true; });
    builder.addCase(getSingleDataWithLinkedin.fulfilled, (state, action) => {
      state.searchData.isLoading = false;
      state.searchData.isSuccess = true;
      state.searchData.errorMessage = action.payload.message || "";
      state.searchData.data = action.payload.data;
    });
    builder.addCase(getSingleDataWithLinkedin.rejected, (state, action: any) => {
      state.searchData.isLoading = false;
      state.searchData.isSuccess = false;
      state.searchData.errorMessage = action.payload?.message || "Something went wrong";
      state.searchData.data = {} as LeadProfileDTO;
    });
    builder.addCase(getItemEnrichedByUserViaSearchInput.pending, (state, action) => { state.searchData.isLoading = true;});
    builder.addCase(getItemEnrichedByUserViaSearchInput.fulfilled, (state, action) => {
      state.searchData.isLoading = false;
      state.searchData.isSuccess = true;
      state.searchData.errorMessage = action.payload.message || "";
      state.searchData.data = action.payload.data;
    });
    builder.addCase(getItemEnrichedByUserViaSearchInput.rejected,
      (state, action: any) => {
        state.searchData.isLoading = false;
        state.searchData.isSuccess = false;
        state.searchData.errorMessage = action.payload?.message || "Something went wrong";
        state.searchData.data = {} as LeadProfileDTO;
    });
    builder.addCase(getSingleDataWithNameCompany.pending, (state, action) => { state.searchData.isLoading = true; });
    builder.addCase(getSingleDataWithNameCompany.fulfilled, (state, action) => {
      state.searchData.isLoading = false;
      state.searchData.isSuccess = true;
      state.searchData.errorMessage = action.payload.message || "";
      state.searchData.data = action.payload.data;
    });
    builder.addCase(getSingleDataWithNameCompany.rejected, (state, action: any) => {
      state.searchData.isLoading = false;
      state.searchData.errorMessage = action.payload.message || "Something went wrong";
      state.searchData.data = {} as LeadProfileDTO;
      state.searchData.isSuccess = false;
    });
    builder.addCase(getSingleDataWithPhone.pending, (state, action) => { state.searchData.isLoading = true; });
    builder.addCase(getSingleDataWithPhone.fulfilled, (state, action) => {
      state.searchData.isLoading = false;
      state.searchData.isSuccess = true;
      state.searchData.errorMessage = action.payload.message || "";
      state.searchData.data = action.payload.data;
    });
    builder.addCase(getSingleDataWithPhone.rejected, (state, action: any) => {
      state.searchData.isLoading = false;
      state.searchData.isSuccess = false;
      state.searchData.errorMessage = action.payload?.message || "Something went wrong";
      state.searchData.data = {} as LeadProfileDTO;
    });
    builder.addCase(getSingleDatas.pending, (state, action) => { state.isLoading = true; });
    builder.addCase(getSingleDatas.fulfilled, (state, action) => {
      state.isLoading = false;
      state.errorMessage = "";
      state.isLastPage = action.payload.data.isLastPage;
      state.numberOfLeads = action.payload.data.numberOfLeads;
      state.datas = [...state.datas, ...action.payload.data.paginatedLeads];
      state.isSuccess = true;
    });
    builder.addCase(getSingleDatas.rejected, (state, action: any) => {
      state.isLoading = false;
      state.errorMessage = action.payload?.message;
      state.datas = [];
      state.isSuccess = false;
    });
    builder.addCase(getContactRevealedByID.pending, (state, action) => {
      const contactFound = state.contactsRevealed.find((i) => i.id === action.meta.arg.leadId);
      if (contactFound) {
        contactFound.isSuccess = false;
        contactFound.isLoading = true;
        return;
      }
      state.contactsRevealed.push({
        id: action.meta.arg.leadId,
        data: null,
        isLoading: true,
        isSuccess: false,
        errorMessage: null,
      });
    });
    builder.addCase(getContactRevealedByID.fulfilled, (state, action) => {
      const contactFound = state.contactsRevealed.find((i) => i.id === action.meta.arg.leadId);
      if (!contactFound) return;
      contactFound.isLoading = false;
      contactFound.isSuccess = true;
      contactFound.errorMessage = null;
      contactFound.data = action.payload.data;
    });
    builder.addCase(getContactRevealedByID.rejected, (state, action: any) => {
      const contactFound = state.contactsRevealed.find((i) => i.id === action.meta.arg.leadId);
      if (!contactFound) return;
      contactFound.isLoading = false;
      contactFound.isSuccess = false;
      contactFound.errorMessage = action.payload?.message || "Something went wrong";
      contactFound.data = null;
    });
  }
});
export const {
  resetDataSlice,
  resetSearchData,
  resetSearchDataWithoutData,
  resetExport,
} = dataSlice.actions;
