import Button from "components/atoms/Button/Button";
import Icon from "components/atoms/Icon/Icon";
import LeadScore from "components/atoms/LeadScore/LeadScore";
import Loading from "components/atoms/Loading/Loading";
import Tooltip from "components/atoms/Tooltip/Tooltip";
import Container from "components/atoms/layout/Container/Container";
import LeadViewSkeleton from 'components/atoms/layout/LeadViewSkeleton/LeadViewSkeleton';
import Skeleton from "components/atoms/layout/Skeleton/Skeleton";
import LeadHelper from "helpers/LeadHelper";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getContactRevealedByID, getLeadItem } from "store/slices/data.slice";
import { getClientICP } from "store/slices/icp.slice";
import { getLeadStats, getPixelsByPersons, handleRevealContactByID } from "store/slices/insights";
import { getPersonalInfo } from "store/slices/user.slice";
import EntryFormatter from "utils/EntryFormatter";
import constants from "utils/constants";
import { LeadProfileDTO } from "utils/dtos/leadDTO";
import { PixelDTO } from "utils/dtos/pixelDTO";
import { messages } from "utils/message";
import { secondsToHMS, showDate } from "utils/workWithData";
import { CompanyInfo } from "../Template/companyInfo";
import { PersonalInfo } from "../Template/personalInfo";
import { Breadcumb } from "components/atoms/Breadcrumb";
import { Conversions } from "../Template/conversions";

const badgesClass = "flex items-center gap-2 text-sm text-gray-300";
const LeadViewModule: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { leadId } = useParams();
  const [searchParams] = useSearchParams();
  const appSource = searchParams.get("app_source")
  const [lead, setLead] = useState<LeadProfileDTO | undefined>();
  const [pulses, setPulses] = useState<PixelDTO[]>([]);
  const [isStatsLoading, setIsStatsLoading] = useState(false);
  const { data: ICPData } = useAppSelector((state) => state.icp);
  const threshold = ICPData?.threshold ?? 10;
  const { isLoading: CRPixelIsLoading, isSuccess: CRPixelIsSuccess, data: CRPixelData } = useAppSelector((state) => state.insights.contactsRevealed.find((x) => x.id === leadId)) ?? {}
  const { isLoading: CRSearchIsLoading, isSuccess: CRSearchIsSuccess, data: CRSearchData } = useAppSelector((state) => state.data.contactsRevealed.find((x) => x.id === leadId)) ?? {};
  const [isLoading, setIsLoading] = useState(true);
  const [IsPulseListLoading, setIsPulseListLoading] = useState(true);

  const fetchLead = async () => {
    if (!leadId || !isLoading) {
      setIsLoading(false);
      return;
    }
    const res = await dispatch(getLeadItem({ leadId, source: appSource || constants.LEADSEARCH_KEYWORD }));
    if (res.payload?.data) {
      setLead(res.payload.data);
      fetchLeadPulseStats();
      fetchLeadPulseList();
    }
    setIsLoading(false);
  };
  const fetchLeadPulseStats = async () => {
    setIsStatsLoading(true);
    const res = await dispatch(getLeadStats({ leadId: leadId! }));
    setIsStatsLoading(false);
    if (!res.payload?.data) return;
    const leadStats = res.payload.data;
    setLead((prev: any) => ({
      ...prev,
      visitLast: leadStats.visitLast,
      visits: leadStats.visits,
      visitors: leadStats.visitors,
      pageViews: leadStats.pages,
      duration: leadStats.duration,
      leadScore: leadStats.leadScore,
    }))
  }
  const fetchLeadPulseList = async () => {
    const res = await dispatch(getPixelsByPersons(leadId!));
    if (!EntryFormatter.isArrayEmpty(res.payload.data)) setPulses(res.payload.data);
    setIsPulseListLoading(false);
  };

  useEffect(() => {
    window.analytics.page("Lead Details", { leadId, source: appSource || constants.LEADSEARCH_KEYWORD })
    fetchLead();
  }, []);
  useEffect(() => {
    if (!CRPixelIsSuccess) return;
    if (CRPixelData) {
      setLead((prev) => ({ ...prev, ...CRPixelData, hideContactInfo: false }))
      setTimeout(() => {
        dispatch(getPersonalInfo())
      }, 10000)
      if (LeadHelper.hasContactData(CRPixelData)) toast.success(messages.ENRICHMENT_CONTACTREVEAL_SUCCESS, { toastId: "contactRevealed" });
      else toast.error(messages.ENRICHMENT_CONTACTREVEAL_CONTACT_MISSING);
    } else {
      toast.error(messages.ENRICHMENT_CONTACTREVEAL_FAILED);
    }
  }, [CRPixelIsSuccess]);
  useEffect(() => {
    if (!CRSearchIsSuccess) return;
    if (CRSearchData) {
      setLead((prev) => ({
        ...prev, ...CRSearchData,
        visitLast: prev?.visitLast,
        visits: prev?.visits,
        visitors: prev?.visitors,
        pageViews: prev?.pageViews,
        duration: prev?.duration,
        leadScore: prev?.leadScore,
        hideContactInfo: false,
      }))
      setTimeout(() => {
        dispatch(getPersonalInfo())
      }, 10000)
      if (LeadHelper.hasContactData(CRSearchData)) toast.success(messages.ENRICHMENT_CONTACTREVEAL_SUCCESS, { toastId: "contactRevealed" });
      else toast.error(messages.ENRICHMENT_CONTACTREVEAL_CONTACT_MISSING);
    } else {
      toast.error(messages.ENRICHMENT_CONTACTREVEAL_FAILED);
    }
  }, [CRSearchIsSuccess]);
  useEffect(() => { dispatch(getClientICP()); }, [dispatch]);
  const fullName = useMemo(() => {
    if (!lead) return ''
    const concatNameAndLastname = `${lead?.nameFirst} ${lead?.nameLast}`
    if (concatNameAndLastname) {
      window.document.title = `${concatNameAndLastname} - ${constants.BRAND_NAME}`
      return concatNameAndLastname
    }
  }, [lead])
  if (isLoading) return <Container><LeadViewSkeleton /></Container>;
  if (!lead) {
    // TODO: improve UI
    return <Container><div className="flex flex-col items-center justify-center text-white-500 text-lg gap-3 sm:pt-48">
      <Icon name="sad" size={60} />Lead not found
    </div></Container>
  }
  return <Container>
    <div className="flex flex-col gap-1 sm:gap-1">
      <Breadcumb appSource={appSource} fullName={fullName} />
      <div className="flex flex-col md:flex-row sm:gap-9 max-sm:gap-3">
        <div className="flex items-center gap-5">
          <h1 className="font-bold text-2xl">{fullName}</h1>
          {lead.linkedInURL && <Tooltip content="LinkedIn Profile">
            <a href={EntryFormatter.formatLink(lead.linkedInURL)} target="_blank" className="text-sm text-white-500 hover:text-blue-500" rel="noreferrer"><Icon name="linkedin" /></a>
          </Tooltip>}
        </div>
        <div className="flex items-center gap-6">
          {isStatsLoading ? <Skeleton width={48} height={32} /> : <Tooltip content="Lead Score"><LeadScore value={lead.leadScore || 0} threshold={threshold} loading={threshold === 0} /></Tooltip>}
          <Tooltip content="Visits"><div className={badgesClass}>
            <Icon name="mouse" size={21} />
            {isStatsLoading ? <Skeleton width={20} height={20} /> : lead.visits || 0}
          </div></Tooltip>
          <Tooltip content="Page Views"><div className={badgesClass}>
            <Icon name="page" size={20} />
            {isStatsLoading ? <Skeleton width={20} height={20} /> : lead.pageViews || 0}
          </div></Tooltip>
          <Tooltip content="Duration"><div className={badgesClass}>
            <Icon name="clock" size={18} />
            {isStatsLoading ? <Skeleton width={20} height={20} /> : secondsToHMS(lead.duration || 0)}
          </div></Tooltip>
        </div>
        {lead.hideContactInfo && <>
          {CRSearchIsLoading || CRPixelIsLoading
            ? <div><Loading height="auto" spinnerSize={28} /></div>
            : <Button size="sm" iconName="credits" iconSize={18} className="!h-[35px]"
              onClick={(e: any) => {
                if (appSource === constants.PIXEL_KEYWORD)
                  dispatch(handleRevealContactByID({ leadId: leadId! })).unwrap().then().catch((error) => {
                    toast.error(error?.message);
                  })
                else {
                  toast.success("Searching trillions of records in real-time. This may take a few seconds. We appreciate your patience!", { toastId: "fetchingContactRevealItem", autoClose: 10000 });
                  dispatch(getContactRevealedByID({ leadId: leadId! })).unwrap().then().catch((error) => {
                    toast.error(error?.message);
                  })
                }
              }}
            >Reveal Contact</Button>
          }</>}
      </div>
    </div>
    <div className="flex max-md:flex-col gap-5">
      <div className="basis-2/5 flex flex-col gap-5">
        <PersonalInfo lead={lead} />
        <CompanyInfo lead={lead} />
      </div>
      <div className="basis-3/5 flex flex-col gap-5">
        <div className={`bg-gray-900 rounded-lg flex flex-col gap-5 py-4 h-full  ${pulses.length > 0 ? 'min-w-[600px] min-h-[300px]' : 'min-h-[200px]'}`}>
          <h2 className="font-bold text-lg px-4">Pages Visited</h2>
          {IsPulseListLoading
            ? <div className="flex justify-center items-center h-full"><Loading height="auto" spinnerSize={40} /></div>
            : pulses.length > 0 ? (
              <table className="table style-1">
                <thead><tr>
                  <th align="left"><div className="flex gap-2 items-center"><Icon name="globe" size={20} />URL</div></th>
                  <th className="w-1/6"><div className="flex justify-center items-center gap-2"><Icon name="time" size={20} />Duration</div></th>
                  <th className="w-1/4"><div className="flex justify-center items-center gap-2"><Icon name="calendar-alt" size={20} />Date</div></th>
                </tr></thead>
                <tbody>
                  {pulses.map((pulse) => {
                    const PageTitle: React.FC<{ isLink: boolean }> = ({ isLink }) => {
                      if (pulse.pageName.length > 60)
                        return <Tooltip content={pulse.pageName} isFullWidth>
                          <p className={`${isLink ? `link` : ``} font-bold badgeTable truncate sm off w-auto`}>{pulse.pageName}</p>
                        </Tooltip>
                      return <div className={`${isLink ? `link` : ``} badge font-bold sm off min-w-[35px] flex items-center gap-2`}>
                        {pulse.pageName}
                        {isLink && <Icon name="url" size={15} />}
                      </div>
                    }
                    return <tr key={pulse.id}>
                      <td>{pulse.url
                        ? <a href={EntryFormatter.formatLink(pulse.url)} target="_blank" className="link" rel="noreferrer"><PageTitle isLink /></a>
                        : <PageTitle isLink={false} />}
                      </td>
                      <td align="center">
                        <div className={"badge font-bold min-w-[35px] sm off"}>{secondsToHMS(pulse.timeOnPage)}</div>
                      </td>
                      <td align="center" className="truncate">
                        <div className={"badge font-bold min-w-[35px] sm off"}>{showDate(pulse.dateVisited)}</div>
                      </td>
                    </tr>
                  })}
                </tbody></table>
            ) : (
              <div className="h-full w-full flex flex-col sm:gap-5 justify-center items-center text-white-500 text-base">
                <Icon name="page" size={60} />
                No website traffic to report from this person
              </div>
            )}
        </div>
        {lead.formData && <Conversions lead={lead} />}
      </div>
    </div>
  </Container>
};
export default LeadViewModule;
