import { ICompany } from "types/company/company.type";
import { OrganizationDTO } from "utils/dtos/organizationDTO";
import { PlatformBrandName } from "utils/enums/platformBrandName";

export interface IUser {
  _id: string;
  clientId: string;
  userId: string;

  organizationName: string;
  organization: OrganizationDTO;

  creditBalance: number;
  creditPurchase: number;
  creditUsage: number;
  revenue: number;

  email: string;
  firstName: string;
  lastName: string;

  hasUnlimitedCredits: boolean;
  hasVerifiedEmail: boolean;

  isPartner: boolean;
  isFirstLogin: boolean;
  isCoordinator: boolean;
  sentEmail: boolean;

  gatewayCustomerId: string | null;
  gatewayBillingUrl: string | null;

  platformBrandId: PlatformBrandName;
  company?: ICompany;
  isInvitedUser?: boolean;
  userRole: EUserRoles
}
export interface IUpdateUser {
  isSuccess: boolean;
  isLoading: boolean;
  errorMessage: string | unknown;
  successMessage: string;
}
export interface ISentInvation {
  isSuccess: boolean;
  isLoading: boolean;
  errorMessage: string | unknown;
  successMessage: string;
}
export interface IUserState {
  isLoading: boolean;
  isSuccess: boolean;
  data: IUser;
  errorMessage: string | unknown;
  updateUser: IUpdateUser;
  sendinviteMessage: ISentInvation;
}

export interface ICompanyTransaction {
  amount: number;
  createdDate: string;
}

export interface ICompanyUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  organizationName: string;
  creditBalance: number;
  userRole: EUserRoles
  isAutoRecharge: boolean;
  autoRechargeAmount: number;
  transactions: ICompanyTransaction[];
}

export enum EUserRoles {
  ADMIN = "admin",
  TEAM_MEMBER = "team_member",
}
export enum EUserRolesDTO {
 admin = "Admin",
 team_member = "Team Member", 
}