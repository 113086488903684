import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { IUser } from "types/user/user.type";
import { IUserState } from "types/user/user.type";
import DomainHelper from "helpers/DomainHelper";

const baseUrl = DomainHelper.getAPIMain();

const initialState: IUserState = {
  data: {
    hasVerifiedEmail: true
  } as IUser,
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
  updateUser: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    successMessage: "",
  },
  sendinviteMessage: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    successMessage: "",
  },
};

export const getPersonalInfo = createAsyncThunk(
  "account/getPersonalInfo",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${baseUrl}account/personal_info`, {
        withCredentials: true,
      });
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const updateUser = createAsyncThunk(
  "account/personalInfo",
  async (
    data: { newEmail?: string; firstName?: string; lastName?: string },
    thunkAPI
  ) => {
    try {
      const response = await axios.post(
        `${baseUrl}account/personal_info`,
        data,
        { withCredentials: true }
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const updateUserPassword = createAsyncThunk(
  "user/updateUserPassword",
  async (data: { newPassword: string; currentPassword: string }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseUrl}account/change_password`,
        data,
        { withCredentials: true }
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const sentinviteMessage = createAsyncThunk(
  "user/sentinviteMessage",
  async (email: string, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseUrl}account/invite_user`,
        { email },
        { withCredentials: true }
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getUserByCID = createAsyncThunk(
  "user/getUserByCID",
  async (cid: string, thunkAPI) => {
    try {
      const response = await axios.get(`${baseUrl}auth/user_by_cid/${cid}`);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUpdateUser: (state) => {
      state.updateUser.errorMessage = "";
      state.updateUser.successMessage = "";
      state.updateUser.isLoading = false;
      state.updateUser.isSuccess = false;
    },
    resetUser: (state) => {
      state.errorMessage = "";
      state.data = {} as IUser;
      state.isLoading = false;
      state.isSuccess = false;
    },
    resetSentinvite: (state) => {
      state.sendinviteMessage.errorMessage = "";
      state.sendinviteMessage.isLoading = false;
      state.sendinviteMessage.isSuccess = false;
      state.sendinviteMessage.successMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPersonalInfo.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getPersonalInfo.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
      state.isSuccess = true;
      state.errorMessage = null;
    });
    builder.addCase(getPersonalInfo.rejected, (state, action: any) => {
      state.data = {} as IUser;
      state.isLoading = false;
      state.errorMessage = action.payload?.message || "Something went wrong";
      state.isSuccess = false;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.updateUser.isLoading = false;
      state.updateUser.isSuccess = true;
      state.updateUser.errorMessage = "";
      state.updateUser.successMessage = action.payload.message;
    });
    builder.addCase(updateUser.rejected, (state, action: any) => {
      state.updateUser.isLoading = false;
      state.updateUser.successMessage = "";
      state.updateUser.isSuccess = false;
      state.updateUser.errorMessage =
        action.payload?.message || "Something went wrong";
    });
    builder.addCase(updateUser.pending, (state, action) => {
      state.updateUser.isLoading = true;
    });
    builder.addCase(updateUserPassword.fulfilled, (state, action) => {
      state.updateUser.isLoading = false;
      state.updateUser.errorMessage = "";
      state.updateUser.isSuccess = true;
      state.updateUser.successMessage = action.payload.message;
    });
    builder.addCase(updateUserPassword.rejected, (state, action: any) => {
      state.updateUser.isLoading = false;
      state.updateUser.successMessage = "";
      state.updateUser.isSuccess = false;
      state.updateUser.errorMessage =
        action.payload?.message || "Something went wrong";
    });
    builder.addCase(updateUserPassword.pending, (state, action) => {
      state.updateUser.isLoading = true;
    });
    builder.addCase(sentinviteMessage.pending, (state, action) => {
      state.sendinviteMessage.isLoading = true;
    });
    builder.addCase(sentinviteMessage.fulfilled, (state, action) => {
      state.sendinviteMessage.isLoading = false;
      state.sendinviteMessage.errorMessage = "";
      state.sendinviteMessage.isSuccess = true;
      state.sendinviteMessage.successMessage = action.payload.message;
    });
    builder.addCase(sentinviteMessage.rejected, (state, action: any) => {
      state.sendinviteMessage.isLoading = false;
      state.sendinviteMessage.successMessage = "";
      state.sendinviteMessage.isSuccess = false;
      state.sendinviteMessage.errorMessage =
        action.payload?.message || "Something went wrong";
    });
    builder
    .addCase(getUserByCID.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.errorMessage = "";
    })
    .addCase(getUserByCID.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = action.payload.data;
    })
    .addCase(getUserByCID.rejected, (state, action: any) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.errorMessage = action.payload?.message || "Something went wrong";
    });
  },
});

export const { resetUpdateUser, resetUser, resetSentinvite } =
  userSlice.actions;
