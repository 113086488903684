import Icon from "components/atoms/Icon/Icon";
import Loading from "components/atoms/Loading/Loading";
import Tooltip from "components/atoms/Tooltip/MovingTooltip";
import { FC, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getClientAnalyticsByNode } from "store/slices/client.slice";
import { ClientAnalyticsNodesDTO, ClientAnalyticsRowDTO } from "store/types";
import { buildQueryString } from "utils/buildQueryString";
interface IProps {
  scrollToEnd: () => void;
}

const AnalyticsBody: FC<IProps> = ({ scrollToEnd }) => {
  const { isLoading: analyticsLoading, data: analyticsData, } = useAppSelector(state => state.client.analytics);
  const calculateQtySteps = () => {
    if (analyticsData?.rows) {
      const allSteps = analyticsData.rows.flatMap((row) =>
        row.nodes
          .map((node) => node.step)
      );
      const maxStep = Math.max(...allSteps);
      return maxStep;
    }
    return 0;
  };
  const [qtySteps, setQtySteps] = useState(calculateQtySteps());
  const [spaceXBetweenNodes, setSpaceXBetweenNodes] = useState(240);
  const [spaceYBetweenNodesAfterFirstStep, setSpaceYBetweenNodesAfterFirstStep] = useState(45);
  const maxHeightPerRow = 250
  const nodeWidth = 20
  const widthContent = qtySteps * spaceXBetweenNodes
  const [hoveredNode, setHoveredNode] = useState<string | any | number>('');
  const [highlightedNodes, setHighlightedNodes] = useState<string[] | any>([]);
  const [isStep, setStep] = useState<number>(1);
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams();
  const jobTitleFromUrl = searchParams.get("job_title")
  const industryFromUrl = searchParams.get("industry")
  const personLocationFromUrl = searchParams.get("person_location")
  // const handlePage = (index: any) => {
  //   console.log(index, 'index')
  //   if (isStep < pageCurrent) {setPageCurrent(isStep); dispatch(stepHandler(isStep))}
  //   else {setPageCurrent(pageCurrent + 1);dispatch(stepHandler(pageCurrent + 1))}
  // };
  const handleMouseEnter = (nodeId: string | number, nodeStep: number) => {
    setHoveredNode(nodeId);
    setStep(nodeStep);
    const findParentNodes = (nodeId: string | number, parents: string[] = []): string[] => {
      const parentLinks = analyticsData?.rows
        .flatMap((row) => row.links)
        .filter((link: { source: string | number, target: string | number }) => link.target === nodeId) || [];
      const parentNodes: string[] = parentLinks.map((link) => link.source);
      if (parentNodes.length > 0) {
        return parentNodes.reduce((acc: any, parentNodeId: any) => {
          if (!acc.includes(parentNodeId)) {
            acc.push(parentNodeId);
            acc.push(...findParentNodes(parentNodeId, acc));
          }
          return acc;
        }, []);
      }
      return parents;
    };
    const parentNodes = findParentNodes(nodeId);
    setHighlightedNodes([nodeId, ...parentNodes]);
  };

  const handleNodeClick = (nodeId: string) => {
    let clickedNodeStep: number | undefined;
    let clickedNodePath: string | undefined;
    analyticsData?.rows.forEach((row) => {
      row.nodes.forEach((node) => {
        if (node.id === nodeId) {
          clickedNodeStep = node.step;
          clickedNodePath = extractPath(node.id);
        }
      });
    });
    if (clickedNodeStep === undefined || clickedNodePath === undefined) return;
    analyticsData?.rows.forEach((row) => {
      row.nodes.forEach((node) => {
        const nodePath = extractPath(node.id);
        const nodeStep = node.step;
      });
    });
  };
  const extractPath = (nodeId: string): string => {
    const splitId = nodeId.split('|');
    return splitId.slice(1).join('/');
  };
  const newQueryParams = useCallback((node: any) => {
    return {
      path_id: node,
      job_titles: buildQueryString(jobTitleFromUrl),
      industries: buildQueryString(industryFromUrl),
      locations_person: buildQueryString(personLocationFromUrl),
    }
  }, [jobTitleFromUrl, industryFromUrl, personLocationFromUrl]);
  const handleMouseLeave = () => {
    setHoveredNode('');
    setHighlightedNodes([]);
    setStep(0);
  };
  const calculateNodeAndStrokeHeight = (visits: number, step: number) => {
    let nodeHeight = visits / 7;
    const safetyMargin = 10;

    if (step === 1) {
      nodeHeight = Math.min(nodeHeight, maxHeightPerRow - safetyMargin);
    } else {
      nodeHeight = Math.min(nodeHeight, spaceYBetweenNodesAfterFirstStep - safetyMargin);
    }

    return Math.max(nodeHeight, 5);
  };

  const calculateDynamicSpacing = () => {
    if (!analyticsData || !analyticsData.rows) return { calculatedSpaceX: 240, calculatedSpaceY: 45 };
    const maxNodeHeight = Math.max(
      ...analyticsData.rows.flatMap((row) =>
        row.nodes.map((node) => calculateNodeAndStrokeHeight(node.visits || 0, node.step))
      ));
    const rawSpaceY = maxNodeHeight + 20;
    const cappedSpaceY = Math.min(rawSpaceY, 70);

    const calculatedSpaceY = Math.max(cappedSpaceY, 45);
    const calculatedSpaceX = 240;

    return { calculatedSpaceX, calculatedSpaceY };
  };
  const getXYPosition = (dto: ClientAnalyticsNodesDTO, indexInStep: number) => {
    const xPosition = (dto.step - 1) * spaceXBetweenNodes;
    const yPosition = indexInStep * spaceYBetweenNodesAfterFirstStep;
    return { x: xPosition, y: yPosition };
  };
  const getRowHeight = (row: ClientAnalyticsRowDTO) => {
    const columnHeights: Record<number, number> = {};
    row.nodes.forEach((node) => {
      const visits = row.links.find((link) => link.target === node.id)?.value || node.visits || 0;
      let nodeHeight = visits / 7;
      const safetyMargin = 10;
      nodeHeight = Math.min(nodeHeight, node.step === 1
        ? maxHeightPerRow - safetyMargin
        : spaceYBetweenNodesAfterFirstStep - safetyMargin
      );
      nodeHeight = Math.max(nodeHeight, 45);
      if (!columnHeights[node.step]) columnHeights[node.step] = 0;
      columnHeights[node.step] += nodeHeight + 25;
    });
    return Math.max(...Object.values(columnHeights));
  };
  const queryParams = useCallback(() => {
    return {
      job_titles: buildQueryString(jobTitleFromUrl),
      industries: buildQueryString(industryFromUrl),
      locations_person: buildQueryString(personLocationFromUrl),
    }
  }, [jobTitleFromUrl, industryFromUrl, personLocationFromUrl,])
  useEffect(() => {
    if (!analyticsData) return
    const { calculatedSpaceX, calculatedSpaceY } = calculateDynamicSpacing();
    setSpaceXBetweenNodes(calculatedSpaceX);
    setSpaceYBetweenNodesAfterFirstStep(calculatedSpaceY);
    setQtySteps(calculateQtySteps());
    scrollToEnd();
  }, [analyticsData])
  // useEffect(() => {
  //   if (analyticsData) return
  //   dispatch(getClientAnalytics(queryParams()))
  // }, [queryParams])
  // if (analyticsLoading) return <Loading height="100%" />
  // if (analyticsData?.rows.length === 0 || !analyticsIsSuccess || !analyticsData) return <div className="w-full h-full flex flex-col gap-3 items-center justify-center">
  //   <Icon name="analytics" className="text-white-500" size={50} />
  //   <p className='text-gray-400'><b className='text-white-500 mr-1'>{analyticsData?.rows.length || 0}</b>{' '}analytics found</p>
  // </div>

  const handleRedirect = (node: string) => {
    const params = new URLSearchParams();

    if (node) params.set("path_id", node);
    if (jobTitleFromUrl) params.set("job_title", jobTitleFromUrl);
    if (industryFromUrl) params.set("industry", industryFromUrl);
    if (personLocationFromUrl) params.set("person_location", personLocationFromUrl);

    const queryString = params.toString();
    const url = `/analytics/view?${queryString}`;

    window.open(url, '_blank');
  };
  if (analyticsLoading) return <div className="h-full w-full"><Loading height="100%" /></div>
  return <div className="relative px-4 pb-2 flex flex-col gap-4">
    {qtySteps > 0 && <header style={{ width: `${widthContent}px` }}>
      <table><thead><tr>
        {Array.from({ length: qtySteps }, (_, i) => {
          const hasNodesInStep = analyticsData?.rows.some((row) => row.nodes.some((node) => node.step === i + 1));
          return hasNodesInStep ? (
            <th key={"step-" + i} style={{ transition: 'all 0.3s ease-in-out' }} className={`!px-0 !pb-0 !border-none text-left ${isStep - 1 >= i ? '!text-white-500 scale-105' : 'text-gray-400 scale-95'}`}>
              {i === 0 ? `Starting Point` : `Step +${i + 1}`}
            </th>
          ) : null;
        })}
      </tr></thead></table>
    </header>}
    <div>
      {analyticsData?.rows.map((row, indexRow) => {
        return <div key={"row-" + indexRow} className="relative w-full" style={{ height: getRowHeight(row) }}>
          {row.nodes.map((node, indexNode) => {
            const visits = row?.links.find(link => link.target === node.id)?.value || node.visits || 0;
            const nodeHeight = calculateNodeAndStrokeHeight(visits, node.step)
            const getIndexInSameStep = row.nodes.filter(n => n.step === node.step).findIndex(n => n.id === node.id);
            const pos = getXYPosition(node, getIndexInSameStep)
            return (<div className="absolute" key={indexRow + "node-" + indexNode} onMouseLeave={handleMouseLeave}
              onMouseEnter={() => handleMouseEnter(node.id, node.step)} style={{ top: pos.y, left: pos.x, width: spaceXBetweenNodes }}>
              <Tooltip // followCursor
                tooltipStyles={{ width: 300, padding: "7px" }}
                content={<>
                  <div className="font-bold text-paper-500 text-[15px] truncate mb-1">{node.name}</div>
                  <div className="flex gap-3">
                    {/* COMMENTED FOR NOW - IT'S GONNA BE USED TO SEE LEADS IN PATH */}
                    {/* <Link to={`/analytics/view?path_id=${node.id}`} state={location.state} > */}
                    <div onClick={() => handleRedirect(node.id)}>
                      <div className="flex items-center gap-1 cursor-pointer hover:text-primary-500 hover:underline text-paper-400">
                        <Icon name="people" color="var(--color-primary-500)" />
                        <div className="text-[12px]">Leads</div>
                      </div>
                    </div>
                    {/* </Link> */}
                    <div className="flex items-center gap-1">
                      <Icon name="url" color="var(--color-primary-500)" />
                      <div className="text-paper-400 text-[12px]">{visits} Visits</div>
                    </div></div>
                </>}>
                <div className={`flex gap-3 z-10 relative cursor-pointer`} onClick={() => {
                  dispatch(getClientAnalyticsByNode(newQueryParams(node.id)))
                  handleNodeClick(node.id)
                }}>
                  {/* onClick={() => handlePage(indexRow)} */}
                  <div className={`transition bg-primary-500 rounded-md border ${highlightedNodes.includes(node.id) ? "border-primary-300" : "border-transparent"}`} style={{ height: nodeHeight, width: nodeWidth }} />
                  <div className={`transition ease-in delay-300 relative flex-1 overflow-hidden ${highlightedNodes.includes(node.id) ? "scale-110" : ""}`}>
                    <h2 className={`transition-transform text-sm font-bold truncate -mt-1 w-full max-w-[150px] whitespace-nowrap`}>{node.name}</h2>
                    <h3 className="text-[12px]">{visits}</h3>
                  </div>
                </div>
              </Tooltip></div>)
          })}
          <svg className="absolute inset-0 h-full pointer-events-none" style={{ width: spaceXBetweenNodes * qtySteps }}>
            {row.links.map((link, indexLink) => {
              const sourceNode = row.nodes.find(node => node.id === link.source);
              const targetNode = row.nodes.find(node => node.id === link.target);
              if (!sourceNode || !targetNode) return
              const sourcePos = getXYPosition(sourceNode, row.nodes.filter(n => n.step === sourceNode.step).findIndex(n => n.id === sourceNode.id));
              const targetPos = getXYPosition(targetNode, row.nodes.filter(n => n.step === targetNode.step).findIndex(n => n.id === targetNode.id));
              const strokeWidth = calculateNodeAndStrokeHeight(link.value, targetNode.step);
              const isFirstLineInStep = targetPos.y === 0;
              const marginForFirstRow = isFirstLineInStep ? strokeWidth < 24 ? strokeWidth > 10 ? 9 : 3 : 20 : 0;
              const adjustmentMargin1 = isFirstLineInStep ? strokeWidth > nodeWidth ? -5 : 0 : 3;
              const path = `
              M${sourcePos.x + nodeWidth + 5},${sourcePos.y + marginForFirstRow + adjustmentMargin1}
              C${sourcePos.x + 150},${sourcePos.y + marginForFirstRow + adjustmentMargin1}
              ${targetPos.x - 60},${targetPos.y + marginForFirstRow + adjustmentMargin1}
              ${targetPos.x - 5},${targetPos.y + marginForFirstRow + adjustmentMargin1}
            `;
              return <path
                key={indexRow + "path-" + indexLink}
                d={path}
                strokeWidth={strokeWidth}
                style={{ transition: "stroke 0.4s, opacity 0.3s" }}
                className={`fill-none stroke-primary-500 ${highlightedNodes.includes(link.target) ? "opacity-60 z-50" : "opacity-20"}`}
              />
            })}
          </svg>
        </div>
      })}
    </div></div>
};
export default AnalyticsBody