import ChartSkeleton from 'components/atoms/layout/ChartSkeleton/ChartSkeleton';
import StatsCard from 'components/atoms/StatsCard/StatsCard';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { toMinuteSeconds, toNumber } from 'utils/format';
import { showDate } from 'utils/workWithData';
import { AnalyticClientStatsProps } from '../types/index.type';
import { CardBarStats } from 'components/atoms/CardBarStats';
const AnalyticClientStats: React.FC<AnalyticClientStatsProps> = ({ metrics, icp, threshold, graph, panelVolumeVisitors, panelConvertingVisitors, panelQualityVolumePerson, panelQualityVolume, panelDurationICP, panelConvertionICP, }) => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 rounded-lg mb-4">
            <div className="col-span-12 md:col-span-3">
                <StatsCard title="Visitors Seen" isLoading={metrics?.isLoading} value={toNumber(metrics?.data?.visitorsSeen)} />
            </div>
            <div className="col-span-12 md:col-span-3">
                <StatsCard title="Visitors Identified" isLoading={metrics?.isLoading} value={toNumber(metrics?.data?.visitorsIdentified)} subtitle={`${metrics?.data?.visitorsIdentifiedPercentBasedOnSeen}% of Visitors Seen`} />
            </div>
            <div className="col-span-12 md:col-span-3">
                <StatsCard title="Avg. Conversion Rate of Visitors" isLoading={metrics.isLoading} value={`${toNumber(metrics.data?.visitorsConversionRateAvg)}%`} />
            </div>
            <div className="col-span-12 md:col-span-3">
                <StatsCard title="Avg. Score of Visitors" threshold={threshold} isLoading={metrics.isLoading} value={Number(metrics?.data?.visitorsIdentifiedScoreAvg || 0)} />
            </div>
            <div className="col-span-12 md:col-span-3">
                <StatsCard title="ICP Visitors" isLoading={icp?.isLoading} value={icp && toNumber(icp?.data?.icpVisitors)} subtitle={`${icp.data?.icpVisitorsPercentBasedOnVisitorsIdentified}% of Visitors Identified`} />
            </div>
            <div className="col-span-12 md:col-span-3">
                <StatsCard title="Avg. Session Duration of Visitors" isLoading={metrics.isLoading} value={toMinuteSeconds(metrics?.data?.visitorsSessionDurationAvg)} iconName='clock' />
            </div>
            <div className="col-span-12 md:col-span-3">
                <StatsCard title="Avg. Conversion Rate of ICP" isLoading={metrics.isLoading} value={`${icp.data?.icpVisitorsConversionRateAvg}%`} />
            </div>
            <div className="col-span-12 md:col-span-3">
                <StatsCard title="Avg. Score of ICP" threshold={threshold} isLoading={icp.isLoading} value={Number(icp.data?.icpVisitorsScoreAvg || 0)} />
            </div>
            <div className="flex flex-col bg-gray-900 w-full h-full col-span-12 min-h-[280px] p-4 rounded-lg gap-5 max-h-fit">
                <h1 className='font-extrabold text-white-500 text-base absolute'>Visitor Insights</h1>
                {/* <ChartSkeleton /> */}
                {graph.isLoading ? <ChartSkeleton /> :
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            width={500} height={600} margin={{ top: 8, right: 24, left: -24, bottom: 0 }}
                            data={graph?.data?.map((item: {
                                day: string
                                visitor_identified: string
                                visitors_seen: string
                            }) => {
                                return ({ ...item, day: showDate(item.day, false)})
                            })}
                        >
                            <CartesianGrid stroke="#444" vertical={false} opacity={.4} />
                            <XAxis tick={{ fill: 'rgb(156 163 175)' }} interval={0} tickMargin={8} axisLine={false} tickLine={false} dataKey="day" fontSize={12} />
                            <YAxis tick={{ fill: 'rgb(156 163 175)' }} axisLine={false} tickLine={false} tickFormatter={(value) => value === 0 ? '' : `${value}`} fontSize={12} />
                            <Tooltip labelStyle={{ color: '#333', fontWeight:'bold' }} />
                            <Legend verticalAlign="top" align="right" iconType="circle" height={50} iconSize={10} wrapperStyle={{ fontSize: 13 }} />
                            <Line type="monotone" dataKey="visitors_seen" name='Visitors Seen' stroke="#7F00FF" strokeWidth={2} dot={false} />
                            <Line type="monotone" dataKey="visitors_identified" name="Visitors Identified" stroke="#9370DB" strokeWidth={2} dot={false} />
                            <Line type="monotone" dataKey="icp_visitors" name="Visitors ICP" stroke="#EE82EE" strokeWidth={2} dot={false} />
                        </LineChart>
                    </ResponsiveContainer>
                }
            </div>
            {/* TODO: Remove isso daqui Jean se quiser ver os filtros, precisa remover no model tambem */}
            {/* <div className="col-span-6">
                <CardBarStats title='Highest Volume Pages of Visitors' segregation='Visitors' data={panelVolumeVisitors.data || []} isLoading={panelVolumeVisitors.isLoading} />
            </div>
            <div className="col-span-6">
                <CardBarStats title='Highest Converting Pages of Visitors' segregation='Visitors' data={panelConvertingVisitors.data || []} isLoading={panelConvertingVisitors.isLoading} />
            </div>
            <div className="col-span-6">
                <CardBarStats title='Pages with Highest Duration of ICP' segregation='Duration' data={panelDurationICP.data || []} isLoading={panelDurationICP.isLoading} />
            </div>
            <div className="col-span-6">
                <CardBarStats title='Highest Converting Pages for ICP' segregation='Convertion' data={panelConvertionICP.data || []} isLoading={panelConvertionICP.isLoading} />
            </div>
            <div className="col-span-6">
                <CardBarStats title='Highest Quality Traffic by Volume' segregation='Visitors' data={panelQualityVolume.data || []} isLoading={panelQualityVolume.isLoading} />
            </div>
            <div className="col-span-6">
                <CardBarStats title='Highest Quality Traffic by %' segregation='Share' data={panelQualityVolumePerson.data || []} isLoading={panelQualityVolumePerson.isLoading} />
            </div> */}
        </div>
    )
}
export default AnalyticClientStats