interface CardProps {
    children: React.ReactNode;
    title?: string
}
export const Card: React.FC<CardProps> = (props) => {
    return (
        <section className="bg-gray-900 rounded-lg w-full flex py-4 flex-col">
            <h2 className="font-bold text-lg px-4">{props.title}</h2>
            <div className="">{props.children}</div>
        </section>
    )
}