import { CopyToClipboard } from "../Copy"
import Tooltip from "../Tooltip/Tooltip"
import Icon from "../Icon/Icon"
import { Validity } from "utils/enums/validity"

interface CardItem {
    prefix: string
    value?: string | null
    isBadge?: boolean
    isCopy?: boolean
    defaultGreen?: boolean
    borderBottom?: boolean
    isLink?: boolean
    validity?: Validity
    showIcon?: boolean
}
interface CardItemPropsWithLink {
    isLink?: true
    link: string
}
interface CardItemPropsWithoutLink {
    isLink?: false
}

type CardItemProps = CardItem & (CardItemPropsWithLink | CardItemPropsWithoutLink)
export const CardItem: React.FC<CardItemProps> = (props) => {
    const selectColor = {
        1: 'Likely',
        2: 'Likely',
        3: 'Verified',
        4: 'Likely',
        5: 'Likely'
    }
    return (
        <div className={`grid grid-cols-12 w-full items-center px-4 py-2 h-12 ${props.borderBottom && 'border-b border-gray-800'}`}>
            <small className="text-sm text-gray-400 col-span-4 truncate">{props.prefix}</small>
            <div className="flex items-center col-span-8 gap-3 w-full">
                {
                    props.value ?
                        <h4 className={`text-sm truncate px-2 ${props.isBadge && `badge p-1 ${selectColor[props.validity || 1]} ${props.defaultGreen && 'green'}`}`}>{
                            props.isLink ? <a target="_blank" className="link" href={props.link} rel="noreferrer">{props.value}</a> : (props.value || '-')}
                            {props.validity === 3 ? props.showIcon && <Tooltip content={selectColor[props.validity || 1] || ''}><Icon name="check-circle" size={14} /></Tooltip> : ''}
                            {props.validity !== 3 ? props.showIcon && <Tooltip content={selectColor[props.validity || 1] || ''}><Icon name="bulb" size={14} /></Tooltip> : ''}
                        </h4> : <h4>-</h4>
                }
                {props.isCopy && props.value && <CopyToClipboard text={props.value || '-'} />}
            </div>
        </div>
    )
}